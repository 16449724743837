export const adminProductTableConfigs = [
    {
      title: 'Image',
      sorter: false,
    },
    {
      title: 'Product Name',
      sorter: false,
      sorterName: 'name',
    },
    {
      title: 'SKUs',
      sorter: false,
      sorterName: 'SKUs',
    },
    {
      title: 'Stock',
      sorter: false,
    },
    {
      title: 'Price',
      sorter: false,
      sorterName: 'Price',
    },
    {
      title: 'Status',
      sorter: false,
      sorterName: 'Price',
    },
    {
      title: 'Action',
      sorter: false,
      sorterName: 'Action',
    },
  ];

  export const adminCouponTableConfigs = [
    {
      title: 'Discount Code',
      // sorter: true,
      // sorterName: 'name',
    },
    {
      title: 'Status',
      // sorter: true,
      // sorterName: 'SKUs',
    },
    {
      title: 'Discount Method',
      sorter: false,
    },
    {
      title: 'Discount Type',
      sorter: false,
      // sorterName: 'Price',
    },
    {
      title: 'Valid from',
      // sorter: true,
      // sorterName: 'SKUs',
    },
    {
      title: 'Valid to',
      // sorter: true,
      // sorterName: 'SKUs',
    },
    {
      title: 'Used',
      sorter: false,
    },
    {
      title: 'Action',
      sorter: false,
      // sorterName: 'Action',
    },
  ];


  export const adminOrdersTableConfigs = [
    {
      title: 'ORDER ID#',
      // sorter: true,
      // sorterName: 'name',
    },
    {
      title: 'CLIENT',
      // sorter: true,
      // sorterName: 'SKUs',
    },
    {
      title: 'PRODUCT',
      sorter: false,
    },
    {
      title: 'AMOUNT',
      // sorter: true,
      // sorterName: 'SKUs',
    },
    {
      title: 'PAYMENT',
      sorter: false,
    },
    {
      title: 'STATUS',
      sorter: false,
      // sorterName: 'Price',
    },
    {
      title: 'DATE TIME',
      sorter: false,
      // sorterName: 'Price',
    },
    {
      title: 'ACTION',
      sorter: false,
      // sorterName: 'Action',
    },
  ];

  export const adminReturnOrdersTableConfigs = [
    {
      title: 'ORDER ID#',
      // sorter: true,
      // sorterName: 'name',
    },
    {
      title: 'PRODUCT NAME',
      // sorter: true,
      // sorterName: 'SKUs',
    },
    {
      title: 'CUSTOMER NAME',
    },
    {
      title: 'ORDER STATUS',
      sorter: false,
      // sorterName: 'Price',
    },
    {
      title: 'REASON',
      // sorter: true,
      // sorterName: 'SKUs',
    },
    {
      title: 'ORDER DATE',
      sorter: false,
    },
    // {
    //   title: 'DATE TIME',
    //   sorter: false,
    //   // sorterName: 'Price',
    // },
    {
      title: 'ACTION',
      sorter: false,
      // sorterName: 'Action',
    },
  ];


  export const adminUsersTableConfigs = [
    {
      title: 'Name',
      sorter: false,
    },
    {
      title: 'Role',
    },
    {
      title: 'Email',
    },
    {
      title: 'Phone',
    },
    {
      title: 'Status',
    },
    {
      title: 'Created',
    },
    {
      title: 'Action',
    },
  ];

  export const adminBlogsTableConfigs = [
    {
      title: 'Image',
      sorter: false,
    },
    {
      title: 'Title',
    },
    {
      title: 'Category',
    },
    {
      title: 'Status',
    },
    {
      title: 'Created',
    },
    {
      title: 'Featured Status',
    },
    {
      title: 'Action',
    },
  ];

  export const adminVideosTableConfigs = [
    {
      title: 'Video',
      sorter: false,
    },
    {
      title: 'Title',
    },
    {
      title: 'Description',
    },
    {
      title: 'Video Link',
    },
    {
      title: 'Created',
    },
    {
      title: 'Featured Status',
    },
    {
      title: 'Action',
    },
  ];


  export const adminNewsletterTableConfigs = [
    {
      title: 'Email',
    },
    {
      title: 'Subsribed At',
    },
  ];


  export const adminStoreLocatorTableConfigs = [
    {
      title: 'Title',
    },
    {
      title: 'Contact',
    },
    {
      title: 'Description',
    },
    {
      title: 'Address',
    },
    {
      title: 'Status',
    },
    {
      title: 'Action',
    },
  ];

  export const adminReviewRatingsTableConfigs = [
    {
      title: 'Name',
    },
    {
      title: 'Title',
    },
    {
      title: 'Ratings',
    },
    {
      title: 'Description',
    },
    {
      title: 'Product',
    },
    {
      title: 'Status',
    },
    {
      title: 'Action',
    },
  ];


  export const HeroProductsTableConfigs = [
    {
      title: 'Image',
      sorter: false,
    },
    {
      title: 'Title',
    },
    {
      title: 'Description',
    },
    {
      title: 'Nutrition',
    },
    {
      title: 'Ingredient',
    },
    {
      title: 'Action',
    },
  ];

  export const OffersBannerTableConfigs = [
    {
      title: 'Banner Image',
      sorter: false,
    },
    {
      title: 'Offer Value( % )',
    },
    {
      title: 'Status',
    },
    {
      title: 'Action',
    },
  ];

  export const FAQsTableConfigs = [
    {
      title: 'S.No',
      sorter: false,
    },
    {
      title: 'Questions',
    },
    {
      title: 'Answers',
    },
    {
      title: 'Action',
    },
  ];

  export const InfluencersTableConfigs = [
    {
      title: 'Image',
      sorter: false,
    },
    {
      title: 'Name',
    },
    {
      title: 'Email',
    },
    {
      title: 'Phone',
      sorter: false,
    },
    {
      title: 'Instagram',
    },
    {
      title: 'Facebook',
    },
    {
      title: 'Youtube',
    },
    {
      title: 'Featured Status',
    },
    {
      title: 'Action',
    },
  ];