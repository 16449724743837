import { toastr } from "react-redux-toastr";
import { START_LOADER, STOP_LOADER } from "../actionTypes";

import {
  CHANGE_FETAURED_REVIEW_STATUS_FAILURE,
  CHANGE_FETAURED_REVIEW_STATUS_SUCCESS,
  DELETE_REVIEW_FAILURE,
  DELETE_REVIEW_SUCCESS,
  GET_REVIEWS_LIST_FAILURE,
  GET_REVIEWS_LIST_SUCCESS,
  REVIEW_API_END,
  REVIEW_API_REQUEST,
} from "../actionTypes";
import { ReviewConstants } from "../../utils/CMSConstants";
import {
  changeReviewsFeaturedStatus,
  deleteReviews,
  getReviewsList,
} from "../../services/adminReviewListing";
import constant from "../../utils/constants";
import { hideLoader } from "./loader";

const { REVIEW_API_TYPES } = ReviewConstants;
const { ERROR_MESSAGES } = constant;
const token = localStorage.getItem("token");

//Get Review List
export const getReviewsListAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: REVIEW_API_REQUEST,
        payload: REVIEW_API_TYPES.GET_REVIEWS_LIST,
      });
      const response = await getReviewsList(params, token);
      const dataList = response.data;
      dispatch({
        type: GET_REVIEWS_LIST_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_REVIEWS_LIST_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: REVIEW_API_END,
      });
    }
  };
};

//Delete Reviews
export const deleteAdminReviewsAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: REVIEW_API_REQUEST,
      payload: REVIEW_API_TYPES.DELETE_REVIEWS,
    });
    const response = await deleteReviews(params, token);
    response?.statusCode === 200
      ? toastr.success(response?.message)
      : toastr.error(response?.error || "Some Error Occured");
    dispatch({
      type: DELETE_REVIEW_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: DELETE_REVIEW_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: REVIEW_API_END,
    });
  }
};

//Change Featured Status
export const changeFeaturedReviewStatusAction =
  (params) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: REVIEW_API_REQUEST,
        payload: REVIEW_API_TYPES.CHANGE_FEATURED_STATUS,
      });
      const response = await changeReviewsFeaturedStatus(params, token);
      toastr.success(response.message);
      dispatch({
        type: CHANGE_FETAURED_REVIEW_STATUS_SUCCESS,
        payload: { ...response.data },
      });
      dispatch({ type: STOP_LOADER });
    } catch (error) {
      dispatch({ type: STOP_LOADER });
      dispatch({
        type: CHANGE_FETAURED_REVIEW_STATUS_FAILURE,
        payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: REVIEW_API_END,
      });
    }
  };
