import { toastr } from "react-redux-toastr";
import { CHANGE_ORDER_STATUS_FAILURE, CHANGE_ORDER_STATUS_SUCCESS, CHANGE_RETURN_ORDER_STATUS_FAILURE, CHANGE_RETURN_ORDER_STATUS_SUCCESS, CONFIRM_ORDER_FAILURE, CONFIRM_ORDER_SUCCESS, CONFIRM_RETURN_ORDER_FAILURE, CONFIRM_RETURN_ORDER_SUCCESS, GET_ORDERS_DETAIL_FAILURE, GET_ORDERS_DETAIL_SUCCESS, GET_ORDERS_LIST_FAILURE, GET_ORDERS_LIST_SUCCESS, GET_ORDERS_STATUS_FAILURE, GET_ORDERS_STATUS_SUCCESS, GET_RETURN_ORDERS_DETAIL_FAILURE, GET_RETURN_ORDERS_DETAIL_SUCCESS, GET_RETURN_ORDERS_LIST_FAILURE, GET_RETURN_ORDERS_LIST_SUCCESS, GET_USERS_ORDERS_LIST_FAILURE, GET_USERS_ORDERS_LIST_SUCCESS, ORDER_API_END, ORDER_API_REQUEST, RETURN_ORDER_API_END, RETURN_ORDER_API_REQUEST, START_LOADER, STOP_LOADER } from "../actionTypes";
import { OrderConstants } from "../../utils/CMSConstants";
import constant from "../../utils/constants";
import { hideLoader } from "./loader";
import { changeOrderStatus, changeReturnOrderStatus, confirmOrder, confirmReturnOrder, getOrderList, getOrdersDetail, getReturnOrderDetailed, getReturnOrderList, getUsersOrderList } from "../../services/adminOrderServices";

const { ORDER_API_TYPES } = OrderConstants;
const { ERROR_MESSAGES } = constant;

//Get Order List
export const getOrdersListAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ORDER_API_REQUEST,
        payload: ORDER_API_TYPES.GET_ORDER_DETAIL,
      });
      const response = await getOrderList(params, token);
      const dataList = response?.data;
      dispatch({
        type: GET_ORDERS_LIST_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ORDERS_LIST_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ORDER_API_END,
      });
    }
  };
};

//Get Order List
export const getUserOrdersListAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ORDER_API_REQUEST,
        payload: ORDER_API_TYPES.GET_USER_ORDER_LIST,
      });
      const response = await getUsersOrderList(params, token);
      const dataList = response?.data;
      dispatch({
        type: GET_USERS_ORDERS_LIST_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_USERS_ORDERS_LIST_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ORDER_API_END,
      });
    }
  };
};

//Get Order List Status Count
export const getOrdersStatusCountsAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ORDER_API_REQUEST,
        payload: ORDER_API_TYPES.ORDER_STATUS_COUNT,
      });
      const response = await getOrderList(params, token);
      const dataList = response.data;
      dispatch({
        type: GET_ORDERS_STATUS_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ORDERS_STATUS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ORDER_API_END,
      });
    }
  };
};

//Get Order Detail
export const getOrdersDetailAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ORDER_API_REQUEST,
        payload: ORDER_API_TYPES.GET_ORDER_LIST,
      });
      const response = await getOrdersDetail(params, token);
      const dataList = response.data;
      dispatch({
        type: GET_ORDERS_DETAIL_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ORDERS_DETAIL_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ORDER_API_END,
      });
    }
  };
};


//Confirm Order
export const confirmOrderAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ORDER_API_REQUEST,
      payload: ORDER_API_TYPES.CONFIRM_ORDER,
    });
    const response = await confirmOrder(params, token);
    response?.statusCode === 200
      ? toastr.success(response?.message)
      : toastr.error(response?.error || "Some Error Occured");
    dispatch({
      type: CONFIRM_ORDER_SUCCESS,
      payload: { ...response.data, orderId: params.orderId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: CONFIRM_ORDER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ORDER_API_END,
    });
  }
};

//Change Order Status
export const changeOrderStatusAction =
  (params,changeStatus) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: ORDER_API_REQUEST,
        payload: ORDER_API_TYPES.CHANGE_ORDER_STATUS,
      });
      const response = await changeOrderStatus(params,changeStatus, token);
      toastr.success(response.message);
      dispatch({
        type: CHANGE_ORDER_STATUS_SUCCESS,
        payload: { ...response.data },
      });
      dispatch({ type: STOP_LOADER });
    } catch (error) {
      dispatch({ type: STOP_LOADER });
      dispatch({
        type: CHANGE_ORDER_STATUS_FAILURE,
        payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ORDER_API_END,
      });
    }
  };


  // ************************ RETURN ORDER LIST ACTIONS"************************************ //

  //Get Return Order List
export const getReturnOrdersListAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: RETURN_ORDER_API_REQUEST,
        payload: ORDER_API_TYPES.GET_RETURN_ORDER_LIST,
      });
      const response = await getReturnOrderList(params, token);
      const dataList = response?.data;
      dispatch({
        type: GET_RETURN_ORDERS_LIST_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_RETURN_ORDERS_LIST_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: RETURN_ORDER_API_END,
      });
    }
  };
};

 //Get Return Order Details
 export const getReturnOrdersDetailsAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: RETURN_ORDER_API_REQUEST,
        payload: ORDER_API_TYPES.GET_RETURN_ORDER_DETAIL,
      });
      const response = await getReturnOrderDetailed(params, token);
      const dataList = response?.data;
      dispatch({
        type: GET_RETURN_ORDERS_DETAIL_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_RETURN_ORDERS_DETAIL_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: RETURN_ORDER_API_END,
      });
    }
  };
};

//Change Order Status
export const changeReturnOrderStatusAction =
  (params,changeStatus) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: ORDER_API_REQUEST,
        payload: ORDER_API_TYPES.CHANGE_RETURN_ORDER_STATUS,
      });
      const response = await changeReturnOrderStatus(params,changeStatus, token);
      toastr.success(response.message);
      dispatch({
        type: CHANGE_RETURN_ORDER_STATUS_SUCCESS,
        payload: { ...response.data },
      });
      dispatch({ type: STOP_LOADER });
    } catch (error) {
      dispatch({ type: STOP_LOADER });
      dispatch({
        type: CHANGE_RETURN_ORDER_STATUS_FAILURE,
        payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ORDER_API_END,
      });
    }
  };

  //Confirm Return Order
export const confirmReturnOrderAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ORDER_API_REQUEST,
      payload: ORDER_API_TYPES.CONFIRM_RETURN_ORDER,
    });
    const{id,confirmStatus} = params;
    const response = await confirmReturnOrder(id, token,confirmStatus);
    response?.statusCode === 200
      ? toastr.success(response?.message)
      : toastr.error(response?.error || "Some Error Occured");
    dispatch({
      type: CONFIRM_RETURN_ORDER_SUCCESS,
      payload: { ...response.data, orderId: params.orderId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: CONFIRM_RETURN_ORDER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ORDER_API_END,
    });
  }
};

