import * as Yup from 'yup';

//Create Product Form Validations
export const validationsSchema = Yup.object({
  Name: Yup.string()
    .required('Required'),
  MRP: Yup.number()
    .required('Required'),
  DiscountedPrice: Yup.number().required("Required").min(0, `Minimum tip is $0`)
    .test({
      name: 'DiscountedPrice',
      exclusive: false,
      params: {},
      message: 'Sale Price must be less than Regular Price',
      test: function (value) {
        // You can access the price field with `this.parent`.
        return value <= this.parent.MRP;
      },
    }),
  SKUs: Yup.string()
    .required('Required'),
  // Sizes: Yup.number().required('Required'),
  // ImagesUrls: Yup.array()
  //   .required('Required').nullable(),
  DetailKey: Yup.string().required('Required'),
  Category: Yup.string().required('Required'),
})

//Create FAQ Form Validations
export const faqValidationsSchema = Yup.object({
  question: Yup.string()
    .required('Required'),
  answer: Yup.string()
    .required('Required'),
})

//Create Variant Form Validations
export const variantsValidationsSchema = Yup.object({
  Name: Yup.string()
    .required('Required'),
  MRP: Yup.number()
    .required('Required'),
  DiscountedPrice: Yup.number().required("Required").min(0, `Minimum tip is $0`)
    .test({
      name: 'DiscountedPrice',
      exclusive: false,
      params: {},
      message: 'Sale Price must be less than Regular Price',
      test: function (value) {
        // You can access the price field with `this.parent`.
        return value <= this.parent.MRP;
      },
    }),
  SKUs: Yup.string()
    .required('Required')
  // Sizes: Yup.number().required('Required'),
  // ImagesUrls: Yup.array()
  //   .required('Required').nullable(),
})
