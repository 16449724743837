import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Image, Input, Text, Box, Icon, Button, Heading, Anchor, Select, Option } from "../elements";
import { useDispatch } from "react-redux";
import TableHeaderRow from "../shared/TableHeaderRow";
import { disableAdminCouponAction } from "../../store/actions/adminCoupon";
import { changeOrderStatusAction, changeReturnOrderStatusAction, confirmOrderAction, confirmReturnOrderAction } from "../../store/actions/adminOrders";
import { LabelField } from "../fields";
import { changeOrderStatus } from "../../services/adminOrderServices";
import moment from "moment";

export default function ReturnTable({ thead, tbody,products, memoizedHandleClick,memoizedTableConfig  }) {
    const [alertModal, setAlertModal] = useState(false);
    const [itemModal, setItemModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [data, setData] = useState([]);
    const [orderId, setOrderId] = useState();
    const [itemData, setItemData] = useState();
    const [productsData, setProductsData] = useState([]);
    const[returnConfirm,setReturnConfirm] = useState(false)
    const dispatch = useDispatch();
    

    useEffect(() => { setData(tbody); setProductsData(products) }, [tbody, products]);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;

        if (name === "allCheck") {
            const checkData = data?.map((item) => {
                return { ...item, isChecked: checked };
            });
            setData(checkData);
        }
        else {
            const checkData = data?.map((item) =>
                item.name === name ? { ...item, isChecked: checked } : item
            );
            setData(checkData);
        }
    }

    const handleDisableOrder = (confirm) => {
        dispatch(confirmReturnOrderAction({id:orderId,confirmStatus:{ isConfirmed: confirm }}))
    }

    const handleChangeOrderStatus = (orderIds) => {
        dispatch(changeReturnOrderStatusAction(orderId || orderIds,{status: selectedStatus.value}))
    }

    const getIdinModal = (key) => {
        setAlertModal(true)
        setOrderId(key)
    }

    const getDatainItemModal = (data) => {
        setItemModal(true)
        setItemData(data)
    }

    const getIdinStatusModal = (key) => {
        setStatusModal(true)
        setOrderId(key)
    }

    const handleSelectStatus = (data, setFieldValue) => {
        // const productSize = data.map((el) => {
        //   return el.value;
        // });
        setSelectedStatus(data);
      };

    return (
        <Box className="mc-table-responsive">
            <Table className="mc-table">
                <Thead className="mc-table-head primary">
                    <Tr>
                        {/* <Th>
                            <Box className="mc-table-check">
                                <Input 
                                    type="checkbox" 
                                    name="allCheck"
                                    checked={ data?.filter((item)=> item.isChecked !== true).length < 1 } 
                                    onChange={ handleCheckbox } 
                                />
                                <Text>Order Id#</Text>
                            </Box>
                        </Th>
                        {thead.map((item, index) => (
                            <Th key={ index }>{ item }</Th>
                        ))} */}

<TableHeaderRow
                        configData={memoizedTableConfig}
                        onDesOrder={memoizedHandleClick}
                        onAscOrder={memoizedHandleClick}
                        />
                    </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                    {productsData?.map((item, index) => (
                        <Tr key={ index }> 

                       
                            <Td>
                                <Box className="mc-table-check">
                                    {/* <Input 
                                        type="checkbox" 
                                        name={item.name} 
                                        checked={ item?.isChecked || false }
                                        onChange={ handleCheckbox } 
                                    /> */}
                                    <Text>{ item.orderData?.OrderId }</Text>
                                </Box>
                            </Td>
                            {/* <Td>
                                <Box className="mc-table-profile">
                                    <Image src={ item.src } alt={ item.alt } />
                                    <Text>{ item.name }</Text>
                                </Box>
                            </Td> */}
                             <Td > <Anchor href={""} title="Products Item" onClick={() => {
                                             getDatainItemModal(item?.items)
                                        }}>{`(${item.items?.length} item)`}</Anchor> 
                             
                             </Td>
                           

                            
    
                           <Td>{item.user?.name ? item.user?.name : 'Guest' }</Td>
                            <Td> 
                                <Text className={`mc-table-badge ${item.status === "processing" ?  "yellow" : item.status === "delivered" ? "green" : item.status === "cancelled" ? "red" : "blue" }`}>{ item.status }</Text> 
                                </Td>
                            <Td>{ item?.reason }</Td>
                            <Td>{moment(item?.orderData?.orderDate).format('lll')}</Td>
                            <Td> <Box className="mc-table-action">
                                {/* <Text className={`mc-table-badge ${item.status === "processing" ?  "yellow" : item.status === "delivered" ? "green" : item.status === "cancelled" ? "red" : "blue" }`}>{ item.status }</Text>  */}
                            {item.status === "return-requested" ? <Anchor href={""} title="Confirm Order" className="material-icons view" onClick={() => {
                                             getIdinModal(item?._id)
                                        }}>{ "done" }</Anchor> : <Anchor href={""} title="Change Status" className="material-icons view" onClick={() => {
                                             getIdinStatusModal(item?._id)
                                        }}>{ "cached" }</Anchor>}
                                         <Box className="mc-table-action">
                                    <Anchor href={{ pathname: `/return-details` ,  search:`?_id=${item?._id}` }} title="View" className="material-icons view">{ "visibility" }</Anchor>
                                    {/* <Anchor title="Download" href="#" className="material-icons download" download>{ "download" }</Anchor> */}
                                    {/* {item.status === "processing" && <Button title="Delete" className="material-icons view" onClick={() => {
                                             getIdinModal(item?._id)
                                        }}>{ "done" }</Button>  }  */}
                                </Box>
                                        </Box>
                                        
                                        </Td>
                            <Td>
                               
                            </Td>
                            
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Modal show={ alertModal } onHide={()=> setAlertModal(false)}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">are your sure!</Heading>
                    <Text as="p">Want to Accept this return order?</Text>
                    <Modal.Footer>
                        <Button type="button" className="btn btn-secondary" onClick={()=> setAlertModal(false)}>nop, close</Button>
                        <Button type="button" className="btn btn-danger" onClick={()=> handleDisableOrder(false)}>Reject</Button>
                        <Button type="button" className="btn btn-success" onClick={()=> handleDisableOrder(true)}>Accept</Button>
                    </Modal.Footer>
                </Box>
            </Modal>

            <Modal show={ itemModal } onHide={()=> setItemModal(false)}>
                <Box className="mc-alert-modal">
                    {/* <Icon type="new_releases" /> */}
                    {itemData && itemData.map((el)=> { return (<Box className="mc-table-product md mt-4">
                                  <Image src={ el?.product?.ImagesUrls[0] } alt={ "productImage" } />
                                    <Box className="mc-table-group">
                                        <Heading as="h6">{ el?.product?.Name }</Heading>
                                        {/* <Text>{ el?.descrip }</Text> */}
                                    </Box>
                                </Box>)})}
                    
                    <Modal.Footer>
                        <Button type="button" className="btn btn-secondary" onClick={()=> setItemModal(false)}>Close</Button>
                        {/* <Button type="button" className="btn btn-danger" onClick={()=> handleDisableOrder()}>yes, Confirm</Button> */}
                    </Modal.Footer>
                </Box>
            </Modal>

            <Modal show={ statusModal } onHide={()=> setStatusModal(false)}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">Change Status Of this Order</Heading>
                    {/* <Text as="p">Want to Change the Status this order?</Text> */}
                    <LabelField
                                        label = { "status by" }
                                        onChange={(e) => handleSelectStatus(e)}
                      value={selectedStatus}
                      options = {[
                        { value: "return-requested", label: "return-requested" },
                        { value: "return-processing", label: "return-processing" },
                        { value: "return-cancelled", label: "return-cancelled" },
                        { value: "returned", label: "returned" },
                        { value: "partially-return-requested", label: "partially-return-requested" },
                        { value: "partially-return-processing", label: "partially-return-processing" },
                        { value: "partially-return-cancelled", label: "partially-return-cancelled" },
                        { value: "partially-returned", label: "partially-returned" }]}
                                        
                                        type="select1"
                                        labelDir = "label-col"
                                        fieldSize = "w-100 h-md"
                                    /> 
                    <Modal.Footer>
                        <Button type="button" className="btn btn-secondary" onClick={()=> setStatusModal(false)}>nop, close</Button>
                        <Button type="button" className="btn btn-danger" onClick={()=> handleChangeOrderStatus()}>yes, Change</Button>
                    </Modal.Footer>
                </Box>
            </Modal>
        </Box>
    )
}