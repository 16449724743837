import constant from "../../../utils/constants";
import {  ADD_ADMIN_BLOG_FAILURE,
  ADD_ADMIN_BLOG_SUCCESS,
ADMIN_BLOG_API_END,
ADMIN_BLOG_API_REQUEST,
GET_ADMIN_BLOGS_FAILURE,
GET_ADMIN_BLOGS_SUCCESS,
GET_ADMIN_BLOG_CATEGORIES_SUCCESS,
GET_ADMIN_BLOG_DETAIL_FAILURE,
GET_ADMIN_BLOG_DETAIL_SUCCESS,
UPDATE_ADMIN_BLOG_FAILURE,
UPDATE_ADMIN_BLOG_SUCCESS,CREATE_HERO_FAILURE, CREATE_HERO_SUCCESS, HERO_SECTION_API_REQUEST, GET_HERO_PRODUCT_LIST_SUCCESS, GET_HERO_PRODUCT_LIST_FAILURE, OFFER_ZONE_API_REQUEST, OFFER_API_END, CREATE_OFFER_ZONE_SUCCESS, CREATE_OFFER_ZONE_FAILUTRE, GET_OFFER_BANNER_LIST_SUCCESS, GET_OFFER_BANNER_LIST_FAILURE } from "../../actionTypes";


const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  offerBanners: { offers: [], meta: "" },
};

const offerZone = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case OFFER_ZONE_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case OFFER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case CREATE_OFFER_ZONE_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case CREATE_OFFER_ZONE_FAILUTRE:
    case GET_OFFER_BANNER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        offerBanners: { offers: [], meta: "" },
      };
    case GET_OFFER_BANNER_LIST_SUCCESS:
      return {
        ...state,
        offerBanners: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          offers: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
          case GET_ADMIN_BLOG_CATEGORIES_SUCCESS:
        return {
          ...state,
          adminBlogCategories: {
            // products:
            //   action.payload.page === 1
            //     ? action.payload.dataList
            //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
            dropdownCategories: action.payload.dataList,
            meta: action.payload.meta,
            loading: false,
          },
          status: API_STATUS.SUCCESS,
        };
    case GET_ADMIN_BLOG_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminBlogDetail: action.payload,
        loading: false,
      };
    case GET_ADMIN_BLOG_DETAIL_FAILURE:
      case UPDATE_ADMIN_BLOG_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
        };
    case UPDATE_ADMIN_BLOG_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };

    default:
      return state;
  }
};

export { offerZone };
