import React from "react";
import ReactSelect from 'react-select';
import { Box, Input, Label, Select, Option } from "../elements";
import CreatableSelect from 'react-select/creatable';
import { YAxis } from "recharts";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";

export default function LabelField({isClearable, disabled,onPaste,label,inputmode,onChange,name,value,htmlfor,options, labelDir, fieldSize, option, type,style, placeholder,selected,onSelect,handleDateChange,maxDate,minDate,closeMenuOnSelect, ...rest }) {
    return (
        <Box className={`mc-label-field-group ${ label ? labelDir || "label-col" : "" }`}>
            {label && <Label htmlfor={htmlfor} className="mc-label-field-title">{ label }</Label>}
            {type === 'text' || type === 'number' ? 
                <Input 
                onPaste={onPaste}
                disabled={disabled}
                    type = { type || "text" } 
                    inputmode={inputmode}
                    style={style}
                    placeholder = { placeholder } 
                    className = {`mc-label-field-input ${ fieldSize || "w-md h-sm" }`} 
                    onChange={onChange}
                    value={value}
                    name={name}
                    { ...rest } 
                />
            : type === 'select' ? 
            <ReactSelect
            isClearable={isClearable}
            required
            className={`mc-label-field-select ${ fieldSize || "w-md h-sm" }`}
            options={options}
            placeholder = { 'choose' } 
            value={value}
            onChange={onChange}
            menuPosition="fixed"
            menuPlacement="auto"
            styles={{
            control: (baseStyles, events) => ({
              ...baseStyles,
              borderColor: events.isFocused ? '#f0f0f0' : '',
              boxShadow: 'none',
              right: "17px",
              width: '102.5%',
              primary: 'black',
              padding: "5px",
              backgroundColor: '#f0f0f0',
              border: events.isFocused ? '#dfe1e3' : '#e4e7eb'

            }),
          }}
            isSearchable={true}
          /> : 
          type === 'select1' ? 
            <ReactSelect
            isClearable={isClearable}
            required
            className={`mc-label-field-select ${ fieldSize || "w-md h-sm" }`}
            options={options}
            placeholder = { 'choose' } 
            value={value}
            onChange={onChange}
            menuPosition="fixed"
            menuPlacement="auto"
            styles={{
            control: (baseStyles, events) => ({
              ...baseStyles,
              borderColor: events.isFocused ? '#f0f0f0' : '',
              boxShadow: 'none',
              right: "17px",
              width: '110%',
              primary: 'black',
              padding: "5px",
              backgroundColor: '#f0f0f0',
              border: events.isFocused ? '#dfe1e3' : '#e4e7eb'

            }),
          }}
            isSearchable={true}
          /> : 
          type === 'creatable' ? 
            <CreatableSelect
            disabled={disabled}
            required
            className={`mc-label-field-select ${ fieldSize || "w-md h-sm" }`}
            options={options}
            placeholder = { 'choose or create your own by typing here...' } 
            value={value}
            onChange={onChange}
            menuPosition="fixed"
            menuPlacement="auto"
            styles={{
            control: (baseStyles, events) => ({
              ...baseStyles,
              borderColor: events.isFocused ? '#f0f0f0' : '',
              boxShadow: 'none',
              right: "17px",
              width: '102%',
              primary: 'black',
              padding: "5px",
              backgroundColor: '#f0f0f0',
              border: events.isFocused ? '#dfe1e3' : '#e4e7eb'

            }),
          }}
            isSearchable={true}
          /> :
          type === 'multi_select' ? 
          <ReactSelect
          closeMenuOnSelect={closeMenuOnSelect}
          required
          className={`mc-label ${ fieldSize || "w-md h-sm" }`}
          options={options}
          placeholder = { placeholder  } 
          value={value}
          onChange={onChange}
          menuPosition="fixed"
          menuPlacement="auto"
          styles={{
          control: (baseStyles, events) => ({
            ...baseStyles,
            borderColor: events.isFocused ? '#f0f0f0' : '',
            boxShadow: 'none',
            right: "17px",
            position: "initial",
            width: '100%',
            primary: 'black',
            padding: "5px",
            backgroundColor: '#f0f0f0',
            border: events.isFocused ? '#dfe1e3' : '#e4e7eb'

          }),
        }}
          isSearchable={true}
          isMulti
        /> 
          : type === 'date' ? 
          <DatePicker
          required
          selected={selected}
          maxDate={maxDate}
          minDate={minDate}
          inputFormat="yyyy-MM-dd"
          dateFormat={'MMM dd, yyyy'}
          className = {`mc-label-field-input ${ fieldSize  }`} 
          onSelect={onSelect} //when day is clicked
          onChange={onChange} //only when value has changed
        />
          : type === 'phone' ?  
          <PhoneInput
          name={name}
          onChange={onChange}
         value={value}
          containerStyle={{width: '420px'}}
          specialLabel=""
          country={'in'}
          /> :
                <Select className={`mc-label-field-select ${ fieldSize || "w-md h-sm" }`} { ...rest }>
                    {option?.map((item, index) => (
                        <Option key={ index } value={ item }>{ item}</Option>
                    ))}
                </Select>
            }
        </Box>
    )
}