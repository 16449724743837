import constant from "../../utils/constants";
import {
  CHANGE_ORDER_STATUS_FAILURE,
  CHANGE_ORDER_STATUS_SUCCESS,
  CHANGE_RETURN_ORDER_STATUS_FAILURE,
  CHANGE_RETURN_ORDER_STATUS_SUCCESS,
  CONFIRM_ORDER_FAILURE,
  CONFIRM_ORDER_SUCCESS,
  CONFIRM_RETURN_ORDER_FAILURE,
  CONFIRM_RETURN_ORDER_SUCCESS,
  GET_ORDERS_DETAIL_FAILURE,
  GET_ORDERS_DETAIL_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_STATUS_FAILURE,
  GET_ORDERS_STATUS_SUCCESS,
  GET_RETURN_ORDERS_DETAIL_FAILURE,
  GET_RETURN_ORDERS_DETAIL_SUCCESS,
  GET_RETURN_ORDERS_LIST_FAILURE,
  GET_RETURN_ORDERS_LIST_SUCCESS,
  GET_USERS_ORDERS_LIST_FAILURE,
  GET_USERS_ORDERS_LIST_SUCCESS,
  ORDER_API_END,
  ORDER_API_REQUEST,
  RETURN_ORDER_API_END,
  RETURN_ORDER_API_REQUEST,
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminOrders: { orders: [], meta: "" },
  returnOrders: { returns: [], meta: "" },
  returnOrdersDetails: { returnDetails: [], meta: "" },
  adminOrdersDetails: { ordersDetails: [], meta: "" },
  adminOrdersStatus: { statusCount: [], meta2: "" },
  adminUserOrders: { userOrders: [], meta: "" },
};

const adminOrders = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case ORDER_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case ORDER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case RETURN_ORDER_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case RETURN_ORDER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_ORDERS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminOrders: { orders: [], meta: "" },
      };
    case GET_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        adminOrders: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          orders: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ORDERS_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminOrdersStatus: { statusCount: [], meta2: "" },
      };
    case GET_ORDERS_STATUS_SUCCESS:
      return {
        ...state,
        adminOrdersStatus: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          statusCount: action.payload.dataList,
          meta2: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_USERS_ORDERS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminUserOrders: { userOrders: [], meta2: "" },
      };
    case GET_USERS_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        adminUserOrders: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          userOrders: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ORDERS_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminOrdersDetails: { ordersDetails: [], meta: "" },
      };
    case GET_ORDERS_DETAIL_SUCCESS:
      return {
        ...state,
        adminOrdersDetails: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          ordersDetails: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case CHANGE_ORDER_STATUS_FAILURE:
    case CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminOrders: {
          ...state.adminOrders,
          orders: state.adminOrders.orders,
        },
      };
    case CONFIRM_ORDER_FAILURE:
    case CONFIRM_ORDER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminOrders: {
          ...state.adminOrders,
          orders: state.adminOrders.orders,
        },
      };
    case GET_RETURN_ORDERS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminOrders: { orders: [], meta: "" },
      };
    case GET_RETURN_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        returnOrders: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          returns: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_RETURN_ORDERS_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminOrdersDetails: { ordersDetails: [], meta: "" },
      };
    case GET_RETURN_ORDERS_DETAIL_SUCCESS:
      return {
        ...state,
        returnOrdersDetails: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          returnDetails: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
      case CHANGE_RETURN_ORDER_STATUS_FAILURE:
        case CHANGE_RETURN_ORDER_STATUS_SUCCESS:
          return {
            ...state,
            status: API_STATUS.SUCCESS,
            returnOrdersDetails: {
              ...state.returnOrdersDetails,
              returnDetails: state.returnOrdersDetails.returnDetails,
            },
          };
          case CONFIRM_RETURN_ORDER_FAILURE:
            case CONFIRM_RETURN_ORDER_SUCCESS:
              return {
                ...state,
                status: API_STATUS.SUCCESS,
                returnOrdersDetails: {
                  ...state.returnOrdersDetails,
                  returnDetails: state.returnOrdersDetails.returnDetails,
                },
              };
    default:
      return state;
  }
};

export { adminOrders };
