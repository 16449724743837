import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  Anchor,
  Button,
  Image,
  Input,
  Label,
  Icon,
  Text,
} from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/productUpload.json";
import { validationsSchema } from "../../utils/formValidations";
import { useFormik } from "formik";
import {
  addProductAction,
  getAdminProductDetailAction,
  getDropdownCategories,
  updateAdminProductAction,
} from "../../store/actions/adminProduct";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { detailKeyOptionList, flavourOptionList, optionList, sizeOptionList } from "../../utils/productConstants";
import { useQuery } from "../../hooks/useQuery";
import { hideLoader, showLoader } from "../../store/actions/loader";
import fetchAndConvertImages from "../../helpers/convertUrlToImages";
import ImageInput from "../../components/elements/ImageInput";

const ProductUpload = () => {
  const [uploadFile, setUploadFile] = React.useState("choose");
  const [previewImages, setPreviewImages] = useState();
  const [imageFile, setImageFile] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedDetailKey, setSelectedDetailKey] = useState();
  const [selectedFlavourOptions, setSelectedFlavourOptions] = useState();
  const [selectedIdOptions, setSelectedIdOptions] = useState();
  const productId = useQuery();
  const [isEditEvent, setEditEventStatus] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  // const { products, loading, meta } = useSelector((state) => ({
  //   products: state.adminProduct.adminProducts?.products,
  //   loading: state.loader.loading,
  //   status: state.adminProduct.status,
  //   meta: state.adminProduct.adminProducts?.meta,
  //   apiType: state.adminProduct.apiType,
  // }));
  const {productDetails,adminParentProducts,categories} = useSelector(
    (state) => ({productDetails : state.adminProduct.adminProductDetail,
      categories: state.adminProduct.adminCategories?.dropdownCategories,
      adminParentProducts : state.adminProduct?.adminParentProducts?.parentProducts})
  );
const [images,setImages] = useState()
const [queuedImagesArray, setQueuedImagesArray] = useState([]);
  const [productData, setProductData] = useState({
    Name: "",
    MRP: "",
    DiscountedPrice: "",
    Stock: "",
    SKUs: "",
    Sizes: [],
    About: "",
    Category: "",
    ProductDetails: "",
    DetailImages: [],
    Disclamer: "",
    ManufacturedBy: "",
    MarketedBy: "",
    CountryOfOrigin: "",
  });

  useEffect(() => {
    if (location?.state?.isEdit) {
      dispatch(showLoader());
      dispatch(getAdminProductDetailAction(productId.get("_id"))).then(
        (res) => {
          const variant = { label: res?.data?.SizeVariant, value: res?.data?.SizeVariant };
          const flavours = { label: res?.data?.FlavourVariant, value: res?.data?.FlavourVariant }
          const Category = { label: res?.data?.Category, value: res?.data?.Category }
          const detail = { label: res?.data?.DetailKey, value: res?.data?.DetailKey }
          // Call the function to convert image URLs into FileList object
          fetchAndConvertImages(res?.data?.ImagesUrls).then((fileList) => {
            formik.setFieldValue("ImagesUrls", fileList);
            setQueuedImagesArray(fileList);
          });
          setEditEventStatus(true); 
          formik.setFieldValue("Name", res?.data?.Name);
          formik.setFieldValue("MRP", res?.data?.MRP);
          formik.setFieldValue("DiscountedPrice", res?.data?.DiscountedPrice);
          formik.setFieldValue("SKUs", res?.data?.SKUs);
          formik.setFieldValue("Stock", res?.data?.Stock);
          formik.setFieldValue("Category", res?.data?.Category);
          formik.setFieldValue("SizeVariant", res?.data?.SizeVariant);
          formik.setFieldValue("FlavourVariant", res?.data?.FlavourVariant);
          formik.setFieldValue("DetailKey", res?.data?.DetailKey);
          // setQueuedImagesArray(res?.data?.ImagesUrls);
          setSelectedDetailKey(detail)
          setSelectedFlavourOptions(flavours)
          setSelectedOptions(variant);
          setSelectedCategory(Category);
        }
      );
      dispatch(hideLoader());
    }
  }, [location?.state?.isEdit]);

 
  useEffect(() => {
    dispatch(getDropdownCategories());
  }, []);

  const handleUploadImage = (e, setFieldValue) => {
    const img = productDetails?.allImages.map((el) => el);
    if ([...e.target.files].length < 1) return;
    const newImageUrls = [];
    [...e.target.files].forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setPreviewImages(newImageUrls);
    setFieldValue("ImagesUrls", e.target.files);
  };
  const onImageChange = (e,setFieldValue) => {
    const files = e.target.files;
    const updatedQueue = [...queuedImagesArray];
    for (let i = 0; i < files.length; i++) {
      updatedQueue.push(files[i]);
    }
    setQueuedImagesArray(updatedQueue);
    setFieldValue("ImagesUrls", updatedQueue);
    e.target.form.reset();
  }

  const deleteQueuedImage = (index,setFieldValue) => {
    const updatedQueue = [...queuedImagesArray];
    updatedQueue.splice(index, 1);
    setFieldValue("ImagesUrls", updatedQueue);
    setQueuedImagesArray(updatedQueue);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const updatedQueue = [...queuedImagesArray];

    for (let i = 0; i < files.length; i++) {
      if (!files[i].type.match('image')) continue;
      if (updatedQueue.every((image) => image.name !== files[i].name)) {
        updatedQueue.push(files[i]);
      }
    }

    setQueuedImagesArray(updatedQueue);
  };

  const handleChange = (e, setFieldValue) => {
    const input = e.target.value;

    const cleanedValue = input.replace(/\D/g, '');
    
    // Limit to 10 digits
    const limitedValue = cleanedValue.slice(0, 10);
    setFieldValue(e.target.name, e.target.name === "MRP" || e.target.name === "DiscountedPrice" || e.target.name === "Stock" ? limitedValue : e.target.value);
  };


  const handleSelectDetailKey = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("DetailKey", data.value);
    setSelectedDetailKey(data);
  };

  const handleSelectSize = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("SizeVariant", data.value);
    setSelectedOptions(data);
  };

  const handleSelectCategory = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("Category", data.value);
    setSelectedCategory(data);
  };

  const handleSelectFlavour = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("FlavourVariant", data.value);
    setSelectedFlavourOptions(data);
  };


  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("Name", formik?.values?.Name);
    formData.append("MRP", formik?.values?.MRP);
    formData.append("DiscountedPrice", formik?.values?.DiscountedPrice);
    formData.append("SKUs", formik?.values?.SKUs);
    formData.append("Stock", formik?.values?.Stock);
    formData.append("DetailKey", formik?.values?.DetailKey);
    formData.append("Category", formik?.values?.Category);
    formData.append("SizeVariant", formik?.values?.SizeVariant === undefined ? "" : formik?.values?.SizeVariant );
    formData.append("FlavourVariant",formik?.values?.FlavourVariant === undefined ? "" : formik?.values?.FlavourVariant);
    [...formik.values.ImagesUrls].forEach((image) => {
      formData.append("ImagesUrls", image);
    });
    isEditEvent
      ? dispatch(updateAdminProductAction(productId.get("_id"), formData))
      : dispatch(addProductAction(formData));
  };

  const formik = useFormik({
    initialValues: { productData },
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });


  const categoriesOptions = categories && categories.map((el)=> {
    return(
{label: el, value:el}
  )})
  // const parentProductList = adminParentProducts &&  adminParentProducts.map((el) => { return {label: el.Name, value: el.Name ,id: el._id}})
  // const parentProductId = adminParentProducts && adminParentProducts.map((el) => { return {label: el._id, value: el._id}})
  // const handleSelectParentProductId = (data, setFieldValue) => {
  //   // const productSize = data.map((el) => {
  //   //   return el.value;
  //   // });
  //   setFieldValue("RelationshipId", data.id);
  //   setSelectedIdOptions(data);
  // };
  return (
    <>
      <PageLayout>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Row>
            {/* <Col xl={12}>
              <CardLayout>
                <Breadcrumb title={data?.pageTitle}>
                  {data?.breadcrumb.map((item, index) => (
                    <li key={index} className="mc-breadcrumb-item">
                      {item.path ? (
                        <Anchor className="mc-breadcrumb-link" href={item.path}>
                          {item.text}
                        </Anchor>
                      ) : (
                        item.text
                      )}
                    </li>
                  ))}
                </Breadcrumb>
              </CardLayout>
            </Col> */}
            <Col xl={14}>
              <CardLayout>
                <CardHeader
                  title="ADD PRODUCT"
                  // dotsMenu={data?.dotsMenu}
                />
                <Row>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Product Name"
                      name="Name"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.Name}
                    />
                  </Col>
                  {formik.touched.Name && formik.errors.Name ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.Name}
                    </span>
                  ) : null}
                  
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="MRP"
                      label="Regular Price(₹)"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.MRP}
                    />
                  </Col>
                  {formik.touched.MRP && formik.errors.MRP ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.MRP}
                    </span>
                  ) : null}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      inputmode="numeric"
                      name="DiscountedPrice"
                      label="Sale Price(₹)"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.DiscountedPrice}
                    />
                  </Col>
                  {formik.touched.DiscountedPrice &&
                    formik.errors.DiscountedPrice ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.DiscountedPrice}
                    </span>
                  ) : null}
                  
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="SKUs"
                      label="SKUs"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.SKUs}
                    />
                  </Col>
                  {formik.touched.SKUs && formik.errors.SKUs ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.SKUs}
                    </span>
                  ) : null}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="Stock"
                      label="Inventory/Stock"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.Stock}
                    />
                  </Col>
                  {formik.touched.Stock && formik.errors.Stock ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.Stock}
                    </span>
                  ) : null}
                  <Col xl={12}>
                    <LabelField
                      type="creatable"
                      name="Category"
                      label="Category"
                      options={categoriesOptions}
                      onChange={(e) => handleSelectCategory(e, formik.setFieldValue)}
                      value={selectedCategory}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  {formik.touched.Category && formik.errors.Category ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.Category}
                    </span>
                  ) : null}

<Col xl={12}>
                    <LabelField
                      type="select"
                      name="DetailKey"
                      label="Choose Product Component"
                      options={detailKeyOptionList}
                      onChange={(e) => handleSelectDetailKey(e, formik.setFieldValue)}
                      value={selectedDetailKey}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                    <Col xl={12}>
                    <LabelField
                      type="creatable"
                      name="SizeVariant"
                      label="Size"
                      options={sizeOptionList}
                      onChange={(e) => handleSelectSize(e, formik.setFieldValue)}
                      value={selectedOptions}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                    <Col xl={12}>
                    <LabelField
                      type="creatable"
                      name="FlavourVariant"
                      label="Flavour"
                      options={flavourOptionList}
                      onChange={(e) => handleSelectFlavour(e, formik.setFieldValue)}
                      value={selectedFlavourOptions}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                </Row>
              </CardLayout>
            </Col>
            <Col xl={5}>
            </Col>
            <Col xl={12}>
              <CardLayout>
                <CardHeader title="Product Image(s)" />
                <Box className="mc-product-upload-media">
                  {[...queuedImagesArray].length > 0 &&
                    [...queuedImagesArray].map((img, i) => {
                      return (
                       isEditEvent ?  <Box key={i}  className="mc-product-upload-image">
                          <Image
                            src={isEditEvent &&  URL.createObjectURL(img)}
                          />
                          <span style={{color: "red" ,fontSize: '24px', cursor: 'pointer'}} onClick={() => deleteQueuedImage(i,formik.setFieldValue)}>&times;</span>
                        </Box> : <Box key={i}  className="mc-product-upload-image">
                          <Image
                            src={URL.createObjectURL(img)}
                          />
                          <span style={{color: "red" ,fontSize: '24px', cursor: 'pointer'}} onClick={() => deleteQueuedImage(i,formik.setFieldValue)}>&times;</span>
                        </Box>
                      );
                    })}
                  <Box className="mc-product-upload-file"
                  onDrop={(e) => handleDrop(e)}
                  onDragOver={(e) => e.preventDefault()}>
                    <ImageInput
                      type="file"
                      name="ImageUrls[]"
                      multiple
                      accept="image/*"
                      id="product"
                      onChange={(e) =>
                        onImageChange(e,formik.setFieldValue)
                      }
                    />
                    <Label htmlFor="product">
                      <Icon type="collections" />
                      <Text>{uploadFile}</Text>
                    </Label>
                  </Box>
                </Box>
                {isEditEvent ? (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Update"
                    icon="cloud_upload"
                  />
                ) : (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Add"
                    icon="cloud_upload"
                  />
                )}
              </CardLayout>
            </Col>
          </Row>
        </form>
      </PageLayout>
    </>
  );
};

export default ProductUpload;
