import { Box, Text } from "./elements";
import Pagination from "@mui/material/Pagination";

export default function ListPagination({pageCount,page,onChange,totalCount,products}) {

    return (
        <Box className="mc-paginate">
            <Text className="mc-paginate-title">Showing <b>{page === 1 ? products?.length : (products?.length) + (page -1)* 10}</b> of <b>{totalCount}</b> Results</Text>
            <Pagination
                    count={pageCount || 0}
                    page={page}
                    onChange={onChange}
                    variant="outlined"
                    color="primary"
                  />
        </Box>
    )
}
