
import React, { useEffect, useState } from "react";
import { Row, Col, Anchor } from "react-bootstrap";

import data from "../../../data/master/productUpload.json";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useQuery } from "../../../hooks/useQuery";
import PageLayout from "../../../layouts/PageLayout";
import { CardHeader, CardLayout } from "../../../components/cards";
import { LabelField, LabelTextarea } from "../../../components/fields";
import { Box } from "@mui/material";
import { Button, Icon, Image, Label, Text } from "../../../components/elements";
import ImageInput from "../../../components/elements/ImageInput";
import { Breadcrumb } from "../../../components";
import { getAdminProductsAction } from "../../../store/actions/adminProduct";
import { createHeroAction, getHeroDetailAction, updateHeroAction } from "../../../store/actions/CMS actions/heroSection";
import { hideLoader, showLoader } from "../../../store/actions/loader";
import { logDOM } from "@testing-library/react";
import fetchAndConvertImages from "../../../helpers/convertUrlToImages";


const CreateHeroSection = () => {
   
      const [uploadFile, setUploadFile] = React.useState("choose");
      const [previewImages, setPreviewImages] = useState([]);
      const [imageFile, setImageFile] = useState([]);
      const [selectedOptions, setSelectedOptions] = useState();
      const [selectedTypeOptions, setSelectedTypeOptions] = useState({label: 'Banner' , value: 'Banner'});
      const [content, setContent] = useState();
      const productId = useQuery();
      const [isEditEvent, setEditEventStatus] = useState(false);
      const dispatch = useDispatch();
      const location = useLocation();

      const { productList } = useSelector((state) => ({
        productList: state.adminProduct.adminProducts?.products,
      }));
    
      const [heroData, setHeroData] = useState({
        sectionType: "",
        product: "",
        BannerImage: "",
        quote: "",
        nutrition: "",
        description: "",
        ingredients: "",
      });


      useEffect(() => {
        const type = {label : 'Banner' , value: 'Banner'}
        formik.setFieldValue('sectionType' , type.value )
        dispatch(getAdminProductsAction())
      }, []);
    
      useEffect(() => {
        if (location?.state?.isHeroEdit) {
          dispatch(showLoader());
          dispatch(getHeroDetailAction(productId.get("_id"))).then((res) => {
            const selectedProducts = { label: res?.data?.product?.Name, value: res?.data?.product?._id };
            fetchAndConvertImages(res?.data?.BannerImage).then((fileList) => {
              formik.setFieldValue("BannerImage", fileList);
            });
          setSelectedOptions(selectedProducts)
            setEditEventStatus(true);
            formik.setFieldValue('product',selectedProducts?.value)
            formik.setFieldValue('quote',res?.data?.quote)
            formik.setFieldValue('nutrition',res?.data?.nutrition)
            formik.setFieldValue('description',res?.data?.description)
            formik.setFieldValue('ingredients',res?.data?.ingredients)
            setPreviewImages(res?.data?.BannerImage);
            setHeroData({
              product: selectedProducts?.value,
              BannerImage: res?.data?.BannerImage,
              quote: res?.data?.quote,
              nutrition: res?.data?.nutrition,
              description: res?.data?.description,
              ingredients: res?.data?.ingredients })
          })
         
         
          dispatch(hideLoader());
        }
      }, [location?.state?.isHeroEdit]);

      
    
      // useEffect(() => {
      //   dispatch(getBlogCategories());
      // }, []);
    
      const products =
      productList &&
      productList?.map((item) => {
      return { label: item?.productName, value: item?.productId };
    });

    const sectionTYpe = [{label: 'Banner' , value: 'Banner'},{label: 'Product' , value: 'Product'}]

    const handleUploadImage = (e, setFieldValue) => {
      if ([...e.target.files].length > 2) {
        return toastr.light("Maximum 2 image will be uploaded");
      } else {
        const newImageUrls = [];
        [...e.target.files].length >= 1 &&
          [...e.target.files].length < 3 &&
          [...e.target.files].forEach((image) =>
            newImageUrls.push(URL.createObjectURL(image))
          );
        setPreviewImages(newImageUrls);
        setFieldValue("BannerImage", e.target.files);
        setHeroData({...heroData,
          BannerImage: e.target.files})
      }
    };

    const handleSelectType = (data, setFieldValue) => {
      setFieldValue("sectionType", data.value);

      setHeroData({...heroData,
        sectionType: data.value,
     })

      setSelectedTypeOptions(data);
    };
    
      const handleChange = (e, setFieldValue) => {
        setFieldValue(e.target.name, e.target.value);
        setHeroData({...heroData,
          BannerImage: "",
          sectionType: formik?.values?.sectionType,
          product: formik?.values?.product,
          quote: formik?.values?.quote,
          nutrition: formik?.values?.nutrition,
          description: formik?.values?.description,
          ingredients: formik?.values?.ingredients })
      };
    
      const handleSelectCategory = (data, setFieldValue) => {
        setFieldValue("product", data.value);
   
        setHeroData({...heroData,
          product: data.value,
       })
       
        setSelectedOptions(data);
      };
     
    
      const handleSubmit = () => {
        let formData = new FormData();
        formData.append("sectionType", formik?.values?.sectionType);
        // formData.append("product", formik?.values?.product);
        // formData.append("quote", formik?.values?.quote);
        // formData.append("nutrition", formik?.values?.nutrition);
        // formData.append("description", formik?.values?.description);
        // formData.append("ingredients", formik?.values?.ingredients );
        [...formik.values.BannerImage].forEach((image) => {
          formData.append("BannerImage", image);
        });
        isEditEvent
        ? dispatch(updateHeroAction(productId.get("_id"), formData))
        :  dispatch(createHeroAction(formData))
        // isEditEvent
        //   ? dispatch(updateAdminBlogAction(productId.get("_id"), formData))
        //   : dispatch(addBlogAction(formData));
      };
    
      const formik = useFormik({
        initialValues: heroData,
        onSubmit: handleSubmit,
      });
    

      return (
        <>
          <PageLayout>
            <form
              onSubmit={formik.handleSubmit}
              encType="multipart/form-data"
              novalidate
            >
              <Row>
                <Col xl={12}>
                  <CardLayout>
                    <Breadcrumb title={"Create Hero Banner"}>
                    </Breadcrumb>
                  </CardLayout>
                </Col>
                <Col xl={14}>
                  <CardLayout>
                    <Row>
                    <Col xl={12}>
                        <LabelField
                          type="select"
                          name="sectionType"
                          label="Type"
                          options={sectionTYpe}
                          onChange={(e) =>
                            handleSelectType(e, formik.setFieldValue)
                          }
                          value={selectedTypeOptions}
                          fieldSize="w-100 h-md"
                        />
                      </Col>
                    {selectedTypeOptions.value === 'Banner' ?
                    <Col xl={12}>
                  <CardLayout>
                    <CardHeader title="Banner Image" />
                    <Box className="mc-product-upload-media">
                      
                          
                            <Box className="mc-product-upload-image">
                              <Image
                                src={previewImages}
                                
                                onChange={handleChange}
                              />
                            </Box>
                         
                       
                      <Box className="mc-product-upload-file">
                        <ImageInput
                          type="file"
                          name="BannerImage"
                          multiple={false}
                          accept="image/*"
                          id="blog"
                          onChange={(e) =>
                            handleUploadImage(e, formik.setFieldValue)
                          }
                        />
                        <Label htmlFor="blog">
                          <Icon type="collections" />
                          <Text>{uploadFile}</Text>
                        </Label>
                      </Box>
                    </Box>
                  </CardLayout>
                  {/* {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Create Hero"
                        icon="chat"
                      />
                    )} */}
                </Col>
                    :
                      <><Col xl={12}>
                        <LabelField
                          type="select"
                          name="product"
                          label="Product"
                          options={products}
                          onChange={(e) =>
                            handleSelectCategory(e, formik.setFieldValue)
                          }
                          value={selectedOptions}
                          fieldSize="w-100 h-md"
                        />
                      </Col>
                      
                      <Col xl={12}>
                        <LabelTextarea
                          label="Product Quote"
                          name="quote"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => handleChange(e, formik.setFieldValue)}
                          value={formik.values.quote}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Product Description"
                          name="description"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => handleChange(e, formik.setFieldValue)}
                          value={formik.values.description}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Product Nutrition"
                          name="nutrition"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => handleChange(e, formik.setFieldValue)}
                          value={formik.values.nutrition}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Product Ingredient"
                          name="ingredients"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => handleChange(e, formik.setFieldValue)}
                          value={formik.values.ingredients}
                        />
                      </Col>
                      </> }
                    </Row>
                  </CardLayout>
                </Col>
                <Col xl={5}></Col>
                <Col xl={12}>
                  {/* <CardLayout>
                    <CardHeader title="Blog Image(s)" dotsMenu={data?.dotsMenu} />
                    <Box className="mc-product-upload-media">
                      {previewImages &&
                        previewImages.map((img, i) => {
                          return (
                            <Box className="mc-product-upload-image">
                              <Image
                                src={img}
                                alt={i}
                                key={i}
                                onChange={handleChange}
                              />
                            </Box>
                          );
                        })}
                      <Box className="mc-product-upload-file">
                        <ImageInput
                          type="file"
                          name="blogImage[]"
                          multiple
                          accept="image/*"
                          id="blog"
                          onChange={(e) =>
                            handleUploadImage(e, formik.setFieldValue)
                          }
                        />
                        <Label htmlFor="blog">
                          <Icon type="collections" />
                          <Text>{uploadFile}</Text>
                        </Label>
                      </Box>
                    </Box>
                    {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Create Blog"
                        icon="chat"
                      />
                    )}
                  </CardLayout> */}
                  {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Create Hero"
                        icon="chat"
                      />
                    )}
                </Col>
              </Row>
            </form>
          </PageLayout>
        </>
      );
    };
    

export default CreateHeroSection;