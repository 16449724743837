
export const BASE_URL = process.env.REACT_APP_BASE_URL
// console.log(process.env,"process.env");
export const API_URL = {
    ADMIN_LOGIN : `${BASE_URL}/auth/login`,
    ADD_PRODUCT: `${BASE_URL}/catalog/product/create`,
    UPDATE_PRODUCT: `${BASE_URL}/catalog/product/update/:id`,
    DELETE_PRODUCT: `${BASE_URL}/catalog/product/status`,
    GET_ADMIN_PRODUCTS: `${BASE_URL}/catalog/products/all`,
    GET_ADMIN_ALL_PRODUCTS: `${BASE_URL}/catalog/products/allProductForDropdownList`,
    GET_PRODUCT: `${BASE_URL}/catalog/product`,
    GET_DROPDOWN_CATEGORIES_LIST : `${BASE_URL}/catalog/product/all/categories`,
    GET_DROPDOWN_PRODUCTS_LIST : `${BASE_URL}/catalog//product/by/categories`,
    GET_PARENT_PRODUCT: `${BASE_URL}/catalog/admin/parent-product/list`,
    GET_VARIANTS_PRODUCT: `${BASE_URL}/catalog/variants/product`,
    CREATE_COUPON: `${BASE_URL}/coupon/admin/create`,
    GET_ADMIN_COUPONS: `${BASE_URL}/coupon/all`,
    COUPON_STATUS: `${BASE_URL}/coupon/admin/status`,
    GET_COUPON: `${BASE_URL}/coupon/single-get`,
    UPDATE_COUPON: `${BASE_URL}/coupon/admin/update/:id`,
    GET_USER: `${BASE_URL}/Admin/users/all`,
    GET_USERS_DETAIL: `${BASE_URL}/Admin/user/get`,
    BLOCK_STATUS: `${BASE_URL}/Admin/user/block`,
    CREATE_BLOG: `${BASE_URL}/blogs/content/create`,
    UPDATE_BLOG: `${BASE_URL}/blogs/content/edit/:id`,
    GET_BLOGS: `${BASE_URL}/blogs/content-get/All`,
    GET_BLOGS_DETAIL: `${BASE_URL}/blogs/content-get/single`,
    GET_BLOGS_CATEGORIES: `${BASE_URL}/blogs/content/blog-categories/list`,
    GET_BLOGS_BY_CATEGORIES: `${BASE_URL}/blogs/content-getBy-category`,
    BLOG_STATUS: `${BASE_URL}/blogs/status`,
    ADMIN_CHANGE_PASSWORD: `${BASE_URL}/user/change-password`,
  
    // CMS API paths

    CREATE_HERO_SECTION: `${BASE_URL}/cms/Add/Hero-product`,
    GET_HERO_SECTION: `${BASE_URL}/cms/Hero-Section`,
    UPDATE_HERO_SECTION: `${BASE_URL}/cms/update/Hero-Product/:id`,
    GET_HERO_SECTION_DETAIL: `${BASE_URL}/cms/get/hero-product`,
    GET_HERO_SECTION_DELETE: `${BASE_URL}/cms/delete/Hero-Product`,

    FEATURED_STATUS: `${BASE_URL}/cms/product/featured`,
    GET_FEATURED_PRODUCTS: `${BASE_URL}/cms/featured/products`,

    FEATURED_BLOGS_STATUS: `${BASE_URL}/cms/Blog/featured`,
    GET_FEATURED_BLOGS: `${BASE_URL}/cms/featured/blogs`,

    FEATURED_VIDEOS_STATUS: `${BASE_URL}/cms/video/featured`,
    GET_FEATURED_VIDEOS: `${BASE_URL}/cms/featured/videos`,

    CREATE_OFFER_BANNER: `${BASE_URL}/cms/add/banner`,
    GET_OFFER_ZONE: `${BASE_URL}/cms/offer-zone`,
    BANNER_STATUS: `${BASE_URL}/cms/offers/Banner/status`,

    CREATE_FAQ: `${BASE_URL}/cms/add/FAQ`,
    GET_FAQ: `${BASE_URL}/cms/all/FAQs`,
    UPDATE_FAQ: `${BASE_URL}/cms/update/FAQ/:id`,
    GET_FAQ_DETAIL: `${BASE_URL}/cms/get/FAQ`,

    CREATE_INFLUENCER: `${BASE_URL}/cms/add/influencer`,
    UPDATE_INFLUENCER: `${BASE_URL}/cms/update/influencer-details/:id`,
    FEATURED_INFLUENCER_STATUS: `${BASE_URL}/cms/featured-status/influencer`,
    GET_SINGLE_INFLUENCER: `${BASE_URL}/cms/get/single-influencer`,
    GET_INFLUENCERS: `${BASE_URL}/cms/influencer/all`,
    DELETE_INFLUENCER: `${BASE_URL}/cms/delete/influencer`,

    POST_UPDATE_CONTACT_US : `${BASE_URL}/cms/add-contact-us`,
    GET_CONTACT_US_LIST : `${BASE_URL}/cms/Contact-us`,

    POST_UPDATE_OTHER_CONTENT : `${BASE_URL}/cms/add/other-content`,
    GET_OTHER_CONTENT : `${BASE_URL}/cms/other-content`,

    ADD_VIDEO: `${BASE_URL}/videos/add/new`,
    UPDATE_VIDEO: `${BASE_URL}/videos/update/:id`,
    CHANGE_VIDEOS_STATUS: `${BASE_URL}/videos/update/status`,
    GET_ALL_VIDEOS: `${BASE_URL}/videos/all`,
    GET_SINGLE_VIDEOS: `${BASE_URL}/videos`,

    //Add Store Locator
    ADD_STORE: `${BASE_URL}/store/locator/add`,
    GET_ALL_STORES: `${BASE_URL}/store/locator/all/outlets`,
    GET_SINGLE_STORES: `${BASE_URL}/store/locator/outlet/:id`,
    STORE_ACTIVE_STATUS: `${BASE_URL}/store/locator/status`,
    UPDATE_STORE: `${BASE_URL}/store/locator/update`,
    GET_STORE_BY_ZIPCODE: `${BASE_URL}/store/locator/outlets/by-zipcode`,

    //Review & Ratings List
    GET_ALL_REVIEW_RATINGS_LIST: `${BASE_URL}/product/all/reviews`,
    DELETE_REVIEW_RATINGS: `${BASE_URL}/product/delete/review`,
    CHANGE_REVIEW_RATINGS_FEATURED_STATUS: `${BASE_URL}/cms/review-rating/featured`,


    //Order List
    GET_ALL_ORDERS_LIST: `${BASE_URL}/order/admin/list/all`,
    CONFIRM_ORDER: `${BASE_URL}/order/admin/confirm`,
    CHANGE_ORDER_STATUS: `${BASE_URL}/order/admin/status/update`,
    CHANGE_RETURN_ORDER_STATUS: `${BASE_URL}/order/admin/returnStatus/update`,
    GET_ORDER_DETAILS: `${BASE_URL}/order/detail`,
    GET_USER_ORDERS_LIST: `${BASE_URL}/order/admin/user/checkouts`,
    GET_RETURN_ORDERS_LIST: `${BASE_URL}/order/admin/returnItemList/all`,
    GET_RETURN_ORDER_DETAILS: `${BASE_URL}/order/returnItemDetail`,

    //Newsletter Subscriber
    GET_NEWSLETTER_SUBSCRIBER: `${BASE_URL}/newsletter/subscribers`,
}

