import React from "react";
import { Link } from "react-router-dom";

export default function Anchor({ onClick, style, className,state, target, href, icon, iconClass, text, badge, arrow, children, ...rest }) {
    return (
        <Link style={style} to={ href } target={ target } state={state} onClick={ onClick } className={ className } {...rest}>
            { icon || iconClass ? <i className={ iconClass || "material-icons" }>{ icon }</i> : <></> }
            { text && <span>{ text }</span> }
            { badge && <sup className={ badge.variant }>{ badge.text }</sup> }
            { arrow && <small className="material-icons">{ arrow }</small>}
            { children }
        </Link>
    )
}