import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Label } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Radio, Switch } from "@mui/material";
import {
  createCouponAction,
  getAdminCouponDetailAction,
  updateAdminCouponAction,
} from "../../store/actions/adminCoupon";
import { discountTyoe } from "../../utils/couponConstants";
import { useLocation } from "react-router-dom";
import { hideLoader, showLoader } from "../../store/actions/loader";
import { useQuery } from "../../hooks/useQuery";
import {
  getAdminProductsAction,
  getDropdownCategories,
  getDropdownProducts,
} from "../../store/actions/adminProduct";
import LabelTinyEditor from "../../components/fields/LabelTinyEditor";

const CreateCoupon = () => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [categorySelectedOptions, setCategorySelectedOptions] = useState();
  const [selectedProducts, setSelectedProducts] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const productId = useQuery();

  const moment = require("moment");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isEditEvent, setEditEventStatus] = useState(false);
  const [couponData, setCouponData] = useState({
    Name: "",
    code: "",
    forAll: true,
    Status: "All",
    percentage: 0,
    MinimumPurchase: 0,
    discountType: "",
    discountValue: 0,
    productCategory: [],
    specificProduct: [],
    Description: "",
    validFrom: new Date().toISOString().slice(0, 10),
    validTo: new Date().toISOString().slice(0, 10),
    OneTimeOnly: false,
    firstTimeOnly: false,
    termnconditions: ""
  });

  const { categories, products, productList } = useSelector((state) => ({
    categories: state.adminProduct.adminCategories?.dropdownCategories,
    products: state.adminProduct.adminProducts?.dropdownProducts,
    productList: state.adminProduct.adminProducts?.products,
    meta: state.adminCoupon.adminCoupons?.meta,
  }));

  useEffect(() => {
    dispatch(getDropdownCategories());
    dispatch(getAdminProductsAction());
  }, []);

  const proCategory =
    categories &&
    categories.map((item) => {
      return { label: item, value: item };
    });

  const productsList =
    products &&
    products?.data?.map((item) => {
      return { label: item?.Name, value: item?._id };
    });

  const productsListing =
    productList &&
    productList?.map((item) => {
      return { label: item?.productName, value: item?.productId };
    });

  useEffect(() => {
    if (location?.state?.isCouponEdit) {
      dispatch(showLoader());
      dispatch(getAdminCouponDetailAction(productId.get("_id"))).then((res) => {
        const discount = {
          label: res?.data?.discountType,
          value: res?.data?.discountType,
        };
        setSelectedOptions(discount);
        const category =
          res?.data?.productCategory &&
          res?.data?.productCategory.map((item) => {
            return { label: item, value: item };
          });
        setCategorySelectedOptions(category);
        const products =
          res?.data?.specificProduct &&
          res?.data?.specificProduct.map((item) => {
            return { label: item?.Name, value: item?._id };
          });
        setSelectedProducts(products);
        setStartDate(
          new Date(moment(res?.data?.validFrom).format("YYYY-MM-DD"))
        );
        setEndDate(new Date(moment(res?.data?.validTo).format("YYYY-MM-DD")));
        setCouponData({
          ...couponData,
          Name: res?.data?.Name,
          code: res?.data?.code,
          MinimumPurchase: res?.data?.MinimumPurchase,
          discountType: discount?.value,
          percentage: res?.data?.percentage,
          discountValue: res?.data?.discountValue,
          productCategory: res?.data?.productCategory,
          specificProduct: res?.data?.specificProduct,
          Description: res?.data?.Description,
          validFrom: moment(res?.data?.validFrom).format("YYYY-MM-DD"),
          validTo: moment(res?.data?.validTo).format("YYYY-MM-DD"),
          OneTimeOnly: res?.data?.OneTimeOnly,
          firstTimeOnly: res?.data?.firstTimeOnly,
          termnconditions: res?.data?.termnconditions
        });
        setEditEventStatus(true);
      });
      dispatch(hideLoader());
    }
  }, [location?.state?.isCouponEdit]);

  // const handleChange = (e, setFieldValue) => {
  //   setFieldValue(e.target.name, e.target.value);
  // };
  const handleChangeAll = (evt) => {
    setCategorySelectedOptions();
    setSelectedProducts();
    setCouponData({
      ...couponData,
      [evt.target.name]: evt.target.value,
      forAll: true,
      productCategory: [],
      specificProduct: [],
    });
  };

  const handleChangeCategory = (evt) => {
    setCategorySelectedOptions();
    setSelectedProducts();
    setCouponData({
      ...couponData,
      [evt.target.name]: evt.target.value,
      forAll: false,
      productCategory: [],
      specificProduct: [],
    });
  };

  const handleChangeProducts = (evt) => {
    setCategorySelectedOptions();
    setSelectedProducts();
    setCouponData({
      ...couponData,
      [evt.target.name]: evt.target.value,
      forAll: false,
      productCategory: [],
      specificProduct: [],
    });
    dispatch(getAdminProductsAction());
  };

  const handleChange = (evt) => {
    const input = evt.target.value;

    const cleanedValue = input.replace(/\D/g, '');
    
    // Limit to 10 digits
    const limitedValue = cleanedValue.slice(0, 10);
    setCouponData({ ...couponData, [evt.target.name]: evt.target.name === "percentage" || evt.target.name === "MinimumPurchase" || evt.target.name === "discountValue" ? limitedValue : evt.target.value });
    // formik.setFieldValue({
    // ...couponData,
    // [evt.target.name]: evt.target.value,
    // });
  };

  const handleChangeStartDate = (e) => {
    setStartDate(e);
    setCouponData({ ...couponData, validFrom: moment(e).format("YYYY-MM-DD") });
    // setFieldValue('validFrom', e);
  };

  const handleChangeEndDate = (e) => {
    setEndDate(e);
    setCouponData({ ...couponData, validTo: moment(e).format("YYYY-MM-DD") });
    // setFieldValue('validTo', e);
  };

  const handleSelectDiscountType = (data) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setCouponData({
      ...couponData,
      discountType: data.value,
      percentage: "",
      MinimumPurchase: "",
      discountValue: "",
    });
    // setFieldValue("discountType", data?.value);
    setSelectedOptions(data);
  };

  const handleSelectProductCategory = (data) => {
    const prodCategory = data.map((el) => {
      return el.value;
    });
    data.forEach((element) => {
      dispatch(getDropdownProducts(element.label));
    });

    setCouponData({ ...couponData, productCategory: prodCategory });
    // setFieldValue("productCategory", productCategory);
    setCategorySelectedOptions(data);
  };

  const handleSelectProduct = (data) => {
    const productsSelected = data.map((el) => {
      return el?.value;
    });
    setCouponData({ ...couponData, specificProduct: productsSelected });
    setSelectedProducts(data);
  };

  const handleSubmit = () => {
    isEditEvent
      ? dispatch(updateAdminCouponAction(productId.get("_id"), couponData))
      : dispatch(dispatch(createCouponAction(couponData)));
  };

  const formik = useFormik({
    initialValues: { couponData },
    // validationSchema: variantsValidationsSchema,
    onSubmit: handleSubmit,
  });

  // const handleChangeStatus = (event) => {
  //     setCouponData({...couponData, Status : event.target.value});
  //   };
  const handleChangeOneTime = () => {
    setCouponData({ ...couponData, OneTimeOnly: !couponData.OneTimeOnly });
  };
  const handleChangeFirstTime = () => {
    setCouponData({ ...couponData, firstTimeOnly: !couponData.firstTimeOnly });
  };

  return (
    <>
      <PageLayout>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Row>
            {/* <Col xl={12}>
              <CardLayout>
                <Breadcrumb title={"Add Coupon"}></Breadcrumb>
              </CardLayout>
            </Col> */}
            <Col xl={14}>
              <CardLayout>
                <CardHeader
                  title="CREATE COUPON"
                  // dotsMenu={data?.dotsMenu}
                />
                <Row>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Coupon Name"
                      name="Name"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e)}
                      value={couponData.Name}
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Coupon Code"
                      name="code"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e)}
                      value={couponData.code}
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelField
                      type="select"
                      name="discountType"
                      label="Discount Type"
                      options={discountTyoe}
                      onChange={(e) => handleSelectDiscountType(e)}
                      value={selectedOptions}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  {selectedOptions?.value === "percentage" ? (
                    <>
                      <Col xl={6}>
                        <LabelField
                          type="text"
                          name="percentage"
                          label="Percentage ( % )"
                          fieldSize="w-100 h-md"
                          onChange={(e) => handleChange(e)}
                          value={couponData.percentage}
                        />
                      </Col>
                      <Col xl={6}>
                        <LabelField
                          type="text"
                          style={{}}
                          name="MinimumPurchase"
                          label="Minimum Purchase(₹)"
                          fieldSize="w-100 h-md"
                          onChange={(e) => handleChange(e)}
                          value={couponData.MinimumPurchase}
                        />
                      </Col>
                      <Col xl={6}>
                        <LabelField
                          type="text"
                          inputmode="numeric"
                          name="discountValue"
                          label="Maximum Discount(₹)"
                          fieldSize="w-100 h-md"
                          onChange={(e) => handleChange(e)}
                          value={couponData.discountValue}
                        />
                      </Col>
                    </>
                  ) : selectedOptions?.value === "fixed" ? (
                    <>
                      {" "}
                      <Col xl={6}>
                        <LabelField
                          type="text"
                          name="MinimumPurchase"
                          label="Minimum Purchase(₹)"
                          fieldSize="w-100 h-md"
                          onChange={(e) => handleChange(e)}
                          value={couponData.MinimumPurchase}
                        />
                      </Col>
                      <Col xl={6}>
                        <LabelField
                          type="text"
                          inputmode="numeric"
                          name="discountValue"
                          label="Discount Value(₹)"
                          fieldSize="w-100 h-md"
                          onChange={(e) => handleChange(e)}
                          value={couponData.discountValue}
                        />
                      </Col>
                    </>
                  ) : selectedOptions?.value === "freeShipping" ? (
                    <Col xl={6}>
                      <LabelField
                        type="text"
                        name="MinimumPurchase"
                        label="Minimum Purchase(₹)"
                        fieldSize="w-100 h-md"
                        onChange={(e) => handleChange(e)}
                        value={couponData.MinimumPurchase}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  <div>
                    <Label className="mc-label-field-title">
                      {"Apply Coupon On"}
                    </Label>
                    <br></br>
                    <FormControlLabel
                      name="Status"
                      checked={
                        couponData.productCategory?.length === 0 &&
                        couponData?.specificProduct?.length === 0 &&
                        couponData.Status === "All"
                      }
                      onChange={(e) => handleChangeAll(e)}
                      value="All"
                      control={<Radio />}
                      label="All Category & Products"
                    />
                    <FormControlLabel
                      name="Status"
                      checked={
                        couponData?.productCategory?.length !== 0 ||
                        couponData.Status === "Category"
                      }
                      onChange={(e) => handleChangeCategory(e)}
                      value="Category"
                      control={<Radio />}
                      label="Category wise Products"
                    />
                    <FormControlLabel
                      name="Status"
                      checked={
                        (couponData?.productCategory?.length === 0 &&
                          couponData?.specificProduct?.length !== 0) ||
                        couponData.Status === "Products"
                      }
                      onChange={(e) => handleChangeProducts(e)}
                      value="Products"
                      control={<Radio />}
                      label="Specific Products"
                    />
                  </div>
                  {couponData?.productCategory?.length !== 0 ||
                  couponData.Status === "Category" ? (
                    <>
                      {" "}
                      <Col xl={12}>
                        <LabelField
                          type="multi_select"
                          name="productCategory"
                          label="Category"
                          options={proCategory}
                          onChange={(e) =>
                            handleSelectProductCategory(e, formik.setFieldValue)
                          }
                          value={categorySelectedOptions}
                          fieldSize="w-100 h-md"
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelField
                          type="multi_select"
                          name="productCategory"
                          label="Products"
                          options={productsList}
                          onChange={(e) =>
                            handleSelectProduct(e, formik.setFieldValue)
                          }
                          value={selectedProducts}
                          fieldSize="w-100 h-md"
                        />
                      </Col>{" "}
                    </>
                  ) : (couponData?.productCategory?.length === 0 &&
                      couponData?.specificProduct?.length !== 0) ||
                    couponData.Status === "Products" ? (
                    <Col xl={12}>
                      <LabelField
                        type="multi_select"
                        name="productCategory"
                        label="Products"
                        options={productsListing}
                        onChange={(e) =>
                          handleSelectProduct(e, formik.setFieldValue)
                        }
                        value={selectedProducts}
                        fieldSize="w-100 h-md"
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col xl={6}>
                    <LabelField
                      type="date"
                      minDate={moment().toDate()}
                      selected={startDate}
                      name="validFrom"
                      label="Valid From"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChangeStartDate(e)}
                    />
                  </Col>
                  <Col xl={6}>
                    <LabelField
                      type="date"
                      minDate={startDate}
                      selected={endDate}
                      name="validTo"
                      label="Valid To"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChangeEndDate(e)}
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelTextarea
                      label="Description"
                      name="Description"
                      fieldSize="w-100 h-text-md"
                      onChange={(e) => handleChange(e)}
                      value={couponData.Description}
                    />
                  </Col>

                  <Col xl={14}>
                    <LabelTinyEditor
                      label="Terms & Conditions"
                      value={couponData.termnconditions}
                      fieldSize="w-200 h-text-md"
                      onEditorChange={(e) => setCouponData({...couponData,termnconditions: e})}
                    />
                  </Col>

                  <div>
                    <FormControlLabel
                      onChange={handleChangeOneTime}
                      checked={couponData.OneTimeOnly}
                      value={couponData.OneTimeOnly}
                      control={<Switch />}
                      label="One Time Only"
                    />
                    <FormControlLabel
                      onChange={handleChangeFirstTime}
                      checked={couponData.firstTimeOnly}
                      value={couponData.firstTimeOnly}
                      control={<Switch />}
                      label="First Purchase Only"
                    />
                  </div>
                </Row>
                {isEditEvent ? (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Update Coupon"
                    icon="discount"
                  />
                ) : (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Create Coupon"
                    icon="discount"
                  />
                )}
              </CardLayout>
            </Col>
          </Row>
        </form>
      </PageLayout>
    </>
  );
};

export default CreateCoupon;
