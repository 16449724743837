import { API_URL } from "../../utils/apiPaths";
import { request } from "../api";

export function createUpdateOtherContents(params,token) {
    return request('POST', API_URL.POST_UPDATE_OTHER_CONTENT, params,token);
  }
  
  export function getOtherContentDetails(params,token) {
    return request('GET', API_URL.GET_OTHER_CONTENT, params,token);
  }
