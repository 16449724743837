import React, { memo, useState } from 'react';
import constant from '../../../utils/constants';

const TableHeaderRow = ( { configData, onAscOrder, onDesOrder } ) => {
  const [sort,setSort] = useState(true)

  const onAscending = (A,B) => {
    onAscOrder(A, B )
    setSort(false)
  }
  const onDescending = (X,Y) => {
    onDesOrder( X, Y )
    setSort(true)
  }
  return (
    configData && configData.map( ( item, index ) => (
      <th key={index}>
        {item.title}
        {item.sorter && (
          <>
            <span class="fa fa-fw fa-sort"  onClick={() => sort ? 
                onAscending( item.sorterName, constant.ORDER_BY.ASC ) :  onDescending( item.sorterName, constant.ORDER_BY.DESC )
              }></span>
            {/* <span
              className='icon-up-arrow'
              onClick={() =>
                onAscOrder( item.sorterName, constant.ORDER_BY.ASC )
              }
            ></span> */}
              {/* <span class="fa fa-fw fa-sort-down"   onClick={() =>
                onDesOrder( item.sorterName, constant.ORDER_BY.DESC )
              }></span> */}
            {/* <span
              className='icon-down-arrow'
              onClick={() =>
                onDesOrder( item.sorterName, constant.ORDER_BY.DESC )
              }
            ></span> */}
          </>
        )}
      </th>
    ) )
  );
};

export default memo( TableHeaderRow );
