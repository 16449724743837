import React, { useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { List, Item, Icon, Text, Box, Anchor } from "../../components/elements";
import { Breadcrumb, RoundAvatar, DivideTitle, DuelText } from "../../components";
import { CardLayout, CardHeader, FloatCard, ActivityCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/userProfile.json";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../hooks/useQuery";
import { getAdminUsersDetailAction } from "../../store/actions/adminUsers";
import { OrdersTable } from "../../components/tables";
import ListPagination from "../../components/ListPagination";
import { adminOrdersTableConfigs } from "../../utils/tableConstants";
import { getUserOrdersListAction } from "../../store/actions/adminOrders";
import { useLocation } from "react-router-dom";
import UserOrdersListTable from "../../components/tables/UserOrdersListTable";

export default function UserProfile() {
    let [page, setPage] = useState(1);
    const dispatch = useDispatch();

  const query = useQuery();
  const { adminUsersDetail,meta,userOrders } = useSelector((state) => ({
    adminUsersDetail: state.adminUsers.adminUsersDetail,
    userOrders: state.adminOrders.adminUserOrders?.userOrders,
    meta: state.adminOrders.adminUserOrders?.meta,
  }));
 const location = useLocation()
  useEffect(() => {
    if (location?.state?.userId) {
      dispatch(getAdminUsersDetailAction(location?.state?.userId))
      dispatch(getUserOrdersListAction(location?.state?.userId));
    }
  }, [location?.state?.userId]);
console.log(adminUsersDetail,"adminUsersDetail");
//   const handlePageChange = (e, p) => {
//     setPage(p);
//     const requestParams = Object.assign({
//       page: p,
//     });
//     dispatch(getUserOrdersListAction(requestParams));
//   };
  const memoizedTableConfig = useMemo(() => adminOrdersTableConfigs, []);
  const count = Math.ceil(meta?.totalCount / 10);
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title="User(s) Profile">
                            {data?.breadcrumb.map((item, index) => (
                                <Item key={ index } className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                                </Item>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        {/* <CardHeader title="user information" dotsMenu={ data?.dotsMenu } /> */}
                        <Box className="mc-user-group">
                            <Box className="mc-user-profile">
                                <RoundAvatar 
                                    // image={ 'images/avatar/09.png' }
                                    src={adminUsersDetail?.photoUrl !== "" ? adminUsersDetail?.photoUrl :   'images/avatar/09.png' } 
                                    alt={ data?.profile.alt } 
                                    size={ data?.profile.size } 
                                />
                                <DuelText 
                                    title={ adminUsersDetail?.name }
                                    // descrip={ data?.profile.username } 
                                    // size={ data?.profile.size }
                                />
                            </Box>
                            <Box className="mb-4">
                                <DivideTitle title="communication" className="mb-4" />
                                <List className="mc-user-metalist">
                                    {/* {data?.contact.map((item, index)=> (
                                        <Item key={ index }>
                                            <Icon>{ item.icon }</Icon>
                                            <Text as="span">{ item.text }</Text>
                                        </Item>
                                    ))} */}
                                     {/* <Item>
                                            <Icon>{ "phone_in_talk" }</Icon>
                                            <Text as="span">{ adminUsersDetail?.phone !== "" ? adminUsersDetail?.phone : "-" }</Text>
                                        </Item> */}
                                        <Item >
                                            {/* <Icon>{ "phone_in_talk" }</Icon> */}
                                            <Text as="span" >{ 'Phone:' }</Text>
                                            <Text as="span" style={{fontWeight: 'bold'}}>{ adminUsersDetail?.phone !== "" ? adminUsersDetail?.phone : "-" }</Text>
                                        </Item>
                                        <Item>
                                            {/* <Icon>{ "feed" }</Icon> */}
                                            <Text as="span" >{ 'Email:' }</Text>
                                            <Text as="span" style={{fontWeight: 'bold'}}>{ adminUsersDetail?.email }</Text>
                                        </Item>
                                        <DivideTitle title="More Details" className="mt-4 mb-4" />
                                        <Item>
                                        <Text as="span" >{ 'Role:' }</Text>
                                            <Text as="span" style={{fontWeight: 'bold'}}>{ adminUsersDetail?.role }</Text>
                                        </Item>
                                        <Item>
                                        <Text as="span" >{ 'Status:' }</Text>
                                            <Text as="span" style={{fontWeight: 'bold'}}>{ adminUsersDetail?.Blocked  ? 'Blocked' : 'Active' }</Text>
                                        </Item>
                                        <Item>
                                        <Text as="span" >{ 'Email Verified:' }</Text>
                                            <Text as="span" style={{fontWeight: 'bold'}}>{ adminUsersDetail?.emailVerified ? "Yes" : "No" }</Text>
                                        </Item>
                                        <Item>
                                        <Text as="span" >{ 'Phone Verified:' }</Text>
                                            <Text as="span" style={{fontWeight: 'bold'}}>{ adminUsersDetail?.phoneVerified ? "Yes" : "No" }</Text>
                                        </Item>
                                        {/* <Item>
                                            <Icon>{ "public" }</Icon>
                                            <Text as="span">{ '-' }</Text>
                                        </Item>
                                        <Item>
                                            <Icon>{ "map" }</Icon>
                                            <Text as="span">{ '-' }</Text>
                                        </Item> */}
                                </List>
                                <DivideTitle title="Orders List" className="mt-4 mb-4" />

                            </Box>
                            {/* <Box className="mb-4">
                                <DivideTitle title={ data?.bio.title } className="mb-3" />
                                <Text className="mc-user-bio mb-4">{ data?.bio.descrip }</Text>
                            </Box> */}
                            {/* <Box>
                                <DivideTitle title="elsewhere" className="mb-4" />
                                <Box className="mc-user-social">
                                    {data?.social.map((item, index)=> (
                                        <Anchor 
                                            key = { index } 
                                            href = { item.path }
                                            text = { item.type }
                                            iconClass = { item.icon }
                                            className = { item.type }
                                        />
                                    ))}
                                </Box>
                            </Box> */}

                        </Box>
                        <Col xl={12}>
                  {userOrders?.length ? (
                    <>
                      {" "}
                      <UserOrdersListTable
                        memoizedTableConfig={memoizedTableConfig}
                        products={userOrders}
                      />
                      {/* <ListPagination
                        products={userOrders}
                        totalCount={meta?.totalCount}
                        pageCount={count}
                        page={page}
                        onChange={handlePageChange}
                      /> */}
                    </>
                  ) : (
                    <div
                      className="no-record"
                      style={{
                        fontSize: "26px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "200px",
                        margin: "0 auto",
                      }}
                    >
                      No Result Found
                    </div>
                  )}
                  {/* <Pagination products={meta?.totalCount}/> */}
                </Col>
                    </CardLayout>
                </Col>
                <Col xl={7}>
                    
                    {/* <Row>
                        {data?.float.map((item, index) => (
                            <Col md={4} lg={4} key={ index }>
                                <FloatCard 
                                    variant={ item.variant }
                                    digit={ item.digit }
                                    title={ item.title }
                                    icon={ item.icon }
                                />
                            </Col>
                        ))}
                        <Col xl={12}>
                            <ActivityCard 
                                style={{ height: "540px" }}
                                title={ data?.activity.title }
                                dotsMenu={ data?.activity.dotsMenu }
                                items={ data?.activity.items }
                            />
                        </Col>
                    </Row> */}
                </Col>
            </Row>
        </PageLayout>
    )
}