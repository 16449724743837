import { START_LOADER, STOP_LOADER } from "../actionTypes";

const initialState = {
  loading: false,
};

const loader = function (state = initialState, action = "") {
  switch (action.type) {
    case START_LOADER:
      return {
        loading: true,
      };

    case STOP_LOADER:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export { loader };
