import { toastr } from "react-redux-toastr";
import { changeOfferStatus, createOfferBanner, getOffersBannerList } from "../../../services/CMS Services/offerZoneServices";
import { FAQsConstants, contactsConstants, heroConstant, offerZoneConstants } from "../../../utils/CMSConstants";
import constant from "../../../utils/constants";
import { CHANGE_OFFER_STATUS_FAILURE, CHANGE_OFFER_STATUS_SUCCESS, CONTACT_API_END, CONTACT_API_REQUEST, CREATE_FAQS_FAILURE, CREATE_FAQS_FAILUTRE, CREATE_FAQS_SUCCESS, CREATE_OFFER_ZONE_FAILUTRE, CREATE_OFFER_ZONE_SUCCESS, CREATE_UPDATE_CONTACTS_FAILURE, CREATE_UPDATE_CONTACTS_SUCCESS, FAQ_API_END, FAQ_API_REQUEST, GET_CONTACTS_FAILURE, GET_CONTACTS_SUCCESS, GET_FAQS_DETAIL_FAILURE, GET_FAQS_DETAIL_SUCCESS, GET_FAQS_LIST_FAILURE, GET_FAQS_LIST_SUCCESS, GET_OFFER_BANNER_LIST_FAILURE, GET_OFFER_BANNER_LIST_SUCCESS, HERO_SECTION_API_REQUEST, OFFER_API_END, OFFER_ZONE_API_REQUEST, START_LOADER, STOP_LOADER, UPDATE_FAQS_FAILURE, UPDATE_FAQS_SUCCESS } from "../../actionTypes";
import history from "../../../utils/history";
import { hideLoader } from "../loader";
import { createFAQ, getFAQList } from "../../../services/CMS Services/FAQsServices";
import { createUpdateContacts, getContactsDetail } from "../../../services/CMS Services/contactUsServices";

const { CONTACT_API_TYPES } = contactsConstants;
const { ERROR_MESSAGES } = constant;

//Add/Create/Update Contacts
export const createUpdateContactAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: CONTACT_API_REQUEST,
        payload: CONTACT_API_TYPES.CREATE_UPDATE_CONTACTS,
      });
      const response = await createUpdateContacts(param,token);
      toastr.success(response.message);
      dispatch({ type: CREATE_UPDATE_CONTACTS_SUCCESS, payload: response.data });
      dispatch({ type: STOP_LOADER });
      history.push("/contactUs");
    } catch (error) {
      const err = Object.values(error?.data?.error || {}) || "    ";
      dispatch({ type: STOP_LOADER });
      dispatch({ type: CREATE_UPDATE_CONTACTS_FAILURE, payload: error });
      if (err[0]) {
        toastr.error(err[0]);
      } else {
        toastr.error(error.data.message);
      }
    } finally {
      dispatch({
        type: CONTACT_API_END,
      });
    }
  };
  
  //Get Contacts
  export const getContactsListAction = (params) => {
    const token = localStorage.getItem("token");
    return async (dispatch) => {
      try {
        // dispatch( showLoader() );
        // dispatch({ type: START_LOADER });
        dispatch({
          type: CONTACT_API_REQUEST,
          payload: CONTACT_API_TYPES.GET_CONTACTS,
        });
        const response = await getContactsDetail(params,token);
        const dataList = response.data;
        dispatch({
          type: GET_CONTACTS_SUCCESS,
          payload: { dataList, meta: dataList },
        });
        dispatch(hideLoader());
        return response;
      } catch (error) {
        dispatch(hideLoader());
        dispatch({
          type: GET_CONTACTS_FAILURE,
          payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
        });
        toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
      } finally {
        dispatch({
          type: CONTACT_API_END,
        });
      }
    };
  };

 
