import { API_URL } from "../../utils/apiPaths";
import { request } from "../api";


export function addInfluencers(params,token) {
  return request('POST', API_URL.CREATE_INFLUENCER, params,token);
}

export function getInfluencers(params,token) {
  return request('GET', API_URL.GET_INFLUENCERS, params,token);
}

export function updateInfluencers(params, formData,token) {
  return request('PUT', API_URL.UPDATE_INFLUENCER.replace(':id', params), formData,token);
}

export function getSingleInfluencers(params,token) {
  return request('GET', `${API_URL.GET_SINGLE_INFLUENCER}/${params}`,"",token);
}

export function deleteInfluencers(params,token) {
  return request('DEL', `${API_URL.DELETE_INFLUENCER}/${params}`, params,token);
}

export function featuredInfluencersStatusChange(params,token) {
    return request('PUT', `${API_URL.FEATURED_INFLUENCER_STATUS}/${params}`, params,token);
  }



export const Influencers = {
  addInfluencers,
  getInfluencers,
  updateInfluencers,
  getSingleInfluencers,
  deleteInfluencers,
  featuredInfluencersStatusChange
};
