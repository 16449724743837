import ReduxToastr from "react-redux-toastr";

export const Toaster = () => {
  return (
    <ReduxToastr
      timeOut='5000'
      newestOnTop={true}
      preventDuplicates
      position='top-center'
      transitionIn='bounceIn'
      transitionOut='bounceOut'
      progressBar={false}
      closeOnToastrClick={false}
      getState={(state) => state.toastr} // This is the default
      className="toast-msg"
    />
  );
};
