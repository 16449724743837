import constant from "../../utils/constants";
import {
  ADMIN_USERS_API_REQUEST,
  ADMIN_USER_API_END,
  BLOCK_UNBLOCK_ADMIN_USERS_FAILURE,
  BLOCK_UNBLOCK_ADMIN_USERS_SUCCESS,
  GET_ADMIN_USERS_DETAIL_FAILURE,
  GET_ADMIN_USERS_DETAIL_SUCCESS,
  GET_ADMIN_USERS_FAILURE,
  GET_ADMIN_USERS_SUCCESS,
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminUsers: { users: [], meta: "" },
};

const adminUsers = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case ADMIN_USERS_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case ADMIN_USER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_ADMIN_USERS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminUsers: { users: [], meta: "" },
      };
    case GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        adminUsers: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          users: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_USERS_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminUsersDetail: action.payload,
        loading: false,
      };
    case GET_ADMIN_USERS_DETAIL_FAILURE:
    case BLOCK_UNBLOCK_ADMIN_USERS_FAILURE:
    case BLOCK_UNBLOCK_ADMIN_USERS_SUCCESS:
            return {
              ...state,
              status: API_STATUS.SUCCESS,
              adminUsers: {
                ...state.adminUsers,
                users: state.adminUsers.users,
              },
            };

    default:
      return state;
  }
};

export { adminUsers };
