
const constant = {

  ORDER_BY: {
    ASC: 'asc',
    DESC: 'desc',
  },

  API_STATUS: {
    START: 'START',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    END: 'END',
  },

  ERROR_MESSAGES: {
    EXCEPTION_ERROR: 'Something went wrong',
  }

}

export default constant;