import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Item, Anchor, Button } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import OrdersTable from "../../components/tables/OrdersTable";
import LabelField from "../../components/fields/LabelField";
import { debounce } from "lodash";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/orderList.json";
import { useDispatch, useSelector } from "react-redux";
import useAddQuery from "../../hooks/useAddQuery";
import useForm from "../../hooks/useForm";
import { getOrdersListAction, getOrdersStatusCountsAction } from "../../store/actions/adminOrders";
import { useSetUrlParams } from "../../hooks/useSetUrlParams";
import { adminOrdersTableConfigs } from "../../utils/tableConstants";
import { Link } from "react-router-dom";
import ListPagination from "../../components/ListPagination";

export default function OrderList() {
    let [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { addQuery } = useAddQuery();
    const [orderID,setOrderID] = useState();
    const [orderStatus,setOrderStatus] = useState("");
    const paramsQuery = new URLSearchParams(window.location.search);
    const { orders, loading, meta,meta2 } = useSelector((state) => ({
      orders: state.adminOrders.adminOrders?.orders,
      loading: state.loader.loading,
      // status: state.adminProduct.status,adminOrders adminOrdersStatus
      meta: state.adminOrders.adminOrders?.meta,
      meta2: state.adminOrders.adminOrdersStatus?.meta2,
      // apiType: state.adminProduct.apiType,
    }));
  
    function getUrlParameter(key, col) {
      let paramsQuerys;
      if (key === "orderId" || key === "sortColumn" || key === "sortOrder") {
        paramsQuerys = paramsQuery.get(key) === "" ? col : paramsQuery.get(key);
      }
      // if( key === 'category' || key === 'skus' || key === 'status' ) {
      //   paramsQuerys = paramsQuery.get( key ) === '' ? [] : JSON.parse( paramsQuery.get( key ) );
      // }
      return paramsQuerys;
    }
  
    const { values, handleChange } = useForm(
      {
        searchDummyText: getUrlParameter("orderId", ""),
        orderId: getUrlParameter("orderId", ""),
        sortBy: getUrlParameter("sortColumn", ""),
        sortOrder: getUrlParameter("sortOrder", "ASC"),
        page: 1,
        publishedOn:
          paramsQuery.get("publishedOn") === null ||
          paramsQuery.get("publishedOn") === ""
            ? false
            : paramsQuery.get("publishedOn"),
      },
      {}
    );
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //   const getData = setTimeout(() => {
    //     dispatch(getAdminProductsAction());
    //   }, 3000);
    //   return () => clearTimeout(getData);
    //   // dispatch(getAdminProductsAction());
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
  
    // useEffect(() => {
    // const requestParams = Object.assign(
    // {
    // perPage: 10,
    // },
    // );
    // dispatch(getAdminProductsAction(requestParams));
    // },[dispatch])
    useEffect(() => {
      const requestParams = Object.assign(
        {
          perPage: 10,
          page: values.page,
          orderId : values.name
        },
        values.search && { OrderId: values.search },
        values.sortBy && { sortBy: values.sortBy }
      );
      dispatch(getOrdersListAction(requestParams));
      dispatch(getOrdersStatusCountsAction());
    }, [
      values.sortOrder,
      values.name,
      values.page,
      values.sortBy,
      values.search,
    ]);
  
  
    const count = Math.ceil(meta?.totalCount / 10);
  
    
    const handlePageChange = (e, p) => {
      setPage(p);
      const requestParams = Object.assign({
        status: orderStatus.value,
        page: p,
      });
      dispatch(getOrdersListAction(requestParams));
    };
    
    const debouncedSave = useCallback(
      debounce((nextValue) => handleChange("search", nextValue), 1000),
      []
    );
  
    const searchHandle = (e) => {
      const { value: nextValue } = e.target;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      addQuery(useSetUrlParams("orderId", nextValue));
      handleChange("searchDummyText", nextValue);
      debouncedSave(nextValue);
      handleChange("page", 1);
    };
  
    const handleSorting = (sortBy, sortingType) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      addQuery(useSetUrlParams("sortColumn", sortBy));
      // eslint-disable-next-line react-hooks/rules-of-hooks
      addQuery(useSetUrlParams("sortOrder", sortingType));
      handleChange("name", sortingType);
      handleChange("sortOrder", sortingType);
      handleChange("page", 1);
    };
    const memoizedTableConfig = useMemo(() => adminOrdersTableConfigs, []);
    const memoizedHandleClick = useCallback(
      (sortBy, orderType) => handleSorting(sortBy, orderType),
      []
    );
  
    const handleClear = () => {
      handleChange("searchDummyText", "");
      // eslint-disable-next-line react-hooks/rules-of-hooks
      addQuery(useSetUrlParams("search", ""));
      debouncedSave("");
    };

    const handleSelectStatus = (data, setFieldValue) => {
      // const productSize = data.map((el) => {
      //   return el.value;
      // });
      setOrderStatus(data);
      const requestParams = Object.assign({
        status: data.value
      });
      dispatch(getOrdersListAction(requestParams));
    };

    const clearMenuSelection = () => {
      const requestParams = Object.assign({
        status: ""
      });
      dispatch(getOrdersListAction(requestParams));
      setOrderStatus('')
    }
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={ "ORDER LIST" }>
                            {data?.breadcrumb.map((item, index) => (
                                <Item key={ index } className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                                </Item>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                {/* {data?.float.map((item, index) => (
                    <Col key={ index } xl={3}>
                        <FloatCard 
                            variant = { item.variant }
                            digit = { item.digit }
                            title = { item.title }
                            icon = { item.icon }
                        />
                    </Col>
                ))} */}
                  <Col xl={3}>
                        <FloatCard 
                            variant = { "lg blue" }
                            digit = {meta2?.totalCount ? meta2?.totalCount : "0"}
                            title = { "total orders" }
                            icon = { "bookmark" }
                        />
                    </Col>
                <Col xl={3}>
                        <FloatCard 
                            variant = { "lg purple" }
                            digit = {meta2?.statusCounts?.processing ? meta2?.statusCounts?.processing : "0"}
                            title = { "pending orders" }
                            icon = { "pending" }
                        />
                    </Col>
                    <Col xl={3}>
                        <FloatCard 
                            variant = { "lg black" }
                            digit = {meta2?.statusCounts?.shipped ? meta2?.statusCounts?.shipped : "0"}
                            title = { "shipped orders" }
                            icon = { "local_shipping" }
                            style={{backgroundColor: "#518645"}}
                            iconColor={{color: "#FFD800"}}
                        />
                    </Col>
                    <Col xl={3}>
                        <FloatCard 
                            variant = { "lg green" }
                            digit = {meta2?.statusCounts?.delivered ? meta2?.statusCounts?.delivered : '0'}
                            title = { "delivered orders" }
                            icon = { "shopping_bag" }
                        />
                    </Col>
                    <Col xl={3}>
                        <FloatCard 
                            variant = { "lg red" }
                            digit = {meta2?.statusCounts?.cancelled ? meta2?.statusCounts?.cancelled : '0'}
                            title = { "cancelled orders" }
                            icon = { "remove_circle" }
                        />
                    </Col>
                    <Col xl={3}>
                        <FloatCard 
                            variant = { "lg yellow" }
                            digit = {meta2?.statusCounts?.returned ? meta2?.statusCounts?.returned : "0"}
                            title = { "returned orders" }
                            icon = { "add_circle" }
                        />
                    </Col>
                <Col xl={12}>
                    <CardLayout>
                        <CardHeader 
                            title="Order Information" 
                            // dotsMenu={ data?.dotsMenu } 
                        />
                        <Row xs={1} sm={5} className="mb-4">
                          
                                <Col >
                                    <LabelField 
                                        type = { "text" }
                                        label = { "Search" }
                                        value={values.searchDummyText}
                    name="search"
                    onChange={searchHandle}
                                        placeholder = { "Search by OrderId"}
                                        labelDir = "label-col"
                                        fieldSize = "w-100 h-md"
                                    /> 
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                  {values.searchDummyText !== null && (values.searchDummyText !== "")  && (
                    <div className="mt-2">
                      <Button
                        className="mc-btn primary mt-4"
                        onClick={handleClear}
                        text="Clear"
                      />
                    </div>
                  )}
                </Col>
                                <Col>
                                <LabelField
                                 type='select1'
                                
                                        label = { "status by" }
                                        onChange={(e) => handleSelectStatus(e)}
                      value={orderStatus}
                      //                   onChange={(e) => handleSelectStatus(e)}
                      // value={selectedStatus}
                                        options = {[
                                            { value: "processing", label: "processing" },
                                            { value: "shipped", label: "shipped" },
                                            { value: "delivered", label: "delivered" },
                                            { value: "cancelled", label: "cancelled" },
                                            { value: "returned", label: "returned" },
                                            { value: "confirmed", label: "confirmed" }]}
                                        
                                        labelDir = "label-col"
                                        fieldSize = "w-100 h-md"
                                    /> 
                                </Col>
                                <Col >
                  {orderStatus !== "" && (
                    <div className="mt-2">
                      <Button
                        className="mc-btn primary mt-4"
                        onClick={clearMenuSelection}
                        text="Clear"
                      />
                    </div>
                  )}
                </Col>
                        </Row>
                        {/* <OrdersTable  
                            thead={ data?.table.thead } 
                            tbody={ data?.table.tbody } 
                        />
                        <Pagination /> */}

                        <CardLayout>
              <Row>
                {/* <Col xs={12} sm={6} md={4} lg={4}>
                  <LabelField
                    type={"text"}
                    label={"Coupon Name"}
                    placeholder={"Search by Coupon Name,Coupon Code"}
                    value={values.searchDummyText}
                    name="search"
                    onChange={searchHandle}
                    labelDir="label-col"
                    fieldSize="w-100 h-md"
                  />
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                  {values.searchDummyText !== "" && (
                    <div style={{ transform: "translateY(-15px)" }}>
                      <Button
                        className="mc-btn primary mt-5"
                        onClick={handleClear}
                        text="Clear"
                      />
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                  <div
                   className="d-flex justify-content-end align-items-center"
                  >
                    <Link to={"/create-coupon"}>
                      <Button
                        className="mc-btn green "
                        text="Create Coupon"
                        icon="discount"
                      />
                    </Link>
                  </div>
                </Col> */}
                <Col xl={12}>
                  {orders?.length ? (
                    <>
                      {" "}
                      <OrdersTable
                        memoizedTableConfig={memoizedTableConfig}
                        memoizedHandleClick={memoizedHandleClick}
                      
                        
                        products={orders}
                      />
                      <ListPagination
                        products={orders}
                        totalCount={meta?.totalCount}
                        pageCount={count}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </>
                  ) : (
                    <div
                      className="no-record"
                      style={{
                        fontSize: "26px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "200px",
                        margin: "0 auto",
                      }}
                    >
                      No Result Found
                    </div>
                  )}
                  {/* <Pagination products={meta?.totalCount}/> */}
                </Col>
              </Row>
            </CardLayout>



                        
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    )
}