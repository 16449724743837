import constant from "../../utils/constants";
import {
  ADMIN_NEWSLETTER_API_END,
  ADMIN_NEWSLETTER_API_REQUEST,
  GET_NEWSLETTER_SUBSCRIBER_FAILURE,
  GET_NEWSLETTER_SUBSCRIBER_SUCCESS,
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminNewsletter: { subscriber: [], meta: "" },
};

const adminNewsletter = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case ADMIN_NEWSLETTER_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case ADMIN_NEWSLETTER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
  
    case GET_NEWSLETTER_SUBSCRIBER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminNewsletter: { subscriber: [], meta: "" },
      };
    case GET_NEWSLETTER_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        adminNewsletter: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          subscriber: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    default:
      return state;
  }
};

export { adminNewsletter };
