import { API_URL } from "../utils/apiPaths";
import { request } from "./api";


export function addBlog(params,token) {
  return request('POST', API_URL.CREATE_BLOG, params,token);
}

export function getAdminBlogs(params,token) {
  return request('GET', API_URL.GET_BLOGS, params,token);
}

export function disableAdminBlogs(params,token) {
  return request('PUT', `${API_URL.BLOG_STATUS}/${params}`, params,token);
}

export function updateAdminBlogs(params, formData,token) {
  return request('PATCH', API_URL.UPDATE_BLOG.replace(':id', params), formData,token);
}

export function getAdminBlogDetail(params,token) {
  return request('GET', `${API_URL.GET_BLOGS_DETAIL}/${params}`,"",token);
}

export function getBlogCategoriesList(params,token) {
  return request('GET', API_URL.GET_BLOGS_CATEGORIES, params,token);
}


export const adminEventServices = {
  addBlog,
  getAdminBlogs,
  disableAdminBlogs,
  updateAdminBlogs,
  getAdminBlogDetail,
  getBlogCategoriesList
};
