import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  Anchor,
  Box,
  Item,
  Text,
  Icon,
  List,
  Image,
  Heading,
  Button,
} from "../../components/elements";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import CardLayout from "../../components/cards/CardLayout";
import data from "../../data/master/productView.json";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminProductDetailAction,
  getAdminVariantProductsAction,
} from "../../store/actions/adminProduct";
import moment from "moment";
import { useQuery } from "../../hooks/useQuery";
import { LabelTextarea } from "../../components/fields";
import { CustomerReview, RatingAnalytics } from "../../components/review";
import Carousel from "react-multi-carousel";
import ActionAreaCard from "../../components/cards/VariantsCard";
import { Link } from "react-router-dom";

export default function ProductView() {
  const dispatch = useDispatch();

  const query = useQuery();
  const { productDetails, adminVariantProducts } = useSelector((state) => ({
    productDetails: state.adminProduct.adminProductDetail,
    adminVariantProducts:
      state.adminProduct?.adminVariantProducts?.variantProducts,
  }));

  useEffect(() => {
    if (query.get("_id")) {
      dispatch(getAdminProductDetailAction(query.get("_id"))).then((res) => {
        dispatch(getAdminVariantProductsAction(res?.data?.RelationshipId));
      });
    }
  }, [query.get("_id")]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
const filterVariants = adminVariantProducts?.data.filter(product => product._id !== query.get("_id"));

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={"Product View"}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
      <CardLayout className="p-lg-5">
        <Row>
          <Col xl={5}>
            <DivideTitle title="product gallery" className="mb-4" />
            <Box className="mc-product-view-gallery">
              {productDetails?.allImages.map((item, index) => (
                <Image key={index} src={item} />
              ))}
            </Box>
          </Col>
          <Col xl={7}>
            <DivideTitle title="product details" className="mb-4" />
            <Heading as="h2" className="mc-product-view-info-title">
              {productDetails && productDetails["productName"]}
            </Heading>
            <Box className="mc-product-view-info-group">
              {/* <Box  className="mc-product-view-meta">
                                   <Icon type={"store"} />
                                    <Heading as="h5">{ "brand" }</Heading>
                                    <Text as="span">:</Text>
                                    { productDetails && <Text as="p">{ productDetails['marketedBY'] }</Text> }
                 </Box> */}
              <Box className="mc-product-view-meta">
                <Icon type={"pix"} />
                <Heading as="h5">{"category"}</Heading>
                <Text as="span">:</Text>
                {productDetails && (
                  <Text as="p">{productDetails["Category"]}</Text>
                )}
              </Box>
              <Box className="mc-product-view-meta">
                <Icon type={"settings"} />
                <Heading as="h5">{"sku's"}</Heading>
                <Text as="span">:</Text>
                {productDetails && <Text as="p">{productDetails["sKUs"]}</Text>}
              </Box>
              {/* <Box  className="mc-product-view-meta">
                                    <Icon type={"factory"} />
                                    <Heading as="h5">{ "manufact.By" }</Heading>
                                    <Text as="span">:</Text>
                                    { productDetails && <Text as="p">{ productDetails['manufacturedBy'] }</Text> }
                                    
                                </Box> */}
              <Box className="mc-product-view-meta">
                <Icon type={"summarize"} />
                <Heading as="h5">{"size variant"}</Heading>
                <Text as="span">:</Text>

                {productDetails && (
                  <List>
                      <Item>{productDetails?.SizeVariant}</Item>
                  </List>
                )}
              </Box>
              <Box className="mc-product-view-meta">
                <Icon type={"summarize"} />
                <Heading as="h5">{"flavour variant"}</Heading>
                <Text as="span">:</Text>

                {productDetails && (
                  <List>
                      <Item>{productDetails?.FlavourVariant}</Item>
                  </List>
                )}
              </Box>
              <Box className="mc-product-view-meta">
                <Icon type={"sell"} />
                <Heading as="h5">{"price"}</Heading>
                <Text as="span">:</Text>
                {productDetails && (
                  <Text as="p">
                    {productDetails["discountedPrice"]}{" "}
                    <del>{productDetails["MRP"]}</del>
                  </Text>
                )}
              </Box>
              <Box className="mc-product-view-meta">
                <Icon type={"shopping_cart"} />
                <Heading as="h5">{"stock"}</Heading>
                <Text as="span">:</Text>
                {productDetails && (
                  <Text as="p">{productDetails["stock"]}</Text>
                )}
              </Box>
              {/* <Box  className="mc-product-view-meta">
                                    <Icon type={"hotel_class"} />
                                    <Heading as="h5">{ "review" }</Heading>
                                    <Text as="span">:</Text>
                                    { ele.list &&
                                        <List>
                                            {ele.list.map((item, index) => (
                                                <Item key={ index }>{ item }</Item>
                                            ))}
                                        </List>
                                    }</Box>
                                    <Box  className="mc-product-view-meta">
                                    <Icon type={"flag"} />
                                    <Heading as="h5">{ "country of origin" }</Heading>
                                    <Text as="span">:</Text>
                                    { productDetails && <Text as="p">{ productDetails['countryOfOrigin'] }</Text> }
                                    { ele.list &&
                                        <List>
                                            {ele.list.map((item, index) => (
                                                <Item key={ index }>{ item }</Item>
                                            ))}
                                        </List>
                                    }
                                </Box> */}
              <Box className="mc-product-view-meta">
                <Icon type={"verified"} />
                <Heading as="h5">{"published"}</Heading>
                <Text as="span">:</Text>
                <Text as="p">
                  {moment(productDetails?.createdAt).format("LL")}
                </Text>
              </Box>
            </Box>
          </Col>
          {/* <Col xl={12}>
            <DivideTitle title="product description" className="mt-5 mb-4" />
            <Box className="mc-product-view-descrip">
              <Text>
                {productDetails && productDetails["ShortDescription"]}
              </Text>
            </Box>
          </Col> */}
          {/* <Col xl={12}>
                        <DivideTitle title="rating analytics" className="mt-5 mb-4" />
                        <RatingAnalytics
                            graphLine = { data?.rating.item }
                            graphScore = { data?.rating.score }
                            graphStar = { data?.rating.icon }
                            grapTitle = { data?.rating.total }
                            graphText = { data?.rating.text }
                        />
                    </Col>
                    <Col xl={12}>
                        <DivideTitle title="customer reviews" className="mt-5 mb-4" />
                        <CustomerReview data={ data?.review }  />
                    </Col> */}
          {/* <Col xl={12}>
                        <DivideTitle title="review reply form" className="mt-3 mb-4" />
                        <LabelTextarea placeholder="Write here..." fieldSize="w-100 h-text-xl" />
                        <Button className="mc-btn mc-review-form-btn primary">drop your replies</Button>
                    </Col> */}
          <Col xl={12}>
            <DivideTitle title="product variants" className="mt-5 mb-4" />
            <div style={{marginLeft: '90%', transform: 'translateY(-60px)'}}> <Link to={{ pathname: `/variants-upload` }} state={{ productId: query.get("_id") }}>
                      <Button
                    className="mc-btn sm green mt-5"
                    text="Add Variants"
                    icon="cloud_upload"
                  /></Link></div>
            {filterVariants?.length > 0 ? (
              <Carousel
                swipeable={false}
                draggable={false}
                // showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                // infinite={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {filterVariants  &&
                  filterVariants.map((el) => {
                    return (
                      <Link to={{ pathname: `/product-view`, search:`?_id=${el?._id}` }} onclick={window.scroll(0,0)}>
                        <ActionAreaCard
                          image={el.ImagesUrls[0]}
                          flavours={el.FlavourVariant}
                          name={el.Name}
                          size={el.SizeVariant}
                        />
                      </Link>
                    );
                  })}
              </Carousel>
            ) : ( <Col style={{display : 'flex', justifyContent: "center" , marginBottom:"20px" , fontWeight: "bold",fontSize:"20px"}}>
              No Variants Found</Col>
            )}
          </Col>
        </Row>
      </CardLayout>
    </PageLayout>
  );
}
