export const couponConstant = {
    COUPON_API_TYPES: {
      CREATE_COUPON: 'CREATE_COUPON',
      GET_ADMIN_COUPONS: 'GET_ADMIN_COUPONS',
      DISABLE_ADMIN_COUPONS: 'DISABLE_ADMIN_COUPONS',
      UPDATE_ADMIN_COUPONS: 'UPDATE_ADMIN_COUPONS',
      GET_ADMIN_COUPON_DETAIL: 'GET_ADMIN_COUPON_DETAIL',
    }
  }

  export const productCategory = [
    { value: "Body Building", label: "Body Building" },
    { value: "Health Care", label: "Health Care" },
    { value: "Immuno Supplement", label: "Immuno Supplement" },
    { value: "Skin Care", label: "Skin Care" },
  ];
  
  export const discountTyoe = [
    { value: "percentage", label: "percentage" },
    { value: "fixed", label: "fixed" },
    { value: "freeShipping", label: "freeShipping" },
    { value: "BOGO", label: "BOGO" },
  ];
  