import constant from "../../../utils/constants";
import {CREATE_HERO_FAILURE, CREATE_HERO_SUCCESS, HERO_SECTION_API_REQUEST, GET_HERO_PRODUCT_LIST_SUCCESS, GET_HERO_PRODUCT_LIST_FAILURE, HERO_API_END, GET_HERO_DETAIL_SUCCESS, GET_HERO_DETAIL_FAILURE, UPDATE_HERO_SUCCESS, UPDATE_HERO_FAILURE } from "../../actionTypes";


const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  heroProducts: { heros: [], meta: "" },
};

const heroSection = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case HERO_SECTION_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case HERO_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case CREATE_HERO_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case CREATE_HERO_FAILURE:
    case GET_HERO_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        heroProducts: { heros: [], meta: "" },
      };
    case GET_HERO_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        heroProducts: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          hero: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_HERO_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        heroProductsDetail: action.payload,
        loading: false,
      };
    case GET_HERO_DETAIL_FAILURE:
      case UPDATE_HERO_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
        };
    case UPDATE_HERO_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };

    default:
      return state;
  }
};

export { heroSection };
