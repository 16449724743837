import React, { useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Button, Item } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import UsersTable from "../../components/tables/UsersTable";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/userList.json";
import { useDispatch, useSelector } from "react-redux";
import useAddQuery from "../../hooks/useAddQuery";
import useForm from "../../hooks/useForm";
import { getAdminUsersAction } from "../../store/actions/adminUsers";
import { useCallback } from "react";
import { debounce } from 'lodash';
import { useSetUrlParams } from "../../hooks/useSetUrlParams";
import { adminProductTableConfigs, adminUsersTableConfigs } from "../../utils/tableConstants";
import ListPagination from "../../components/ListPagination";

export default function UserList() {
    let [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { addQuery } = useAddQuery();
    const paramsQuery = new URLSearchParams(window.location.search);
    const { users, loading, meta } = useSelector((state) => ({
        users: state.adminUsers.adminUsers?.users,
        loading: state.loader.loading,
        status: state.adminUsers.status,
        apiType: state.adminUsers.apiType,
        meta : state.adminUsers.adminUsers?.meta
    }));

    function getUrlParameter(key, col) {
        let paramsQuerys;
        if (key === 'search' || key === 'sortColumn' || key === 'sortOrder') {
            paramsQuerys = paramsQuery.get(key) === '' ? col : paramsQuery.get(key);
        }
        // if( key === 'category' || key === 'skus' || key === 'status' ) {
        //   paramsQuerys = paramsQuery.get( key ) === '' ? [] : JSON.parse( paramsQuery.get( key ) );
        // }
        return paramsQuerys;
    }
    const count = Math.ceil(meta?.totalCount / 10);
    const handlePageChange = (e, p) => {
      setPage(p);
      const requestParams = Object.assign({
        page: p,
      });
      dispatch(getAdminUsersAction(requestParams));
    };

    const { values, handleChange } = useForm(
        {
            searchDummyText: getUrlParameter('search', ''),
            search: getUrlParameter('search', ''),
            sortBy: getUrlParameter('sortColumn', ''),
            sortOrder: getUrlParameter('sortOrder', 'ASC'),
            page: 1,
            publishedOn: (paramsQuery.get('publishedOn') === null || paramsQuery.get('publishedOn') === '') ? false : paramsQuery.get('publishedOn'),
        },
        {},
    );


    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //   const getData = setTimeout(() => {
    //     dispatch(getAdminProductsAction());
    //   }, 3000);
    //   return () => clearTimeout(getData);
    //   // dispatch(getAdminProductsAction());
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    // const requestParams = Object.assign(
    // {
    // perPage: 10,
    // },
    // );
    // dispatch(getAdminProductsAction(requestParams));
    // },[dispatch]);

    useEffect(() => {
        const requestParams = Object.assign(
            {
                name: values.name,
                perPage: 10,
                page: values.page,
                publishedOn: values.publishedOn === false ? '' : values.publishedOn,
            },
            values.search && { search: values.search },
            values.sortBy && { sortBy: values.sortBy },
        );
        dispatch(getAdminUsersAction(requestParams));
    }, [
        values.sortOrder,
        values.name,
        values.page,
        values.sortBy,
        values.search,
        values.publishedOn
    ]);

    //   const count = Math.ceil(meta?.totalCount / 10);
    //   const handlePageChange = (e, p) => {
    //     setPage(p);
    //     const requestParams = Object.assign({
    //       page: p,
    //     });
    //     dispatch(getAdminProductsAction(requestParams));
    //   };
    const debouncedSave = useCallback(
        debounce(nextValue => handleChange('search', nextValue), 1000),
        [],
    );

    const searchHandle = (e) => {
        const { value: nextValue } = e.target;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        addQuery(useSetUrlParams("search", nextValue));
        handleChange('searchDummyText', nextValue);
        debouncedSave(nextValue);
        handleChange('page', 1);
    };

    const handleSorting = (sortBy, sortingType) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        addQuery(useSetUrlParams("sortColumn", sortBy));
        // eslint-disable-next-line react-hooks/rules-of-hooks
        addQuery(useSetUrlParams("sortOrder", sortingType));
        handleChange('name', sortingType);
        handleChange('sortOrder', sortingType);
        handleChange('page', 1);
    };
    const memoizedTableConfig = useMemo(() => adminUsersTableConfigs, []);
    const memoizedHandleClick = useCallback(
        (sortBy, orderType) => handleSorting(sortBy, orderType),
        [],
    );

    const  handleClear = () => {
        handleChange( 'searchDummyText', '' );
         // eslint-disable-next-line react-hooks/rules-of-hooks
        addQuery( useSetUrlParams( "search", '' ) );
        debouncedSave( '' );
        }
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={data?.pageTitle}>
                            {/* {data?.breadcrumb.map((item, index) => (
                                <Item key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </Item>
                            ))} */}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                {/* {data?.float.map((item, index) => (
                    <Col xl={4} key={ index }>
                        <FloatCard 
                            variant={ item.variant }
                            digit={ item.digit }
                            title={ item.title }
                            icon={ item.icon }
                        />
                    </Col>
                ))} */}
                <Col xl={3}>
                        <FloatCard
                            variant = { "lg blue" }
                            digit = {meta?.totalCount ? meta?.totalCount : "0"}
                            title = { "total users" }
                            icon = { "bookmark" }
                        />
                    </Col>
                <Col xl={12}>
                    <CardLayout>
                        <CardHeader title={data?.cardTitle} />
                        {/* <Row xs={1} sm={4} className="mb-4">
                            {data?.filter.map((item, index)=> (
                                <Col key={index}>
                                    <LabelField 
                                        type = { item.type }
                                        label = { item.label }
                                        option = { item.option }
                                        placeholder = { item.placeholder }
                                        labelDir = "label-col"
                                        fieldSize = "w-100 h-sm"
                                    /> 
                                </Col>
                            ))}
                        </Row> */}
                         <Row>
                  <Col xs={12} sm={6} md={4} lg={4} className="mb-4" >
                    <LabelField
                      type={'text'}
                    //   label={'Search'}
                      placeholder={'Search by Email or Phone...'}
                      value={values.searchDummyText}
                      name='search'
                      onChange={searchHandle}
                      labelDir="label-col"
                      fieldSize="w-100 h-md"
                    /> 
                    
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={4}>
                  {values.searchDummyText !== null && (values.searchDummyText !== "") && (
                    <div className="mt-2">
                      <Button
                        className="mc-btn primary"
                        onClick={handleClear}
                        text="Clear"
                      />
                    </div>
                  )}
                </Col>
                  </Row>
                        {users?.length > 0 ? <> <UsersTable
                            memoizedTableConfig={memoizedTableConfig}
                            memoizedHandleClick={memoizedHandleClick}
                            thead={data?.product?.thead}
                            tbody={data?.product?.tbody}
                            users={users}
                        />
                            <ListPagination
                    products={users}
                    totalCount={meta?.totalCount}
                    pageCount={count}
                    page={page}
                    onChange={handlePageChange}
                  />
                        </> : <div className='no-record' style={{
                            fontSize: '26px',
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '200px',
                            margin: '0 auto'
                        }}>
                            No Result Found
                        </div>}
                        {/* <UsersTable 
                            thead = { data?.table.thead }
                            tbody = { data?.table.tbody }
                            users={users}
                        /> */}
                        {/* <Pagination /> */}
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    );
}