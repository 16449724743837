import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  Anchor,
  Button,
  Image,
  Input,
  Label,
  Icon,
  Text,
} from "../../../components/elements";
import { LabelField, LabelTextarea } from "../../../components/fields";
import { CardLayout, CardHeader } from "../../../components/cards";
import Breadcrumb from "../../../components/Breadcrumb";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/productUpload.json";
import { validationsSchema } from "../../../utils/formValidations";
import { useFormik } from "formik";
import {
  addProductAction,
  getAdminProductDetailAction,
  updateAdminProductAction,
} from "../../../store/actions/adminProduct";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { flavourOptionList, optionList, sizeOptionList } from "../../../utils/productConstants";
import { useQuery } from "../../../hooks/useQuery";
import { hideLoader, showLoader } from "../../../store/actions/loader";
import fetchAndConvertImages from "../../../helpers/convertUrlToImages";
import ImageInput from "../../../components/elements/ImageInput";
import PhoneInput from "react-phone-input-2";
import { createInfluencersAction, getInfluencerDetailAction, updateInfluencersDetail } from "../../../store/actions/CMS actions/Influencers";

const AddInfluencers = () => {
  const [uploadFile, setUploadFile] = React.useState("choose");
  const [previewImages, setPreviewImages] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedFlavourOptions, setSelectedFlavourOptions] = useState();
  const [selectedIdOptions, setSelectedIdOptions] = useState();
  const productId = useQuery();
  const [isEditEvent, setEditEventStatus] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  // const { products, loading, meta } = useSelector((state) => ({
  //   products: state.adminProduct.adminProducts?.products,
  //   loading: state.loader.loading,
  //   status: state.adminProduct.status,
  //   meta: state.adminProduct.adminProducts?.meta,
  //   apiType: state.adminProduct.apiType,
  // }));
  const {productDetails,adminParentProducts} = useSelector(
    (state) => ({productDetails : state.adminProduct.adminProductDetail,
      adminParentProducts : state.adminProduct?.adminParentProducts?.parentProducts})
  );

  const [influencerData, setInfluencerData] = useState({
    Name: "",
    Image: "",
    email: "",
    phone: "",
    instagram: "",
    facebook: [],
    youtube: "",
  });

  useEffect(() => {
    if (location?.state?.isInfluencerEdit) {
      dispatch(showLoader());
      dispatch(getInfluencerDetailAction(productId.get("_id"))).then(
        (res) => {
         
          // Call the function to convert image URLs into FileList object
          // fetchAndConvertImages(res?.data?.Image).then((fileList) => {
          //   formik.setFieldValue("Image", fileList);
          //   setImageFile(fileList);
          // });
          setEditEventStatus(true); 
          formik.setFieldValue("Name", res?.data?.Name);
          formik.setFieldValue("email", res?.data?.email);
          formik.setFieldValue("phone", res?.data?.phone);
          formik.setFieldValue("instagram", res?.data?.socialMedia?.instagram);
          formik.setFieldValue("facebook", res?.data?.socialMedia?.facebook);
          formik.setFieldValue("youtube", res?.data?.socialMedia?.youtube);
          formik.setFieldValue("Image", res?.data?.Image);
          setInfluencerData({...influencerData,phone: res?.data?.phone})
          setPreviewImages(res?.data?.Image);
        }
      );
      dispatch(hideLoader());
    }
  }, [location?.state?.isInfluencerEdit]);

 

  const handleUploadImage = (e, setFieldValue) => {
    const img = productDetails?.allImages.map((el) => el);
    if ([...e.target.files].length < 1) return;
    const newImageUrls = [];
    [...e.target.files].forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setPreviewImages(newImageUrls);
    setFieldValue("Image", e.target.files);
  };

  const handleChange = (e, setFieldValue) => {
    setFieldValue(e.target.name, e.target.value);
    
  };

  const handlePhoneInput = (e) => {
    setInfluencerData({...influencerData,phone:e})
  }

  const handleSelectSize = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("SizeVariant", data.value);
    setSelectedOptions(data);
  };

  const handleSelectFlavour = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("FlavourVariant", data.value);
    setSelectedFlavourOptions(data);
  };

  

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("Name", formik?.values?.Name);
    formData.append("email", formik?.values?.email);
    formData.append("phone", influencerData.phone);
    formData.append("instagram", formik?.values?.instagram);
    formData.append("facebook", formik?.values?.facebook);
    formData.append("youtube", formik?.values?.youtube);
    [...formik.values.Image].forEach((image) => {
      formData.append("Image", image);
    });
    isEditEvent
      ? dispatch(updateInfluencersDetail(productId.get("_id"), formData))
      : dispatch(createInfluencersAction(formData));
  };
  

  const formik = useFormik({
    initialValues: { influencerData },
    // validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });


  // const parentProductList = adminParentProducts &&  adminParentProducts.map((el) => { return {label: el.Name, value: el.Name ,id: el._id}})
  // const parentProductId = adminParentProducts && adminParentProducts.map((el) => { return {label: el._id, value: el._id}})
  // const handleSelectParentProductId = (data, setFieldValue) => {
  //   // const productSize = data.map((el) => {
  //   //   return el.value;
  //   // });
  //   setFieldValue("RelationshipId", data.id);
  //   setSelectedIdOptions(data);
  // };
  return (
    <>
      <PageLayout>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Row>
            <Col xl={12}>
              <CardLayout>
                <Breadcrumb title={'Add Influencer'}>
                  {/* {data?.breadcrumb.map((item, index) => (
                    <li key={index} className="mc-breadcrumb-item">
                      {item.path ? (
                        <Anchor className="mc-breadcrumb-link" href={item.path}>
                          {item.text}
                        </Anchor>
                      ) : (
                        item.text
                      )}
                    </li>
                  ))} */}
                </Breadcrumb>
              </CardLayout>
            </Col>
            <Col xl={7}>
              <CardLayout>
                {/* <CardHeader
                  title="basic information"
                  dotsMenu={data?.dotsMenu}
                /> */}
                <Row>
                  {/* <Col xl={12}>
<input {...formik.getFieldProps('Name')}></input>
</Col> */}
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Name"
                      name="Name"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.Name}
                    />
                  </Col>
                  {/* {formik.touched.Name && formik.errors.Name ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.Name}
                    </span>
                  ) : null} */}
                  {/* <Col xl={12}><LabelTextarea label="description" fieldSize="w-100 h-text-md" {...formik.getFieldProps('Name')} /></Col> */}
                  {/* <Col xl={6}><LabelField label="category" option={['mans', 'womans', 'accessory']} {...formik.getFieldProps('Name')} fieldSize="w-100 h-md" /></Col>
<Col xl={6}><LabelField label="brand" option={['richman', 'lubana', 'ecstasy']} {...formik.getFieldProps('Name')} fieldSize="w-100 h-md" /></Col> */}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="email"
                      label="Email"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.email}
                    />
                  </Col>
                  {/* {formik.touched.MRP && formik.errors.MRP ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.MRP}
                    </span>
                  ) : null} */}
                  <Col xl={6}>
                    <LabelField
                      type="phone"
                      label="Phone"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handlePhoneInput(e)}
                      value={influencerData.phone}
                    />
                  </Col>
                  {/* {formik.touched.DiscountedPrice &&
                    formik.errors.DiscountedPrice ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.DiscountedPrice}
                    </span>
                  ) : null} */}
                  {/* <Col xl={6}><LabelField type="text" label="shipping fee" fieldSize="w-100 h-md" {...formik.getFieldProps('Name')} /></Col>
<Col xl={6}><LabelField type="text" label="tax rate" fieldSize="w-100 h-md" {...formik.getFieldProps('Name')} /></Col> */}
                  {/* <Col xl={6}>
                    <LabelField
                      type="text"
                      name="ManufacturedBy"
                      label="Manufactured By"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.ManufacturedBy}
                    />
                  </Col>
                  {formik.touched.ManufacturedBy &&
                  formik.errors.ManufacturedBy ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.ManufacturedBy}
                    </span>
                  ) : null}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="MarketedBy"
                      label="Marketed By"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.MarketedBy}
                    />
                  </Col> 
                  {formik.touched.MarketedBy && formik.errors.MarketedBy ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.MarketedBy}
                    </span>
                  ) : null}*/}
                  {/* <Col xl={6}>
                    <LabelField
                      type="text"
                      name="CountryOfOrigin"
                      label="Country Of Origin"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.CountryOfOrigin}
                      fieldSize="w-100 h-md"
                    />
                  </Col> 
                  {formik.touched.CountryOfOrigin &&
                  formik.errors.CountryOfOrigin ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.CountryOfOrigin}
                    </span>
                  ) : null}*/}
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      name="instagram"
                      label="Instagram"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.instagram}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      name="facebook"
                      label="Facebook"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.facebook}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      name="youtube"
                      label="Youtube"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.youtube}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  {/* {formik.touched.Category && formik.errors.Category ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.Category}
                    </span>
                  ) : null} */}
                    {/* <Col xl={12}>
                    <Box className="mc-product-upload-organize">
                      <LabelField
                        type="text"
                        label="add size"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                    <LabelField
                      type="select"
                      name="RelationshipId"
                      label="Parent Product"
                      options={parentProductList}
                      onChange={(e) => handleSelectParentProductId(e, formik.setFieldValue)}
                      value={selectedIdOptions}
                      fieldSize="w-100 h-md"
                    />
                  </Col> */}
                </Row>
              </CardLayout>
            </Col>
            <Col xl={5}>
              {/* <CardLayout className="mb-4">
                <CardHeader title="organization" dotsMenu={data?.dotsMenu} />
                <Row>
                  <Col xl={12}>
                    <Box className="mc-product-upload-organize mb-4">
                      <LabelField
                        type="text"
                        label="add category"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                    <Box className="mc-product-upload-organize mb-4">
                      <LabelField
                        type="text"
                        label="add brand"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                    <Box className="mc-product-upload-organize mb-4">
                      <LabelField
                        type="text"
                        label="add color"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                    <Box className="mc-product-upload-organize">
                      <LabelField
                        type="text"
                        label="add size"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                  </Col>
                </Row>
              </CardLayout> */}
              {/* <CardLayout>
                <CardHeader title="specification" dotsMenu={data?.dotsMenu} />
                <Row>
                  <Col xl={6}>
                    <LabelField
                      label="size"
                      option={["sm", "md", "lg", "xl", "xxl"]}
                      fieldSize="w-100 h-multiple"
                      multiple
                    />
                  </Col>
                  <Col xl={6}>
                    <LabelField
                      label="color"
                      option={["red", "green", "blue", "pink", "black"]}
                      fieldSize="w-100 h-multiple"
                      multiple
                    />
                  </Col>
                   <Col xl={6}><LabelField type="text" label="stock" fieldSize="w-100 h-md" /></Col>
<Col xl={6}><LabelField type="text" label="weight" fieldSize="w-100 h-md" /></Col> 
                </Row>
              </CardLayout> */}
            </Col>
            <Col xl={12}>
              <CardLayout>
                <CardHeader title="Image" />
                <Box className="mc-product-upload-media">
                        <Box className="mc-product-upload-image">
                          <Image
                            src={previewImages}
                            onChange={handleChange}
                          />
                        </Box>
                  <Box className="mc-product-upload-file">
                    <ImageInput
                      type="file"
                      name="Image"
                      multiple={false}
                      accept="image/*"
                      id="product"
                      onChange={(e) =>
                        handleUploadImage(e, formik.setFieldValue)
                      }
                    />
                    <Label htmlFor="product">
                      <Icon type="collections" />
                      <Text>{uploadFile}</Text>
                    </Label>
                  </Box>
                </Box>
                {isEditEvent ? (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Update"
                    icon="cloud_upload"
                  />
                ) : (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Add Influencer"
                    icon="cloud_upload"
                  />
                )}
              </CardLayout>
            </Col>
          </Row>
        </form>
      </PageLayout>
    </>
  );
};

export default AddInfluencers;
