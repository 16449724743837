import React, { useCallback, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout, FloatCard } from "../../components/cards";
import ProductsTable from "../../components/tables/ProductsTable";
import LabelField from "../../components/fields/LabelField";
import { Breadcrumb } from "../../components";
import Anchor from "../../components/elements/Anchor";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/productList.json";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAdminProductsAction, getDropdownCategories } from "../../store/actions/adminProduct";
import { LoaderProvider } from "../../context/Preloader";
import ListPagination from "../../components/ListPagination";
import useForm from "../../hooks/useForm";
import { debounce } from 'lodash';
import { concatStrings } from "../../helpers/convertUrlToImages";
import { useSetUrlParams } from "../../hooks/useSetUrlParams";
import useAddQuery from "../../hooks/useAddQuery";
import { Box, Button, Input } from "../../components/elements";
import { Height } from "@mui/icons-material";
import { adminProductTableConfigs } from "../../utils/tableConstants";
import { Link } from "react-router-dom";
import ProductFloatCard from "../../components/cards/ProductFloatCard";

export default function ProductList() {
  let [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const paramsQuery = new URLSearchParams( window.location.search );
  const { categories,products, loading, meta } = useSelector((state) => ({
    products: state.adminProduct.adminProducts?.products,
    categories: state.adminProduct.adminCategories?.dropdownCategories,
    loading: state.loader.loading,
    status: state.adminProduct.status,
    meta: state.adminProduct.adminProducts?.meta,
    apiType: state.adminProduct.apiType,
  }));

  function getUrlParameter( key, col ) {
    let paramsQuerys;
    if( key === 'search' || key === 'sortColumn' || key === 'sortOrder' ) {
      paramsQuerys = paramsQuery.get( key ) === '' ? col : paramsQuery.get( key );
    }
    // if( key === 'category' || key === 'skus' || key === 'status' ) {
    //   paramsQuerys = paramsQuery.get( key ) === '' ? [] : JSON.parse( paramsQuery.get( key ) );
    // }
    return paramsQuerys;
  }

  const { values, handleChange } = useForm(
    {
      searchDummyText: getUrlParameter( 'search', '' ),
      search: getUrlParameter( 'search', '' ),
      sortBy: getUrlParameter( 'sortColumn', '' ),
      sortOrder: getUrlParameter( 'sortOrder', 'ASC' ),
      page: 1,
      publishedOn: ( paramsQuery.get( 'publishedOn' ) === null || paramsQuery.get( 'publishedOn' ) === '' ) ? false : paramsQuery.get( 'publishedOn' ),
    },
    {},
  );


  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     dispatch(getAdminProductsAction());
  //   }, 3000);
  //   return () => clearTimeout(getData);
  //   // dispatch(getAdminProductsAction());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  // const requestParams = Object.assign(
  // {
  // perPage: 10,
  // },
  // );
  // dispatch(getAdminProductsAction(requestParams));
  // },[dispatch]);

  useEffect(() => {
    const requestParams = Object.assign(
      {
        name: values.name,
        perPage: 10,
        page: values.page,
        publishedOn: values.publishedOn === false ? '' : values.publishedOn,
      },
      values.search &&  { search: values.search },
      values.sortBy && { sortBy: values.sortBy },
    );
    dispatch(getAdminProductsAction(requestParams));
    dispatch(getDropdownCategories())
  }, [
    values.sortOrder,
    values.name,
    values.page,
    values.sortBy,
    values.search,
    values.publishedOn
  ]);

  const count = Math.ceil(meta?.totalCount / 10);
  const handlePageChange = (e, p) => {
    setPage(p);
    const requestParams = Object.assign({
      page: p,
    });
    dispatch(getAdminProductsAction(requestParams));
  };
  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( 'search', nextValue ), 1000 ),
    [],
  );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    addQuery( useSetUrlParams( "search", nextValue ) );
    handleChange( 'searchDummyText', nextValue );
    debouncedSave( nextValue );
    handleChange( 'page', 1 );
  };

  const handleSorting = ( sortBy, sortingType ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    addQuery( useSetUrlParams( "sortColumn", sortBy ) );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
    handleChange( 'name', sortingType );
    handleChange( 'sortOrder', sortingType );
    handleChange( 'page', 1 );
  };
  const memoizedTableConfig = useMemo( () => adminProductTableConfigs, [] );
  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    [],
  );

 const  handleClear = () => {
  handleChange( 'searchDummyText', '' );
   // eslint-disable-next-line react-hooks/rules-of-hooks
  addQuery( useSetUrlParams( "search", '' ) );
  debouncedSave( '' );
  }
  return (
    <PageLayout>
      {loading ? (
        <LoaderProvider />
      ) : (
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={data?.pageTitle}>
                {data?.breadcrumb.map((item, index) => (
                  <li key={index} className="mc-breadcrumb-item">
                    {item.path ? (
                      <Anchor className="mc-breadcrumb-link" href={item.path}>
                        {item.text}
                      </Anchor>
                    ) : (
                      item.text
                    )}
                  </li>
                ))}
              </Breadcrumb>
            </CardLayout>
          </Col>
          {data?.float.map((item, index) => (
            <Col key={index} sm={6} lg={4}>
              <ProductFloatCard
                variant={item.variant}
                productCount={meta?.totalCount}
                categoryCount={categories?.length}
                brandsCount={meta?.totalCount}
                title={item.title}
                icon={item.icon}
              />
            </Col>
          ))}
          <Col xl={12}>
            <CardLayout>
              <Row>
                  <Col xs={12} sm={6} md={4} lg={4}>
                   
                    <LabelField
                      type={'text'}
                      label={''}
                      placeholder={'Search By Product Name & SKUs...'}
                      value={values.searchDummyText}
                      name='search'
                      onChange={searchHandle}
                      labelDir="label-col"
                      fieldSize="w-100 h-md"
                    /> 
                    
                  </Col>
                 <Col xs={12} sm={6} md={4} lg={4}>
                       {values.searchDummyText !== null && (values.searchDummyText !== "") && <div className="mt-2">
                      <Button
                    className="mc-btn primary"
                    onClick={handleClear}
                    text="Clear"
                  /></div>}</Col>
                  <Col xs={12} sm={6} md={4} lg={4}>
                      <div className="d-flex justify-content-end align-items-center" >
                      <Link to={'/product-upload'}>
                      <Button
                    className="mc-btn primary "
                    text="Add Product"
                    icon="cloud_upload"
                  /></Link></div></Col>
                <Col xl={12}>
               {products ?  <> <ProductsTable
               memoizedTableConfig={memoizedTableConfig}
               memoizedHandleClick={memoizedHandleClick}
                    thead={data?.product.thead}
                    tbody={data?.product.tbody}
                    products={products}
                  />
                  <ListPagination
                    products={products}
                    totalCount={meta?.totalCount}
                    pageCount={count}
                    page={page}
                    onChange={handlePageChange}
                  /> </> : <div className='no-record' style={{fontSize: '26px',
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '200px',
                    margin: '0 auto'}}>
                  No Result Found
                </div>}
                  {/* <Pagination products={meta?.totalCount}/> */}
                </Col>
              </Row>
            </CardLayout>
          </Col>
        </Row>
      )}
    </PageLayout>
  );
}
