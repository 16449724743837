import React from "react";
import { Box, Label } from "../elements";
import { Editor } from "@tinymce/tinymce-react";

export default function LabelTinyEditor({ style,label,name ,onChange,value,labelDir, fieldSize,onEditorChange, placeholder, ...rest }) {
    return (
        <Box className={`mc-label-field-group ${ label ? labelDir || "label-col" : "" }`}>
            {label && <Label className="mc-label-field-title">{ label }</Label>}
            <Editor
            name={name}
            style={style}
             className={`mc-label-field-textarea ${ fieldSize || "w-md h-text-md" }`} 
             apiKey="z8jpbvqjui7jqellf5n7wnpgmw5dz4lmsaiuiipr534prhyo"
                    value={value}
                    onEditorChange={onEditorChange}
                    init={{
                      selector: "textarea",
                      file_picker_types: "file image media",
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                        "powerpaste",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help  | link image code",
                      powerpaste_allow_local_images: true,
                      powerpaste_word_import: "prompt",
                      powerpaste_html_import: "prompt",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
        </Box>
    )
}