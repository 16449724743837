import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Td } from "../elements/Table";
import {
  Box,
  Text,
} from "../elements";
import { useDispatch } from "react-redux";
import TableHeaderRow from "../shared/TableHeaderRow";
import moment from "moment";

export default function NewsletterTable({
  thead,
  tbody,
  products,
  memoizedHandleClick,
  memoizedTableConfig,
}) {
  const [data, setData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(tbody);
    setProductsData(products);
  }, [tbody, products]);

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            <TableHeaderRow
              configData={memoizedTableConfig}
              onDesOrder={memoizedHandleClick}
              onAscOrder={memoizedHandleClick}
            />
          </Tr>
        </Thead>
       
        <Tbody className="mc-table-body even">
          {productsData?.map((item, index) => (
            <Tr key={index}>
              {/* <Td title={index + 1}>
                <Box className="mc-table-check">
                  <Box className="mc-table-product md">
                  <Box className="mc-table-group">
                  {index + 1}
                    <Text>{""}</Text>
                  </Box>
                  </Box>
                </Box>
              </Td> */}
              <Td>
                <Box className="mc-table-product md">
                  <Box className="mc-table-group">
                    {item?.email}
                    <Text>{""}</Text>
                  </Box>
                </Box>
              </Td>
              <Td>{moment(item?.createdAt).utc().format("ll")}</Td>
              {/* <Td>-</Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
