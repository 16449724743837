import { toastr } from "react-redux-toastr";
import constant from "../../utils/constants";
import {  GET_SINGLE_STORES_LIST_FAILURE, GET_SINGLE_STORES_LIST_SUCCESS, START_LOADER, STOP_LOADER } from "../actionTypes";
import { hideLoader } from "./loader";
import history from "../../utils/history";
import { ADD_STORE_FAILURE, ADD_STORE_SUCCESS, CHANGE_STORE_ACTIVE_STATUS_FAILURE, CHANGE_STORE_ACTIVE_STATUS_SUCCESS, GET_STORES_LIST_BY_ZIPCODE_FAILURE, GET_STORES_LIST_BY_ZIPCODE_SUCCESS, GET_STORES_LIST_FAILURE, GET_STORES_LIST_SUCCESS, STORE_LOCATOR_API_END, STORE_LOCATOR_API_REQUEST, UPDATE_STORE_FAILURE, UPDATE_STORE_SUCCESS } from "../actionTypes";
import { storeConstants } from "../../utils/storeLocatorConstants";
import { addStore, changeAdminStoresLocatorStatus, getAdminSingleStoresLocator, getAdminStoresLocatorByZipcode, getAdminStoresLocatorList, updateAdminStoresLocator } from "../../services/adminStoreLocator";


const { STORES_API_TYPES } = storeConstants;
const { ERROR_MESSAGES } = constant;

//Add/Create Admin Store Locator
export const addStoreLocatorAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: STORE_LOCATOR_API_REQUEST,
      payload: STORES_API_TYPES.ADD_STORE,
    });
    const response = await addStore(param,token);
    toastr.success(response.message);
    dispatch({ type: ADD_STORE_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
    history.push("/stores-list");
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: ADD_STORE_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: STORE_LOCATOR_API_END,
    });
  }
};

//Get Stores Locator List
export const getStoresLocatorListAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: STORE_LOCATOR_API_REQUEST,
        payload: STORES_API_TYPES.GET_ADMIN_STORES_LIST,
      });
      const response = await getAdminStoresLocatorList(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_STORES_LIST_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_STORES_LIST_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: STORE_LOCATOR_API_END,
      });
    }
  };
};

//Get Single Stores Locator List
export const  getSingleStoresLocatorListAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: STORE_LOCATOR_API_REQUEST,
        payload: STORES_API_TYPES.GET_ADMIN__SINGLE_STORES_LIST,
      });
      const response = await getAdminSingleStoresLocator(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_SINGLE_STORES_LIST_SUCCESS,
        payload: { dataList, meta: dataList },
      });
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_SINGLE_STORES_LIST_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: STORE_LOCATOR_API_END,
      });
    }
  };
};


//Update Stores
export const updateStoresAction = (params,data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: STORE_LOCATOR_API_REQUEST,
      payload: STORES_API_TYPES.UPDATE_ADMIN_STORES_LOCATOR,
    });
    const response = await updateAdminStoresLocator(params,data,token);
    if (response) {
      toastr.success(response.message);
      dispatch({
        type: UPDATE_STORE_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: STOP_LOADER });
      history.push("/stores-list");
    }
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: UPDATE_STORE_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: STORE_LOCATOR_API_END,
    });
  }
};

//Change Store Active Status 
export const changeStoreActiveStatusAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: STORE_LOCATOR_API_REQUEST,
      payload: STORES_API_TYPES.CHANGE_STORES_ACTIVE_STATUS,
    });
    const response = await changeAdminStoresLocatorStatus(params,token);
   
      toastr.success(response.message)
    
    dispatch({
      type: CHANGE_STORE_ACTIVE_STATUS_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: CHANGE_STORE_ACTIVE_STATUS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: STORE_LOCATOR_API_END,
    });
  }
};

// //Change Store Active Status 
// export const changeStoreActiveStatusAction = (param) => async (dispatch) => {
//   try {
//     // dispatch( { type: START_LOADER } );
//     dispatch({
//       type: STORE_LOCATOR_API_REQUEST,
//       payload: STORES_API_TYPES.CHANGE_STORES_ACTIVE_STATUS,
//     });
//     const response = await changeAdminStoresLocatorStatus(param,token);
//     const newResponse = response.data;
//     dispatch({
//       type: CHANGE_STORE_ACTIVE_STATUS_SUCCESS,
//       payload: newResponse,
//     });
//     dispatch({ type: STOP_LOADER });
//   } catch (error) {
//     dispatch({ type: STOP_LOADER });
//     dispatch({
//       type: CHANGE_STORE_ACTIVE_STATUS_FAILURE,
//       payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
//     });
//     toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
//   } finally {
//     dispatch({
//       type: STORE_LOCATOR_API_END,
//     });
//   }
// };

//Get Stores By Zipcode
export const getStoresByZipcodeAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    // dispatch( { type: START_LOADER } );
    dispatch({
      type: STORE_LOCATOR_API_REQUEST,
      payload: STORES_API_TYPES.GET_STORES_SEARCH_BY_ZIPCODE,
    });
    const response = await getAdminStoresLocatorByZipcode(param,token);
    const newResponse = response.data;
    dispatch({
      type: GET_STORES_LIST_BY_ZIPCODE_SUCCESS,
      payload: newResponse,
    });
    dispatch({ type: STOP_LOADER });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: GET_STORES_LIST_BY_ZIPCODE_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: STORE_LOCATOR_API_END,
    });
  }
};

