import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text, List, Item, Image, Anchor, Heading, Button } from "../../components/elements";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../components/elements/Table";
import CardLayout from "../../components/cards/CardLayout";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/invoiceDetails.json";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../hooks/useQuery";
import { getOrdersDetailAction, getReturnOrdersDetailsAction, getReturnreturnDetailsAction } from "../../store/actions/adminOrders";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import ModalImage from "react-modal-image";

export default function ReturnOrderDetails() {
    const dispatch = useDispatch();

  const query = useQuery();
  const { returnDetails } = useSelector((state) => ({
    returnDetails: state.adminOrders.returnOrdersDetails.returnDetails
  }));

  useEffect(() => {
    if (query.get("_id")) {
      dispatch(getReturnOrdersDetailsAction(query.get("_id")))
    }
  }, [query.get("_id")]);
  const downloadPdfDocument = (rootElementId , downloadFileName) => {
    const input = document.getElementById(rootElementId);
    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'in',
                format: [20, 20],
            });
            pdf.addImage(imgData, 'JPEG', 0, 0);
            pdf.save(`${downloadFileName}.pdf`);
        })
}



  const totalMRPs = 
  returnDetails?.items?.reduce((acc, obj) => acc + obj?.product?.MRP * obj?.quantity, 0)
  
  const totalDiscounts = 
  returnDetails?.items?.reduce((acc, obj) => acc + obj?.product?.DiscountedPrice * obj?.quantity, 0)
  
// const totalDiscounts = userInfo
//   ? cartData &&
//     cartData.reduce(
//       (acc, obj) => acc + obj.product.DiscountedPrice * obj.quantity,
//       0
//     )
//   : cartData &&
//     cartData.reduce(
//       (acc, obj) => acc + obj.DiscountedPrice * obj.quantity,
//       0
//     );


  const discount = totalMRPs - totalDiscounts;
  const payableAmount = totalMRPs - (discount);
  const savedAmount = discount + (returnDetails?.coupon !==null ? returnDetails?.coupon?.discountValue : 0);
  const gstTaxes = (13 * payableAmount) / 100;
  const grandTotal = payableAmount + gstTaxes
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={ "ORDER RETURN DETAILS" }>
                            {data?.returnbreadcrumb.map((item, index) => (
                                <Item key={ index } className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                                </Item>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={12} id="testId">
                    <CardLayout className="p-md-5">
                        <Box className="mc-invoice-head">
                            <Image src={ data?.logo.src } alt={ data?.logo.alt } />
                            <Heading as="h2">Order Id : {returnDetails?.orderData?.OrderId}</Heading>
                        </Box>
                        {/* <Box className="mc-invoice-group">
                            <Box className="mc-invoice-recieved">
                                <Heading as="h6">{ "Shipment Details" }</Heading>
                                <Text>{ returnDetails?.shippingAddress?.recieverName}</Text>
                                <Text> {" "}{returnDetails?.shippingAddress?.address},{" "}{returnDetails?.shippingAddress?.city},
             {" "}{returnDetails?.shippingAddress?.state},{" "}{returnDetails?.shippingAddress?.zip}{" "}</Text>
             <Text>{returnDetails?.shippingAddress?.Contact}</Text>
             <Text>{returnDetails?.user?.email}</Text>
                            </Box>
                        </Box> */}
                        <Box className="mc-table-responsive">
                            <Table className="mc-table">
                                <Thead className="mc-table-head">
                                    <Tr>
                                        {data?.table.thead.map((item, index) => (
                                            <Th key={ index }>{ item }</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody className="mc-table-body">
                                    {returnDetails?.items?.map((item, index) => (
                                        <Tr key={ index }>
                                            
                                            <Td>
                                                <Box className="mc-table-product sm">
                                                    <Image src={ item?.product?.ImagesUrls[0] } />
                                                    <Text>{ item?.product?.Name }</Text>
                                                </Box>
                                            </Td>
                                            <Td>{ item?.product?.MRP }</Td>
                                            <Td>{ Math.round(
    ((item?.product?.MRP - item?.product?.DiscountedPrice) / item?.product?.MRP) *
      100
  ) }%</Td>
                                            <Td>{ item?.quantity }</Td>
                                            <Td>{ item?.product?.DiscountedPrice * item?.quantity }</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        <Box className="mc-invoice-list-group">
                             <Box className="mc-invoice-btns">
                             <Box className="mc-table-products" style={{"display" : "flex"}}>
                                                  {/* {returnDetails?.Images && returnDetails?.Images.map((image)=> { return( <Image src={ image } />)})}  */}
          
                                                  { returnDetails?.Images && returnDetails?.Images.map((image)=> { return(  
                                                  <ModalImage
  small={image}
  large={image}
/>   )})} 
                                                 {/* <Text>{ item?.Name }</Text> */}
                                                </Box>
                             {/* <Button
                                    
                                    
                                    icon={ "print" }
                                    text={ "print this reciept" }
                                    className={ "btn btn-dark" }
                                /> 
                            <Button 
                                    onClick={()=>downloadPdfDocument("testId","Invoice")}
                                   
                                    icon={ "download" }
                                    text={ "download as pdf" }
                                    className={ "btn btn-success" }
                                />  */}
                        </Box>
                            <List className="mc-invoice-list">
                            <Item >
                                        <Text as="span" className="title total">{ "Subtotal" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                        { totalMRPs && <Text as="span" className={`digit ${ totalMRPs ? totalMRPs : "" }`}>₹{ totalMRPs }/-</Text> }
                                    </Item>
                                    <Item >
                                        <Text as="span" className="title total">{ "Discount" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                        { totalDiscounts && <Text as="span" className={`digit `}>₹{ totalMRPs - totalDiscounts }/-</Text> }
                                    </Item>
                                   { returnDetails.isAppliedCoupon &&  <Item >
                                        <Text as="span" className="title total">{ "Coupon" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                       
                                        { <Text as="span" className={`digit`}> -₹{(Math.abs(returnDetails?.payableAmount - ((returnDetails.totalAmount - returnDetails.discount) + returnDetails?.taxes?.cgst + (returnDetails?.payableAmount < 999 ? (60) : 0)))  ).toFixed(2) }/-</Text> }
                                    </Item>} 
                                    <Item >
                                        <Text as="span" className="title total">{ "Shipping" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                        <Text as="span" className={`digit `}>₹{returnDetails?.payableAmount < 999 ? "60.00" : "0.00" }/-</Text> 
                                    </Item>
                                    <Item >
                                        <Text as="span" className="title total">{ "GST" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                         <Text as="span" className={`digit`}>₹{ gstTaxes.toFixed(2) }/-</Text> 
                                    </Item>
                                    <Item >
                                        <Text as="span" className="title total">{ "Total" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                        <Text as="span" className={`digit`}>₹{ grandTotal.toFixed(2) }/-</Text> 
                                    </Item>
                                    <Item >
                                        <Text as="span" className="title total">{ "Refundable Amount" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                        <Text as="span" className={`digit`}>₹{ returnDetails.refundAmount.toFixed(2) }/-</Text> 
                                    </Item>
                                    {/* <Item >
                                        <Text as="span" className="title total">{ "Payment" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                        { returnDetails.status && <Text as="span" className={`status green `}>{  returnDetails?.transaction?.gateway }</Text> }
                                    </Item> */}
                                    <Item >
                                        <Text as="span" className="title total">{ "Status" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                        { returnDetails.status && <Text as="span" className={`status purple `}>{  returnDetails?.status }</Text> }
                                    </Item>
                                  { returnDetails?.status === 'cancelled' && <Item >
                                        <Text as="span" className="title total">{ "Reason" }</Text>
                                        <Text as="span" className="clone">:</Text>
                                        { returnDetails.status && <Text >{  returnDetails?.cancel_reason }</Text> }
                                    </Item>}
                            </List>
                        </Box>
                        <Text className="mc-invoice-note">{ data?.note }</Text>
                        {/* <Box className="mc-invoice-btns">
                            
                             <Button
                                    
                                    
                                    icon={ "print" }
                                    text={ "print this reciept" }
                                    className={ "btn btn-dark" }
                                /> 
                            <Button 
                                    onClick={()=>downloadPdfDocument("testId","Invoice")}
                                   
                                    icon={ "download" }
                                    text={ "download as pdf" }
                                    className={ "btn btn-success" }
                                /> 
                        </Box> */}
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    )
}