import { toastr } from "react-redux-toastr";
import {
  ADMIN_NEWSLETTER_API_END,
  ADMIN_NEWSLETTER_API_REQUEST,
  GET_NEWSLETTER_SUBSCRIBER_FAILURE,
  GET_NEWSLETTER_SUBSCRIBER_SUCCESS,
  START_LOADER,
  STOP_LOADER,
} from "../actionTypes";
import constant from "../../utils/constants";
import history from "../../utils/history";
import { hideLoader } from "./loader";
import { newsletterConstant } from "../../utils/newsletterConstants";
import { getNewsletterSubscribers } from "../../services/adminNewsletterSubscribers";

const { NEWSLETTER_API_TYPES } = newsletterConstant;
const { ERROR_MESSAGES } = constant;



//Get Admin Newsletter Subscriber List
export const getNewsletterSubscriberAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_NEWSLETTER_API_REQUEST,
        payload: NEWSLETTER_API_TYPES.GET_NEWSLETTER_SUBSCRIBERS,
      });
      const response = await getNewsletterSubscribers(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_NEWSLETTER_SUBSCRIBER_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_NEWSLETTER_SUBSCRIBER_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_NEWSLETTER_API_END,
      });
    }
  };
};
