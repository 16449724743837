import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Button, Image } from "../../components/elements";
import { useErrorBoundary } from "react-error-boundary";
import history from "../../utils/history";

export default function ErrorFallback({ error }) {
    const { resetBoundary } = useErrorBoundary();
    const handleRoute = () => {
        history.push("/")
        window.location.reload()
    }
    return (
       
        <div style={{marginTop:"100px"}}>
            <Box className="mc-blank">
                <Image src="images/blank.webp" alt="blank" />
                <div role="alert">
      <p>Something went wrong!</p>
      {/* <pre style={{ color: "red" }}>{error.message}</pre> */}
      <Button
      onClick={handleRoute}
                        className="mc-btn blue"
                        text="Back to home!"
                        
                      />
      {/* <Button onClick={resetBoundary}>Try again</Button> */}
    </div>
            </Box>
            </div>
       
    )
}