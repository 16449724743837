import { toastr } from "react-redux-toastr";
import { featuredVideoConstants } from "../../../utils/CMSConstants";
import constant from "../../../utils/constants";
import {
  CHANGE_FETAURED_VIDEO_STATUS_FAILURE,
  CHANGE_FETAURED_VIDEO_STATUS_SUCCESS,
  FEATURED_API_END,
  FEATURED_BLOGS_API_REQUEST,
  FEATURED_VIDEOS_API_REQUEST,
  GET_FEATURED_VIDEOS_LIST_FAILURE,
  GET_FEATURED_VIDEOS_LIST_SUCCESS,
  START_LOADER,
  STOP_LOADER,
} from "../../actionTypes";
import { hideLoader } from "../loader";
import history from "../../../utils/history";
import {
  getFeaturedVideos,
  statusFeaturedVideos,
} from "../../../services/CMS Services/featuredVideosServices";

const { FEATURED_API_TYPES } = featuredVideoConstants;
const { ERROR_MESSAGES } = constant;

//Get Featured Blogs
export const getFeaturedBlogListAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: FEATURED_BLOGS_API_REQUEST,
      payload: FEATURED_API_TYPES.GET_FEATURED_VIDEOS_LIST,
    });
    const response = await getFeaturedVideos(param, token);
    toastr.success(response.message);
    dispatch({
      type: GET_FEATURED_VIDEOS_LIST_SUCCESS,
      payload: response.data,
    });
    dispatch({ type: STOP_LOADER });
    // history.push("/hero-product-list");s
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: GET_FEATURED_VIDEOS_LIST_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: FEATURED_API_END,
    });
  }
};

//Change Featured Videos Status
export const changeVideosStatusAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: FEATURED_VIDEOS_API_REQUEST,
      payload: FEATURED_API_TYPES.CHANGE_FEATURED_STATUS,
    });
    const response = await statusFeaturedVideos(params, token);
    toastr.success(response.message);
    dispatch({
      type: CHANGE_FETAURED_VIDEO_STATUS_SUCCESS,
      payload: { ...response.data },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: CHANGE_FETAURED_VIDEO_STATUS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: FEATURED_API_END,
    });
  }
};
