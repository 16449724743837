export const ADMIN_USERS_API_REQUEST = "ADMIN_USERS_API_REQUEST";

export const GET_ADMIN_USERS_SUCCESS = 'GET_ADMIN_USERS_SUCCESS';
export const GET_ADMIN_USERS_FAILURE = 'GET_ADMIN_USERS_FAILURE';

export const GET_ADMIN_USERS_DETAIL_SUCCESS = 'GET_ADMIN_USERS_DETAIL_SUCCESS';
export const GET_ADMIN_USERS_DETAIL_FAILURE = 'GET_ADMIN_USERS_DETAIL_FAILURE';

export const BLOCK_UNBLOCK_ADMIN_USERS_SUCCESS = 'BLOCK_UNBLOCK_ADMIN_USERS_SUCCESS';
export const BLOCK_UNBLOCK_ADMIN_USERS_FAILURE = 'BLOCK_UNBLOCK_ADMIN_USERS_FAILURE';

export const ADMIN_PRODUCT_API_REQUEST = "ADMIN_PRODUCT_API_REQUEST";
export const ADMIN_LOGIN_API_REQUEST = "ADMIN_LOGIN_API_REQUEST";
export const ADMIN_CHANGE_PASWWORD_API_REQUEST = "ADMIN_CHANGE_PASWWORD_API_REQUEST";

export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';

export const ADMIN_CPASSWORD_SUCCESS = 'ADMIN_CPASSWORD_SUCCESS';
export const ADMIN_CPASSWORD_FAILURE = 'ADMIN_CPASSWORD_FAILURE';

export const ADD_ADMIN_PRODUCT_SUCCESS = 'ADD_ADMIN_PRODUCT_SUCCESS';
export const ADD_ADMIN_PRODUCT_FAILURE = 'ADD_ADMIN_PRODUCT_FAILURE';

export const GET_ADMIN_PRODUCTS_SUCCESS = 'GET_ADMIN_PRODUCTS_SUCCESS';
export const GET_ADMIN_PRODUCTS_FAILURE = 'GET_ADMIN_PRODUCTS_FAILURE';

export const GET_ADMIN_PRODUCTS_DROPDOWN_SUCCESS = 'GET_ADMIN_PRODUCTS_DROPDOWN_SUCCESS';
export const GET_ADMIN_PRODUCTS_DROPDOWN_FAILURE = 'GET_ADMIN_PRODUCTS_DROPDOWN_FAILURE';

export const GET_ADMIN_PARENT_PRODUCTS_SUCCESS = 'GET_ADMIN_PARENT_PRODUCTS_SUCCESS';
export const GET_ADMIN_PARENT_PRODUCTS_FAILURE = 'GET_ADMIN_PARENT_PRODUCTS_FAILURE';

export const GET_ADMIN_VARIANT_PRODUCTS_SUCCESS = 'GET_ADMIN_VARIANT_PRODUCTS_SUCCESS';
export const GET_ADMIN_VARIANT_PRODUCTS_FAILURE = 'GET_ADMIN_VARIANT_PRODUCTS_FAILURE';

export const GET_ADMIN_DROPDOWN_CATEGORIES_SUCCESS = 'GET_ADMIN_DROPDOWN_CATEGORIES_SUCCESS';
export const GET_ADMIN_DROPDOWN_CATEGORIES_FAILURE = 'GET_ADMIN_DROPDOWN_CATEGORIES_FAILURE';

export const GET_ADMIN_DROPDOWN_PRODUCTS_SUCCESS = 'GET_ADMIN_DROPDOWN_PRODUCTS_SUCCESS';
export const GET_ADMIN_DROPDOWN_PRODUCTS_FAILURE = 'GET_ADMIN_DROPDOWN_PRODUCTS_FAILURE';

export const GET_ADMIN_PRODUCTS_DETAIL_SUCCESS = 'GET_ADMIN_EVENT_DETAIL_SUCCESS';
export const GET_ADMIN_PRODUCTS_DETAIL_FAILURE = 'GET_ADMIN_PRODUCTS_DETAIL_FAILURE'

export const UPDATE_ADMIN_PRODUCT_SUCCESS = 'UPDATE_ADMIN_PRODUCT_SUCCESS';
export const UPDATE_ADMIN_PRODUCT_FAILURE = 'UPDATE_ADMIN_PRODUCT_FAILURE';

export const DELETE_ADMIN_PRODUCT_SUCCESS = 'DELETE_ADMIN_PRODUCT_SUCCESS';
export const DELETE_ADMIN_PRODUCT_FAILURE = 'DELETE_ADMIN_PRODUCT_FAILURE';

export const ADMIN_COUPON_API_REQUEST = "ADMIN_COUPON_API_REQUEST";

export const CREATE_ADMIN_COUPON_SUCCESS = 'CREATE_ADMIN_COUPON_SUCCESS';
export const CREATE_ADMIN_COUPON_FAILURE = 'CREATE_ADMIN_COUPON_FAILURE';

export const GET_ADMIN_COUPONS_SUCCESS = 'GET_ADMIN_COUPONS_SUCCESS';
export const GET_ADMIN_COUPONS_FAILURE = 'GET_ADMIN_COUPONS_FAILURE';

export const DISABLE_ADMIN_COUPON_SUCCESS = 'DISABLE_ADMIN_COUPON_SUCCESS';
export const DISABLE_ADMIN_COUPON_FAILURE = 'DISABLE_ADMIN_COUPON_FAILURE';

export const UPDATE_ADMIN_COUPON_SUCCESS = 'UPDATE_ADMIN_COUPON_SUCCESS';
export const UPDATE_ADMIN_COUPON_FAILURE = 'UPDATE_ADMIN_COUPON_FAILURE';

export const GET_ADMIN_COUPON_DETAIL_SUCCESS = 'GET_ADMIN_COUPON_DETAIL_SUCCESS';
export const GET_ADMIN_COUPON_DETAIL_FAILURE = 'GET_ADMIN_COUPON_DETAIL_FAILURE'


export const ADMIN_COUPON_API_END = 'ADMIN_COUPON_API_END';

export const ADMIN_BLOG_API_REQUEST = "ADMIN_BLOG_API_REQUEST";

export const ADMIN_VIDEO_API_REQUEST = "ADMIN_VIDEO_API_REQUEST";

export const ADMIN_NEWSLETTER_API_REQUEST = "ADMIN_NEWSLETTER_API_REQUEST";

export const ADD_ADMIN_BLOG_SUCCESS = 'ADD_ADMIN_BLOG_SUCCESS';
export const ADD_ADMIN_BLOG_FAILURE = 'ADD_ADMIN_BLOG_FAILURE';

export const ADD_ADMIN_VIDEO_SUCCESS = 'ADD_ADMIN_VIDEO_SUCCESS';
export const ADD_ADMIN_VIDEO_FAILURE = 'ADD_ADMIN_VIDEO_FAILURE';

export const GET_ADMIN_BLOGS_SUCCESS = 'GET_ADMIN_BLOGS_SUCCESS';
export const GET_ADMIN_BLOGS_FAILURE = 'GET_ADMIN_BLOGS_FAILURE';

export const GET_ADMIN_VIDEOS_SUCCESS = 'GET_ADMIN_VIDEOS_SUCCESS';
export const GET_ADMIN_VIDEOS_FAILURE = 'GET_ADMIN_VIDEOS_FAILURE';

export const GET_NEWSLETTER_SUBSCRIBER_SUCCESS = 'GET_NEWSLETTER_SUBSCRIBER_SUCCESS';
export const GET_NEWSLETTER_SUBSCRIBER_FAILURE = 'GET_NEWSLETTER_SUBSCRIBER_FAILURE';

export const DISABLE_ADMIN_BLOG_SUCCESS = 'DISABLE_ADMIN_BLOG_SUCCESS';
export const DISABLE_ADMIN_BLOG_FAILURE = 'DISABLE_ADMIN_BLOG_FAILURE';

export const DISABLE_ADMIN_VIDEOS_SUCCESS = 'DISABLE_ADMIN_VIDEOS_SUCCESS';
export const DISABLE_ADMIN_VIDEOS_FAILURE = 'DISABLE_ADMIN_VIDEOS_FAILURE';

export const UPDATE_ADMIN_BLOG_SUCCESS = 'UPDATE_ADMIN_BLOG_SUCCESS';
export const UPDATE_ADMIN_BLOG_FAILURE = 'UPDATE_ADMIN_BLOG_FAILURE';

export const UPDATE_ADMIN_VIDEOS_SUCCESS = 'UPDATE_ADMIN_VIDEOS_SUCCESS';
export const UPDATE_ADMIN_VIDEOS_FAILURE = 'UPDATE_ADMIN_VIDEOS_FAILURE';

export const GET_ADMIN_BLOG_CATEGORIES_SUCCESS = 'GET_ADMIN_BLOG_CATEGORIES_SUCCESS';
export const GET_ADMIN_BLOG_CATEGORIES_FAILURE = 'GET_ADMIN_BLOG_CATEGORIES_FAILURE';

export const GET_ADMIN_BLOG_DETAIL_SUCCESS = 'GET_ADMIN_BLOG_DETAIL_SUCCESS';
export const GET_ADMIN_BLOG_DETAIL_FAILURE = 'GET_ADMIN_BLOG_DETAIL_FAILURE'

export const GET_ADMIN_SINGLE_VIDEO_SUCCESS = 'GET_ADMIN_SINGLE_VIDEO_SUCCESS';
export const GET_ADMIN_SINGLE_VIDEO_FAILURE = 'GET_ADMIN_SINGLE_VIDEO_FAILURE;'

// CMS Action Types
export const HERO_SECTION_API_REQUEST = "HERO_SECTION_API_REQUEST";
export const HERO_API_END = 'HERO_API_END';

export const FEATURED_PRODUCTS_API_REQUEST = "FEATURED_PRODUCTS_API_REQUEST";
export const FEATURED_BLOGS_API_REQUEST = "FEATURED_BLOGS_API_REQUEST";
export const FEATURED_VIDEOS_API_REQUEST = "FEATURED_VIDEOS_API_REQUEST";
export const FEATURED_API_END = 'FEATURED_API_END';

export const OFFER_ZONE_API_REQUEST = "OFFER_ZONE_API_REQUEST";
export const OFFER_API_END = 'OFFER_API_END';

export const FAQ_API_REQUEST = "FAQ_API_REQUEST";
export const FAQ_API_END = 'FAQ_API_END';

export const CONTACT_API_REQUEST = "CONTACT_API_REQUEST";
export const CONTACT_API_END = 'CONTACT_API_END';

export const INFLUENCER_API_REQUEST = "INFLUENCER_API_REQUEST";
export const INFLUENCER_API_END = 'INFLUENCER_API_END';

export const CONTENTS_API_REQUEST = "CONTENTS_API_REQUEST";
export const CONTENTS_API_END = 'CONTENTS_API_END';


export const CREATE_HERO_SUCCESS = 'CREATE_HERO_SUCCESS';
export const CREATE_HERO_FAILURE = 'CREATE_HERO_FAILURE';

export const UPDATE_HERO_SUCCESS = 'UPDATE_HERO_SUCCESS';
export const UPDATE_HERO_FAILURE = 'UPDATE_HERO_FAILURE';

export const GET_HERO_PRODUCT_LIST_SUCCESS = 'GET_HERO_PRODUCT_LIST_SUCCESS';
export const GET_HERO_PRODUCT_LIST_FAILURE = 'GET_HERO_PRODUCT_LIST_FAILURE';

export const GET_REVIEWS_LIST_SUCCESS = 'GET_REVIEWS_LIST_SUCCESS';
export const GET_REVIEWS_LIST_FAILURE = 'GET_REVIEWS_LIST_FAILURE';

export const GET_ORDERS_LIST_SUCCESS = 'GET_ORDERS_LIST_SUCCESS';
export const GET_ORDERS_LIST_FAILURE = 'GET_ORDERS_LIST_FAILURE';

export const GET_RETURN_ORDERS_LIST_SUCCESS = 'GET_RETURN_ORDERS_LIST_SUCCESS';
export const GET_RETURN_ORDERS_LIST_FAILURE = 'GET_RETURN_ORDERS_LIST_FAILURE';

export const GET_RETURN_ORDERS_DETAIL_SUCCESS = 'GET_RETURN_ORDERS_DETAIL_SUCCESS';
export const GET_RETURN_ORDERS_DETAIL_FAILURE = 'GET_RETURN_ORDERS_DETIAL_FAILURE';

export const GET_USERS_ORDERS_LIST_SUCCESS = 'GET_USERS_ORDERS_LIST_SUCCESS';
export const GET_USERS_ORDERS_LIST_FAILURE = 'GET_USERS_ORDERS_LIST_FAILURE';

export const GET_ORDERS_STATUS_SUCCESS = 'GET_ORDERS_STATUS_SUCCESS';
export const GET_ORDERS_STATUS_FAILURE = 'GET_ORDERS_STATUS_FAILURE';


export const GET_ORDERS_DETAIL_SUCCESS = 'GET_ORDERS_DETAIL_SUCCESS';
export const GET_ORDERS_DETAIL_FAILURE = 'GET_ORDERS_DETAIL_FAILURE';

export const GET_HERO_DETAIL_SUCCESS = 'GET_HERO_DETAIL_SUCCESS';
export const GET_HERO_DETAIL_FAILURE = 'GET_HERO_DETAIL_FAILURE';

export const DELETE_HERO_SUCCESS = 'DELETE_HERO_SUCCESS';
export const DELETE_HERO_FAILURE = 'DELETE_HERO_FAILURE';

export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';

export const CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS';
export const CONFIRM_ORDER_FAILURE = 'CONFIRM_ORDER_FAILURE';

export const CONFIRM_RETURN_ORDER_SUCCESS = 'CONFIRM_RETURN_ORDER_SUCCESS';
export const CONFIRM_RETURN_ORDER_FAILURE = 'CONFIRM_RETURN_ORDER_FAILURE';

export const ADD_STORE_SUCCESS = 'ADD_STORE_SUCCESS';
export const ADD_STORE_FAILURE = 'ADD_STORE_FAILURE';

export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_FAILURE = 'UPDATE_STORE_FAILURE';

export const GET_STORES_LIST_SUCCESS = 'GET_STORES_LIST_SUCCESS';
export const GET_STORES_LIST_FAILURE = 'GET_STORES_LIST_FAILURE';

export const GET_SINGLE_STORES_LIST_SUCCESS = 'GET_SINGLE_STORES_LIST_SUCCESS';
export const GET_SINGLE_STORES_LIST_FAILURE = 'GET_SINGLE_STORES_LIST_FAILURE';

export const GET_STORES_LIST_BY_ZIPCODE_SUCCESS = 'GET_STORES_LIST_BY_ZIPCODE_SUCCESS';
export const GET_STORES_LIST_BY_ZIPCODE_FAILURE = 'GET_STORES_LIST_BY_ZIPCODE_FAILURE';

export const CHANGE_STORE_ACTIVE_STATUS_SUCCESS = 'CHANGE_STORE_ACTIVE_STATUS_SUCCESS';
export const CHANGE_STORE_ACTIVE_STATUS_FAILURE = 'CHANGE_STORE_ACTIVE_STATUS_FAILURE';

export const GET_FEATURED_PRODUCT_LIST_SUCCESS = 'GET_FEATURED_PRODUCT_LIST_SUCCESS';
export const GET_FEATURED_PRODUCT_LIST_FAILURE = 'GET_FEATURED_PRODUCT_LIST_FAILURE';

export const CHANGE_FETAURED_STATUS_SUCCESS = 'CHANGE_FETAURED_STATUS_SUCCESS';
export const CHANGE_FETAURED_STATUS_FAILURE = 'CHANGE_FETAURED_STATUS_FAILURE';

export const CHANGE_FETAURED_REVIEW_STATUS_SUCCESS = 'CHANGE_FETAURED_REVIEW_STATUS_SUCCESS';
export const CHANGE_FETAURED_REVIEW_STATUS_FAILURE = 'CHANGE_FETAURED_REVIEW_STATUS_FAILURE';

export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS';
export const CHANGE_ORDER_STATUS_FAILURE = 'CHANGE_ORDER_STATUS_FAILURE';

export const CHANGE_RETURN_ORDER_STATUS_SUCCESS = 'CHANGE_RETURN_ORDER_STATUS_SUCCESS';
export const CHANGE_RETURN_ORDER_STATUS_FAILURE = 'CHANGE_RETURN_ORDER_STATUS_FAILURE';


export const CHANGE_FETAURED_BLOG_STATUS_SUCCESS = 'CHANGE_FETAURED_BLOG_STATUS_SUCCESS';
export const CHANGE_FETAURED_BLOG_STATUS_FAILURE = 'CHANGE_FETAURED_BLOG_STATUS_FAILURE';

export const CHANGE_FETAURED_VIDEO_STATUS_SUCCESS = 'CHANGE_FETAURED_VIDEO_STATUS_SUCCESS';
export const CHANGE_FETAURED_VIDEO_STATUS_FAILURE = 'CHANGE_FETAURED_VIDEO_STATUS_FAILURE';

export const GET_FEATURED_BLOGS_LIST_SUCCESS = 'GET_FEATURED_BLOGS_LIST_SUCCESS';
export const GET_FEATURED_BLOGS_LIST_FAILURE = 'GET_FEATURED_BLOGS_LIST_FAILURE';

export const GET_FEATURED_VIDEOS_LIST_SUCCESS = 'GET_FEATURED_VIDEOS_LIST_SUCCESS';
export const GET_FEATURED_VIDEOS_LIST_FAILURE = 'GET_FEATURED_VIDEOS_LIST_FAILURE';

export const CREATE_OFFER_ZONE_SUCCESS = 'CREATE_OFFER_ZONE_SUCCESS';
export const CREATE_OFFER_ZONE_FAILUTRE = 'CREATE_OFFER_ZONE_FAILUTRE';

export const GET_OFFER_BANNER_LIST_SUCCESS = 'GET_OFFER_BANNER_LIST_SUCCESS';
export const GET_OFFER_BANNER_LIST_FAILURE = 'GET_OFFER_BANNER_LIST_FAILURE';

export const CHANGE_OFFER_STATUS_SUCCESS = 'CHANGE_OFFER_STATUS_SUCCESS';
export const CHANGE_OFFER_STATUS_FAILURE = 'CHANGE_OFFER_STATUS_FAILURE';

export const CREATE_FAQS_SUCCESS = 'CREATE_FAQS_SUCCESS';
export const CREATE_FAQS_FAILURE = 'CREATE_FAQS_FAILURE';

export const GET_FAQS_LIST_SUCCESS = 'GET_FAQS_LIST_SUCCESS';
export const GET_FAQS_LIST_FAILURE = 'GET_FAQS_LIST_FAILURE';

export const UPDATE_FAQS_SUCCESS = 'UPDATE_FAQS_SUCCESS';
export const UPDATE_FAQS_FAILURE = 'UPDATE_FAQS_FAILURE';

export const GET_FAQS_DETAIL_SUCCESS = 'GET_FAQS_DETAIL_SUCCESS';
export const GET_FAQS_DETAIL_FAILURE = 'GET_FAQS_DETAIL_FAILURE';

export const CREATE_INFLUENCER_SUCCESS = 'CREATE_INFLUENCER_SUCCESS';
export const CREATE_INFLUENCER_FAILURE = 'CREATE_INFLUENCER_FAILURE';

export const GET_INFLUENCERS_LIST_SUCCESS = 'GET_INFLUENCERS_LIST_SUCCESS';
export const GET_INFLUENCERS_LIST_FAILURE = 'GET_INFLUENCERS_LIST_FAILURE';

export const UPDATE_INFLUENCERS_SUCCESS = 'UPDATE_INFLUENCERS_SUCCESS';
export const UPDATE_INFLUENCERS_FAILURE = 'UPDATE_INFLUENCERS_FAILURE';

export const GET_INFLUENCER_DETAIL_SUCCESS = 'GET_INFLUENCER_DETAIL_SUCCESS';
export const GET_INFLUENCER_DETAIL_FAILURE = 'GET_INFLUENCER_DETAIL_FAILURE';

export const CHANGE_FETAURED_INFLUENCER_STATUS_SUCCESS = 'CHANGE_FETAURED_INFLUENCER_STATUS_SUCCESS';
export const CHANGE_FETAURED_INFLUENCER_STATUS_FAILURE = 'CHANGE_FETAURED_INFLUENCER_STATUS_FAILURE';

export const CREATE_UPDATE_CONTACTS_SUCCESS = 'CREATE_UPDATE_CONTACTS_SUCCESS';
export const CREATE_UPDATE_CONTACTS_FAILURE = 'CREATE_UPDATE_CONTACTS_FAILURE';

export const CREATE_UPDATE_CONTENT_SUCCESS = 'CREATE_UPDATE_CONTENT_SUCCESS';
export const CREATE_UPDATE_CONTENT_FAILURE = 'CREATE_UPDATE_CONTENT_FAILURE';

export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE';

export const GET_CONTENTS_SUCCESS = 'GET_CONTENTS_SUCCESS';
export const GET_CONTENTS_FAILURE = 'GET_CONTENTS_FAILURE';

export const REVIEW_API_REQUEST = "REVIEW_API_REQUEST";
export const REVIEW_API_END = 'REVIEW_API_END';

export const ORDER_API_REQUEST = "ORDER_API_REQUEST"
export const ORDER_API_END = 'ORDER_API_END';

export const RETURN_ORDER_API_REQUEST = "RETURN_ORDER_API_REQUEST"
export const RETURN_ORDER_API_END = 'RETURN_ORDER_API_END';

export const ADMIN_BLOG_API_END = 'ADMIN_BLOG_API_END';
export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';

export const ADMIN_VIDEO_API_END = 'ADMIN_VIDEO_API_END';
export const ADMIN_NEWSLETTER_API_END = 'ADMIN_NEWSLETTER_API_END';
export const ADMIN_PRODUCT_API_END = 'ADMIN_PRODUCT_API_END';
export const ADMIN_LOGIN_API_END = 'ADMIN_LOGIN_API_END';
export const ADMIN_CPASSWORD_API_END = 'ADMIN_CPASSWORD_API_END';

export const ADMIN_USER_API_END = 'ADMIN_USER_API_END';

export const STORE_LOCATOR_API_REQUEST = "STORE_LOCATOR_API_REQUEST";
export const STORE_LOCATOR_API_END = 'STORE_LOCATOR_API_END';

export const CLEAR_STORE = 'CLEAR_STORE';
