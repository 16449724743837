
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "../../../hooks/useQuery";
import PageLayout from "../../../layouts/PageLayout";
import { CardLayout } from "../../../components/cards";
import { LabelTextarea } from "../../../components/fields";
import { Button } from "../../../components/elements";
import { Breadcrumb } from "../../../components";
import {createFAQsAction, getFAQsDetailAction, updateFAQsDetail } from "../../../store/actions/CMS actions/FAQs";
import { hideLoader, showLoader } from "../../../store/actions/loader";
import { createUpdateContactAction, getContactsListAction } from "../../../store/actions/CMS actions/contactUs";


const CreateContactUs = () => {
      const productId = useQuery();
      const [isEditEvent, setEditEventStatus] = useState(false);
      const dispatch = useDispatch();
      const location = useLocation();
    
      const [faqData, setFAQData] = useState({
        CompanyNumber: "",
        CompanyAddress: "",
        CompanyEmail: "",
        pageContent: "",
      });
    
      useEffect(() => {
        if (location?.state?.isContactEdit) {
          dispatch(showLoader());
          dispatch(getContactsListAction()).then((res) => {
          
            setEditEventStatus(true);
            setFAQData({
                CompanyNumber: res?.data?.CompanyNumber,
                CompanyAddress: res?.data?.CompanyAddress,
                CompanyEmail: res?.data?.CompanyEmail,
                pageContent: res?.data?.pageContent,
            })
          });
          dispatch(hideLoader());
        }
      }, [location?.state?.isContactEdit]);
    
    
      const handleSubmit = () => {
       dispatch(createUpdateContactAction(faqData))
      };
    
      const formik = useFormik({
        initialValues: faqData,
        onSubmit: handleSubmit,
      });

    
      return (
        <>
          <PageLayout>
            <form
              onSubmit={formik.handleSubmit}
              encType="multipart/form-data"
              novalidate
            >
              <Row>
                <Col xl={12}>
                  <CardLayout>
                    <Breadcrumb title={"Contact(s)"}>
                    </Breadcrumb>
                  </CardLayout>
                </Col>
                <Col xl={7}>
                  <CardLayout>
                    <Row>
                      <Col xl={12}>
                      <LabelTextarea
                          label="Company Number"
                          name="CompanyNumber"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            CompanyNumber: e.target.value})}
                          value={faqData.CompanyNumber}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Company Address"
                          name="CompanyAddress"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            CompanyAddress: e.target.value})}
                          value={faqData.CompanyAddress}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Company Email"
                          name="CompanyEmail"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            CompanyEmail: e.target.value})}
                          value={faqData.CompanyEmail}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="page Content"
                          name="pageContent"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            pageContent: e.target.value})}
                          value={faqData.pageContent}
                        />
                      </Col>
                    </Row>
                  </CardLayout>
                </Col>
                <Col xl={5}></Col>
                <Col xl={12}>
                  {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Create FAQs"
                        icon="chat"
                      />
                    )}
                </Col>
              </Row>
            </form>
          </PageLayout>
        </>
      );
    };
    

export default CreateContactUs;