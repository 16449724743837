import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Anchor, Heading, Box, Text, Image, Icon, Button, Input } from "../elements";
import { useDispatch } from "react-redux";
import { ToggleOff, ToggleOn } from "@mui/icons-material";
import TableHeaderRow from "../shared/TableHeaderRow";
import moment from "moment";
import { disableAdminCouponAction } from "../../store/actions/adminCoupon";
import { toastr } from "react-redux-toastr";

export default function CouponsTable({ thead, tbody, products, memoizedHandleClick,memoizedTableConfig }) {

    const [alertModal, setAlertModal] = useState(false);
    const [data, setData] = useState([]);
    const [productId, setProductId] = useState();
    const [productsData, setProductsData] = useState([]);
    const dispatch = useDispatch();
    

    useEffect(() => { setData(tbody); setProductsData(products) }, [tbody, products]);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;

        if (name === "allCheck") {
            const checkData = data?.map((item) => {
                return { ...item, isChecked: checked };
            });
            setData(checkData);
        }
        else {
            const checkData = data?.map((item) =>
                item.name === name ? { ...item, isChecked: checked } : item
            );
            setData(checkData);
        }
    }

    const handleDisableProduct = (productIds) => {
        dispatch(disableAdminCouponAction(productId || productIds))
    }

    const getIdinModal = (key) => {
        setAlertModal(true)
        setProductId(key)
    }

    const handleCopied = (item) => {
        navigator.clipboard.writeText(item?.method?.discountCode);
        toastr.info("copied")
    }

    return (
        <Box className="mc-table-responsive">
            <Table className="mc-table product">
                <Thead className="mc-table-head primary">
                    <Tr>
                        {/* <Th>
                            <Box className="mc-table-check">
                                <Input
                                    type="checkbox" 
                                    name="allCheck"
                                    checked={ data?.filter((item)=> item.isChecked !== true).length < 1 } 
                                    onChange={ handleCheckbox } 
                                />
                                <Text>Image</Text>
                            </Box>
                        </Th> */}
                        <TableHeaderRow 
                        configData={memoizedTableConfig}
                        onDesOrder={memoizedHandleClick}
                        onAscOrder={memoizedHandleClick}
                        />
                        {/* {thead.map((item, index) => (
                            <Th key={index}>{item}</Th>
                        ))} */}
                    </Tr>
                </Thead>
                {/* <Tbody className="mc-table-body even">
                    {(data || productsData)?.map((item, index) => (
                        <Tr key={ index }> 
                            <Td title={ index + 1 }>
                                <Box className="mc-table-check">
                                    <Input 
                                        type="checkbox" 
                                        name={item.name} 
                                        checked={ item?.isChecked || false }
                                        onChange={ handleCheckbox } 
                                    />
                                    <Text>#{ item.productId }</Text>
                                </Box>
                            </Td>
                            <Td>
                                <Box className="mc-table-product md">
                                    <Image src={ item.src } alt={ item.alt } />
                                    <Box className="mc-table-group">
                                        <Heading as="h6">{ item.heading }</Heading>
                                        <Text>{ item.descrip }</Text>
                                    </Box>
                                </Box>
                            </Td>
                            <Td>{ item.category }</Td>
                            <Td>{ item.brand }</Td>
                            <Td>
                                <Box className="mc-table-price">
                                    <del>{ item.price.previous }</del>
                                    <Text>{ item.price.present }</Text>
                                </Box>
                            </Td>
                            <Td>{ item.stock }</Td>
                            <Td>
                                <Box className="mc-table-rating">
                                    <Icon>{ item.rating.icon }</Icon>
                                    <Heading>{ item.rating.percent }</Heading>
                                    <Text>({ item.rating.number })</Text>
                                </Box>
                            </Td>
                            <Td>{ item.order }</Td>
                            <Td>{ item.sales }</Td>
                            <Td>
                                <Box className="mc-table-action">
                                    <Anchor href="/product-view" title="View" className="material-icons view">{ item.action.view }</Anchor>
                                    <Anchor href="/product-upload" title="Edit" className="material-icons edit">{ item.action.edit }</Anchor>
                                    <Button title="Delete" className="material-icons delete" onClick={()=> setAlertModal(true)}>{ item.action.delete }</Button>
                                </Box>
                            </Td>
                        </Tr>
                    ))}
                </Tbody> */}
                <Tbody className="mc-table-body even">
                    {productsData && (productsData)?.map((item, index) => (
                        <Tr key={index}>
                            <Td title={index + 1}>
                                <Box className="mc-table-check">
                                    {/* <Input 
                                        type="checkbox" 
                                        // name={item.name} 
                                        // checked={ item?.isChecked || false }
                                        onChange={ handleCheckbox } 
                                    /> */}
                                    <Box className={`mc-table-product md ${item?.method?.discountCode && 'discount-border'}`} >
                                    <Heading as="h6"><button onClick={() => handleCopied(item)}><strong>{item?.method?.discountCode} </strong></button>
                                     </Heading>
                                    </Box>
                                    
                                </Box>
                                <span style={{fontSize:"13px"}} >{item?.name ? item?.name : "-"}</span>
                            </Td>
                            <Td>
                                <Box className="mc-table-product md">
                                    <Box className="mc-table-group">
                                    <Text className={!item?.active ? "mc-table-badge red" : "mc-table-badge green"}>{ item?.active ? "Active" : "InActive" }</Text>
                                        <Text>{""}</Text>
                                    </Box>
                                </Box>
                            </Td>
                            <Td>{item?.method?.name}</Td>
                            {/* <Td>{item?.productCategory.length !== 0 ? item?.productCategory : "-"}</Td> */}
                            <Td>
                                <Box style={{color: "#005BD3"}}>
                                    
                                    <Text>{item?.type === "amountOffProducts" ? "Amount Off Products" : item?.type === "amountOffOrder" ? "Amount Off Order" : item?.type === "buyXgetY" ? "Buy X Get Y" : "Free Shiping"}</Text>
                                </Box>
                            </Td>
                            {/* <Td>{item?.discountValue ? item?.discountValue  : "-" }</Td> */}
                            {/* <Td>{item?.combinations.map((item, index) => `${index + 1}. ${item}`).join('\n')}</Td> */}
                            <Td><span style={{color: "red"}}>{item?.activeDates?.startDate ? moment(item?.activeDates?.startDate).utc().format('ll,HH:mm') : "-"}</span></Td>
                            <Td><span style={{color: "red"}}>{item?.activeDates?.endDate ? moment(item?.activeDates?.endDate).utc().format('ll,HH:mm') : "-"}</span></Td>
                            <Td>{item?.maxDiscountUses?.limitNumberOfTimesThisDiscountCanBeUsed?.usedCount}</Td>
                            {/* <Td>
                                <Box className="mc-table-rating">
                                    <Icon>{ item.rating.icon }</Icon>
                                    <Heading>{ item.rating.percent }</Heading>
                                    <Text>({ item.rating.number })</Text>
                                </Box>
                            </Td>
                            <Td>{ 'item.order' }</Td>
                            <Td>{ 'item.sales' }</Td> */}
                            <Td>
                                <Box className="mc-table-action">
                                    <Anchor href={{ pathname: `/create-coupon`,search:`?_id=${item?._id}`}} state={item?.type} title="Edit" className="material-icons edit">edit</Anchor>
                                    <Anchor
                                    href={""}
                                        title="Enable/Disable"
                                        className="material-icons view"
                                        onClick={() => {
                                            item?.active ? getIdinModal(item?._id) : handleDisableProduct(item?._id); 
                                        }}
                                    >
                                        {item?.active ? (
                                            <ToggleOn style={{ color: "#000" }} />
                                        ) : (
                                            <ToggleOff style={{ color: "#85818b" }} />
                                        )}
                                    </Anchor>
                                  
                                </Box>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            <Modal show={alertModal} onHide={() => setAlertModal(false)}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">are your sure!</Heading>
                    <Text as="p">Want to disable this coupon?</Text>
                    <Modal.Footer>
                        <Button type="button" className="btn btn-secondary" onClick={() => setAlertModal(false)}>nop, close</Button>
                        <Button type="button" className="btn btn-danger" onClick={() => handleDisableProduct()}>yes, disable</Button>
                    </Modal.Footer>
                </Box>
            </Modal>
        </Box>
    );
}
