
import React, { useEffect, useState } from "react";
import { Row, Col, Anchor } from "react-bootstrap";

import data from "../../../data/master/productUpload.json";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useQuery } from "../../../hooks/useQuery";
import PageLayout from "../../../layouts/PageLayout";
import { CardHeader, CardLayout } from "../../../components/cards";
import { LabelField, LabelTextarea } from "../../../components/fields";
import { Box } from "@mui/material";
import { Button, Icon, Image, Label, Text } from "../../../components/elements";
import ImageInput from "../../../components/elements/ImageInput";
import { Breadcrumb } from "../../../components";
import { getAdminProductsAction } from "../../../store/actions/adminProduct";
import { createHeroAction } from "../../../store/actions/CMS actions/heroSection";
import LabelTinyEditor from "../../../components/fields/LabelTinyEditor";
import { createOfferZone } from "../../../store/actions/CMS actions/offerZone";


const CreateOfferBanner = () => {
   
      const [uploadFile, setUploadFile] = React.useState("choose");
      const [previewImages, setPreviewImages] = useState([]);
      const [imageFile, setImageFile] = useState([]);
      const [selectedOptions, setSelectedOptions] = useState();
      const [content, setContent] = useState();
      const productId = useQuery();
      const [isEditEvent, setEditEventStatus] = useState(false);
      const dispatch = useDispatch();
      const location = useLocation();

      const { productList } = useSelector((state) => ({
        productList: state.adminProduct.adminProducts?.products,
      }));
    
      const [offerData, setOfferData] = useState({
        banner: "",
        offer: "",
      });


      useEffect(() => {
        dispatch(getAdminProductsAction())
      }, []);
    
      // useEffect(() => {
      //   if (location?.state?.isBlogEdit) {
      //     dispatch(showLoader());
      //     dispatch(getAdminBlogDetailAction(productId.get("_id"))).then((res) => {
      //       const blogCategory = {
      //         label: res?.data?.category,
      //         value: res?.data?.category,
      //       };
      //       // Call the function to convert image URLs into FileList object
      //       fetchAndConvertImages(res?.data?.blogImage).then((fileList) => {
      //         formik.setFieldValue("blogImage", fileList);
      //         setImageFile(fileList);
      //       });
      //       setEditEventStatus(true);
      //       formik.setFieldValue("category", res?.data?.category);
      //       formik.setFieldValue("title", res?.data?.title);
      //       formik.setFieldValue("description", res?.data?.description);
      //       setPreviewImages(res?.data?.blogImage);
      //       setSelectedOptions(blogCategory);
      //       setContent(res?.data?.content);
      //     });
      //     dispatch(hideLoader());
      //   }
      // }, [location?.state?.isBlogEdit]);
    
      // useEffect(() => {
      //   dispatch(getBlogCategories());
      // }, []);
    
      const products =
      productList &&
      productList?.map((item) => {
      return { label: item?.productName, value: item?.productId };
    });

    
      const handleUploadImage = (e, setFieldValue) => {
        if ([...e.target.files].length > 2) {
          return toastr.light("Maximum 2 image will be uploaded");
        } else {
          const newImageUrls = [];
          [...e.target.files].length >= 1 &&
            [...e.target.files].length < 3 &&
            [...e.target.files].forEach((image) =>
              newImageUrls.push(URL.createObjectURL(image))
            );
          setPreviewImages(newImageUrls);
          setFieldValue("banner", e.target.files);
        }
      };
    
      // const handleChange = (e, setFieldValue) => {
      //   setFieldValue(e.target.name, e.target.value);
      //   // setHeroData({...heroData,
      //   //   product: formik?.values?.product,
      //   //   quote: formik?.values?.quote,
      //   //   nutrition: formik?.values?.nutrition,
      //   //   description: formik?.values?.description,
      //   //   ingredients: formik?.values?.ingredients })
      // };

      const handleChange = (e, setFieldValue) => {
        const input = e.target.value;
    
        const cleanedValue = input.replace(/\D/g, '');
        
        // Limit to 10 digits
        const limitedValue = cleanedValue.slice(0, 10);
        setFieldValue(e.target.name, limitedValue );
      };
    
      const handleSelectCategory = (data, setFieldValue) => {
        setFieldValue("product", data.value);
        setSelectedOptions(data);
      };


      const handleSubmit = () => {
        let formData = new FormData();
        formData.append("offer", formik?.values?.offer);
        [...formik.values.banner].forEach((image) => {
          formData.append("banner", image);
        });
        // isEditEvent
        //   ? dispatch(updateAdminProductAction(productId.get("_id"), formData))
        //   : dispatch(addProductAction(formData));
        dispatch(createOfferZone(formData));
      };
    
    
      // const handleSubmit = () => {
      // dispatch(createHeroAction(heroData))
      //   // isEditEvent
      //   //   ? dispatch(updateAdminBlogAction(productId.get("_id"), formData))
      //   //   : dispatch(addBlogAction(formData));
      // };
    
      const formik = useFormik({
        initialValues: offerData,
        onSubmit: handleSubmit,
      });
    
      return (
        <>
          <PageLayout>
            <form
              onSubmit={formik.handleSubmit}
              encType="multipart/form-data"
              novalidate
            >
              <Row>
                <Col xl={12}>
                  <CardLayout>
                    <Breadcrumb title={"Create Offer(s) Banner"}>
                      {/* {data?.breadcrumb.map((item, index) => (
                        <li key={index} className="mc-breadcrumb-item">
                          {item.path ? (
                            <Anchor className="mc-breadcrumb-link" href={item.path}>
                              {item.text}
                            </Anchor>
                          ) : (
                            item.text
                          )}
                        </li>
                      ))} */}
                    </Breadcrumb>
                  </CardLayout>
                </Col>
                <Col xl={14}>
                  <CardLayout>
                    <Row>
                      {/* <Col xl={12}>
                        <LabelField
                          type="select"
                          name="product"
                          label="Products"
                          options={products}
                          onChange={(e) =>
                            handleSelectCategory(e, formik.setFieldValue)
                          }
                          value={selectedOptions}
                          fieldSize="w-100 h-md"
                        />
                      </Col> */}
                      {/* <Col xl={12}>
                        <LabelField
                          type="text"
                          label="Product Quote"
                          name="title"
                          fieldSize="w-100 h-md"
                          onChange={(e) => handleChange(e, formik.setFieldValue)}
                          value={formik.values.title}
                        />
                      </Col> */}
                      
                      <Col xl={12}>
                    <LabelField
                      type="text"
                      name="offer"
                      label="Offer Value(%)"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.offer}
                    />
                  </Col>
                  {/* <Col xl={12}>
                    <LabelTinyEditor
                      label="Blog Content"
                      value={content}
                      fieldSize="w-100 h-text-md"
                      onEditorChange={(e) => setContent(e)}
                    />
                  </Col> */}
                      {/* <Col xl={12}>
                        <LabelTextarea
                          label="Product Descriptions"
                          name="description"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => handleChange(e, formik.setFieldValue)}
                          value={formik.values.description}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Product Nutrition"
                          name="nutrition"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => handleChange(e, formik.setFieldValue)}
                          value={formik.values.nutrition}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Product Ingredients"
                          name="ingredients"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => handleChange(e, formik.setFieldValue)}
                          value={formik.values.ingredients}
                        />
                      </Col> */}
                    </Row>
                  </CardLayout>
                </Col>
                <Col xl={5}></Col>
                <Col xl={12}>
                  <CardLayout>
                    <CardHeader title="Offer Banner Image" />
                    <Box className="mc-product-upload-media">
                      {previewImages &&
                        previewImages.map((img, i) => {
                          return (
                            <Box className="mc-product-upload-image">
                              <Image
                                src={img}
                                alt={i}
                                key={i}
                                onChange={handleChange}
                              />
                            </Box>
                          );
                        })}
                      <Box className="mc-product-upload-file">
                        <ImageInput
                          type="file"
                          name="banner"
                          multiple={false}
                          accept="image/*"
                          id="blog"
                          onChange={(e) =>
                            handleUploadImage(e, formik.setFieldValue)
                          }
                        />
                        <Label htmlFor="blog">
                          <Icon type="collections" />
                          <Text>{uploadFile}</Text>
                        </Label>
                      </Box>
                    </Box>
                    {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Create Offer Banner"
                        icon="chat"
                      />
                    )}
                  </CardLayout>
                  {/* {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Create Hero"
                        icon="chat"
                      />
                    )} */}
                </Col>
              </Row>
            </form>
          </PageLayout>
        </>
      );
    };
    

export default CreateOfferBanner;