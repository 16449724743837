import constant from "../../utils/constants";
import {
  ADMIN_COUPON_API_END,
  ADMIN_COUPON_API_REQUEST,
  CREATE_ADMIN_COUPON_FAILURE,
  CREATE_ADMIN_COUPON_SUCCESS,
  DISABLE_ADMIN_COUPON_SUCCESS,
  GET_ADMIN_COUPONS_FAILURE,
  GET_ADMIN_COUPONS_SUCCESS,
  GET_ADMIN_COUPON_DETAIL_FAILURE,
  GET_ADMIN_COUPON_DETAIL_SUCCESS,
  UPDATE_ADMIN_PRODUCT_FAILURE,
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminCoupons: { coupons: [], meta: "" },
};

const adminCoupon = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case ADMIN_COUPON_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case ADMIN_COUPON_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case CREATE_ADMIN_COUPON_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case CREATE_ADMIN_COUPON_FAILURE:
        return {
            ...state,
            status: API_STATUS.FAILURE,
          };
    case GET_ADMIN_COUPONS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminCoupons: { data: [], meta: "" },
      };
    case GET_ADMIN_COUPONS_SUCCESS:
      return {
        ...state,
        adminCoupons: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          coupons: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_COUPON_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminProductDetail: action.payload,
        loading: false,
      };
    case GET_ADMIN_COUPON_DETAIL_FAILURE:
    case UPDATE_ADMIN_PRODUCT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case DISABLE_ADMIN_COUPON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminCoupons: {
          ...state.adminCoupons,
          coupons: state.adminCoupons.coupons,
        },
      };

    default:
      return state;
  }
};

export { adminCoupon };
