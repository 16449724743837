import { toastr } from "react-redux-toastr";
import { adminChangePassword, adminLogIn } from "../../services/adminAuthServices";
import { ADMIN_CHANGE_PASWWORD_API_REQUEST, ADMIN_CPASSWORD_API_END, ADMIN_CPASSWORD_FAILURE, ADMIN_CPASSWORD_SUCCESS, ADMIN_LOGIN_API_END, ADMIN_LOGIN_API_REQUEST, ADMIN_LOGIN_FAILURE, ADMIN_LOGIN_SUCCESS, START_LOADER, STOP_LOADER } from "../actionTypes";
import history from "../../utils/history";
import { productConstant } from "../../utils/productConstants";
import { adminAuthConstant } from "../../utils/adminAuthConstants";

const { ADMIN_LOGIN_API_TYPES } = productConstant;
const { ADMIN_CHANGE_PASSWORD_API_TYPES } = adminAuthConstant;

//Admin Log In
export const adminLoginAuth = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_LOGIN_API_REQUEST,
        payload: ADMIN_LOGIN_API_TYPES.ADMIN_LOGIN,
      });
      const response = await adminLogIn(param);
      dispatch({ type: ADMIN_LOGIN_SUCCESS, payload: response });
      dispatch({ type: STOP_LOADER });
      if(response?.statusCode === 200) {
        localStorage.setItem(
          "token",
         response.token);
         history.push("/");
      } else  {
        toastr.error(" Some Error Occured")
      }
      //   localStorage.setItem(
      //   "token",
      //  response.token);
      //  history.push("/");
      // toastr.success(response.message);
    } catch (error) {
    //   const err = Object.values(error?.data?.error || {}) || "    ";
      dispatch({ type: STOP_LOADER });
      dispatch({ type: ADMIN_LOGIN_FAILURE, payload: error });
      if (error?.data?.statusCode === 401) {
        toastr.error(error?.data?.error);
      } 
      else {
        toastr.error(error?.data?.error);
      }
    } finally {
      dispatch({
        type: ADMIN_LOGIN_API_END,
      });
    }
  };

  //Admin Change Password
export const adminCPassword = (param,passwords) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_CHANGE_PASWWORD_API_REQUEST,
      payload: ADMIN_CHANGE_PASSWORD_API_TYPES.ADMIN_CPASSWORD,
    });
    const response = await adminChangePassword(param,passwords,token);
    toastr.success(response.message);
    dispatch({ type: ADMIN_CPASSWORD_SUCCESS, payload: response });
    dispatch({ type: STOP_LOADER });
    // history.push("/");
  } catch (error) {
  //   const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: ADMIN_CPASSWORD_FAILURE, payload: error });
    if (error?.data?.statusCode === 401) {
      toastr.error(error?.data?.error);
    } 
    else {
     toastr.error(error?.data?.error);
    }
  } finally {
    dispatch({
      type: ADMIN_CPASSWORD_API_END,
    });
  }
};