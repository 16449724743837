import constant from "../../../utils/constants";
import { FAQ_API_REQUEST, FAQ_API_END, CREATE_FAQS_SUCCESS, CREATE_FAQS_FAILURE, GET_FAQS_LIST_FAILURE, GET_FAQS_LIST_SUCCESS, GET_FAQS_DETAIL_SUCCESS, GET_FAQS_DETAIL_FAILURE, UPDATE_FAQS_SUCCESS, UPDATE_FAQS_FAILURE } from "../../actionTypes";


const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  FAQ: { faqs: [], meta: "" },
};

const FAQs = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case FAQ_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case FAQ_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case CREATE_FAQS_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case CREATE_FAQS_FAILURE:
    case GET_FAQS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        FAQ: { faqs: [], meta: "" },
      };
    case GET_FAQS_LIST_SUCCESS:
      return {
        ...state,
        FAQ: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          faqs: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_FAQS_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        faqsDetail: action.payload,
        loading: false,
      };
    case GET_FAQS_DETAIL_FAILURE:
      case UPDATE_FAQS_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
        };
    case UPDATE_FAQS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };

    default:
      return state;
  }
};

export { FAQs };
