import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Anchor,
  Heading,
  Box,
  Text,
  Image,
  Icon,
  Button,
  Input,
} from "../elements";
import { useDispatch } from "react-redux";
import { ToggleOff, ToggleOn } from "@mui/icons-material";
import TableHeaderRow from "../shared/TableHeaderRow";
import moment from "moment";
import { disableAdminBlogsAction } from "../../store/actions/adminBlog";
import { disableOffersBannerAction } from "../../store/actions/CMS actions/offerZone";
import { changeFeatureInfluencerStatus } from "../../store/actions/CMS actions/Influencers";

export default function InfluencersTable({
  thead,
  tbody,
  products,
  memoizedHandleClick,
  memoizedTableConfig,
}) {
  const [alertModal, setAlertModal] = useState(false);
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState();
  const [productsData, setProductsData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(tbody);
    setProductsData(products);
  }, [tbody, products]);

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;

    if (name === "allCheck") {
      const checkData = data?.map((item) => {
        return { ...item, isChecked: checked };
      });
      setData(checkData);
    } else {
      const checkData = data?.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item
      );
      setData(checkData);
    }
  };

  const handleChangeFeature = (productIds) => {
    dispatch(changeFeatureInfluencerStatus(productId || productIds));
  };

  const getIdinModal = (key) => {
    setAlertModal(true);
    setProductId(key);
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            {/* <Th>
                            <Box className="mc-table-check">
                                <Input
                                    type="checkbox" 
                                    name="allCheck"
                                    checked={ data?.filter((item)=> item.isChecked !== true).length < 1 } 
                                    onChange={ handleCheckbox } 
                                />
                                <Text>Image</Text>
                            </Box>
                        </Th> */}
            <TableHeaderRow
              configData={memoizedTableConfig}
              onDesOrder={memoizedHandleClick}
              onAscOrder={memoizedHandleClick}
            />
            {/* {thead.map((item, index) => (
                            <Th key={index}>{item}</Th>
                        ))} */}
          </Tr>
        </Thead>
        {/* <Tbody className="mc-table-body even">
                    {(data || productsData)?.map((item, index) => (
                        <Tr key={ index }> 
                            <Td title={ index + 1 }>
                                <Box className="mc-table-check">
                                    <Input 
                                        type="checkbox" 
                                        name={item.name} 
                                        checked={ item?.isChecked || false }
                                        onChange={ handleCheckbox } 
                                    />
                                    <Text>#{ item.productId }</Text>
                                </Box>
                            </Td>
                            <Td>
                                <Box className="mc-table-product md">
                                    <Image src={ item.src } alt={ item.alt } />
                                    <Box className="mc-table-group">
                                        <Heading as="h6">{ item.heading }</Heading>
                                        <Text>{ item.descrip }</Text>
                                    </Box>
                                </Box>
                            </Td>
                            <Td>{ item.category }</Td>
                            <Td>{ item.brand }</Td>
                            <Td>
                                <Box className="mc-table-price">
                                    <del>{ item.price.previous }</del>
                                    <Text>{ item.price.present }</Text>
                                </Box>
                            </Td>
                            <Td>{ item.stock }</Td>
                            <Td>
                                <Box className="mc-table-rating">
                                    <Icon>{ item.rating.icon }</Icon>
                                    <Heading>{ item.rating.percent }</Heading>
                                    <Text>({ item.rating.number })</Text>
                                </Box>
                            </Td>
                            <Td>{ item.order }</Td>
                            <Td>{ item.sales }</Td>
                            <Td>
                                <Box className="mc-table-action">
                                    <Anchor href="/product-view" title="View" className="material-icons view">{ item.action.view }</Anchor>
                                    <Anchor href="/product-upload" title="Edit" className="material-icons edit">{ item.action.edit }</Anchor>
                                    <Button title="Delete" className="material-icons delete" onClick={()=> setAlertModal(true)}>{ item.action.delete }</Button>
                                </Box>
                            </Td>
                        </Tr>
                    ))}
                </Tbody> */}
        <Tbody className="mc-table-body even">
          {productsData?.map((item, index) => (
            <Tr key={index}>
              <Td title={index + 1}>
                <Box className="mc-table-check">
                {/* <Td title={ item.Name }>
                                <Box className="mc-table-profile">
                                    <Image src={ item.Image } alt={ item.alt } />
                                    <Text>{ item.Name }</Text>
                                </Box>
                            </Td> */}
                  {/* <Input 
                                        type="checkbox" 
                                        // name={item.name} 
                                        // checked={ item?.isChecked || false }
                                        onChange={ handleCheckbox } 
                                    /> */}
                  <Box className="mc-table-product md">
                    <Image
                      src={
                        item?.Image
                      }
                    />
                  </Box>
                </Box>
              </Td>
              <Td>
                <Box className="mc-table-product md">
                  <Box className="mc-table-group">
                    {item?.Name}
                    <Text>{""}</Text>
                  </Box>
                </Box>
              </Td>
              
              <Td>
                <Box className="mc-table-product md">
                  <Box className="mc-table-group">
                    {item?.email}
                    <Text>{""}</Text>
                  </Box>
                </Box>
              </Td>
              <Td>
                <Box className="mc-table-product md">
                  <Box className="mc-table-group">
                    {item?.phone}
                    <Text>{""}</Text>
                  </Box>
                </Box>
              </Td>
              <Td>
                <Box className="mc-table-product md">
                  <Box className="mc-table-group">
                    {item?.socialMedia?.instagram}
                    <Text>{""}</Text>
                  </Box>
                </Box>
              </Td>
              <Td>
                <Box className="mc-table-product md">
                  <Box className="mc-table-group">
                    {item?.socialMedia?.facebook}
                    <Text>{""}</Text>
                  </Box>
                </Box>
              </Td>
              <Td>
                <Box className="mc-table-product md">
                  <Box className="mc-table-group">
                    {item?.socialMedia?.youtube}
                    <Text>{""}</Text>
                  </Box>
                </Box>
              </Td>
              <Td title={ item?.isFeatured }>
                               <Text className={item.isFeatured  ? "mc-table-badge green" : "mc-table-badge red"}>{ item.isFeatured ? "Featured" : "Non Featured" }</Text> 
                                {/* { item.role === "pending" && <Text className="mc-table-badge purple">{ item.status }</Text> } 
                                 { item.Blocked === "blocked" && <Text className="mc-table-badge red">{ item.role }</Text> } */}
                            </Td>
              {/* <Td>
                                <Box className="mc-table-rating">
                                    <Icon>{ item.rating.icon }</Icon>
                                    <Heading>{ item.rating.percent }</Heading>
                                    <Text>({ item.rating.number })</Text>
                                </Box>
                            </Td>
                            <Td>{ 'item.order' }</Td>
                            <Td>{ 'item.sales' }</Td> */}
              <Td>
                <Box className="mc-table-action">
                  {/* <Anchor href={{ pathname: `/product-view?_id=${item?.productId}` }} title="View" className="material-icons view">visibility</Anchor> */}
                  {/* <Anchor href={"/product-view/:id".replace(":id", item.productId)} title="View" className="material-icons view">visibility</Anchor> */}
                  <Anchor
                    href={{ pathname: `/add-influencers`,search:`?_id=${item?._id}` }}
                    state={{ isInfluencerEdit: true }}
                    title="Edit"
                    className="material-icons edit"
                  >
                    edit
                  </Anchor>
                  <Anchor
                  href={""}
                    title="Enable/Disable"
                    className="material-icons view"
                    onClick={() => {
                      item?.isFeatured
                        ? getIdinModal(item?._id)
                        : handleChangeFeature(item?._id);
                    }}
                  >
                    {item?.isFeatured ? (
                      <ToggleOn style={{ color: "#000" }} />
                    ) : (
                      <ToggleOff style={{ color: "#85818b" }} />
                    )}
                  </Anchor>
                  {/* //  onClick={()=> getIdinModal(item.productId)} */}
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to change featured status?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setAlertModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => handleChangeFeature()}
            >
              yes
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}
