import React from 'react';

const Summary = ({ values,location }) => {
  return (
      <div className="summary-section">
        {/* <h5>Summary</h5> */} {values.method?.name === "discountCode" ? (values.method.discountCode ? <div><strong>{values.method.discountCode}</strong></div> :<div>No discount code yet</div> ) : (values.method.title ? <div><strong>{values.method.title}</strong></div> :<div>No title yet</div>)}
      
      <div>
        <strong>Type and method</strong>
        <ul>
          <li>{`${location?.state === "amountOffProducts" ? "Amount Of Products" : location?.state === "buyXgetY" ? "Buy X get Y" : location?.state === "amountOffOrder" ? "Amount Off Order" : "Free Shipping" }`}</li>
          <li>{values.method.name === "discountCode" ? 'Discount code' : 'Automatic'}</li>
        </ul>
      </div>
      <div>
        <strong>Details</strong>
        <ul>
          <><li>For HerbalCart Store</li>
            <li>{values?.minPurchaseRequirement?.name === "noMinReq" ? " No minimum requirements" : (values?.minPurchaseRequirements?.name === "minPurchaseAmt" ? `Minimum purchase amount ₹ ${values?.minPurchaseRequirements?.value}`: `Minimum purchase of ${values?.minPurchaseRequirements?.value} item`)}</li>
            {/* <li>{values?.customerEligibility === "All customers" ? "All customers" : (values?.customerEligibility === "Specific customer segments" ? `For ₹ ${values?.specificCustomerSegments}`: `For ${values?.specificCustomers} customers`)}</li> */}
            <li>{values.customerEligibility.name === 'allCustomers' ? "All Customers" : values.customerEligibility.name === 'specificCustomerSegment' ? "Specific Customer Segments" : values.customerEligibility.name === 'specificCustomer' ? "Specific Customer" : "-"}</li>
           {(values.maxDiscountUses.limitNumberOfTimesThisDiscountCanBeUsed.checked  || values.maxDiscountUses.limitToOneUsePerCustomer) &&  <li>{`Limit of ${values.maxDiscountUses.limitNumberOfTimesThisDiscountCanBeUsed.value} uses ${!values.maxDiscountUses.limitToOneUsePerCustomer ? "" :  ", one per customer"} `}</li>}</>
          {values?.combinations?.productDiscount ||
          values?.combinations?.orderDiscount ||
          values?.combinations?.shippingDiscount ? (
            <li>Can combine with other discounts</li>
          ) : (
            <li>Can't combine with other discounts</li>
          )}
        </ul>
      </div>
      <div>
        <strong>Performance</strong>
        <p>Discount is not active yet</p>
      </div>
    </div>
  );
};

export default Summary;
