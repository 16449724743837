import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CouponModal = ({show,setShow}) => {
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
//   return (
//    <>
//     <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#discountModal">
//         Select Discount Type
//     </button>

//     <div class="modal fade" id="discountModal">
//         <div class="modal-dialog modal-dialog-centered">
//             <div class="modal-content">
               
//                 <div class="modal-header">
//                     <h4 class="modal-title">Select discount type</h4>
//                     <button type="button" class="close" data-dismiss="modal">&times;</button>
//                 </div>

              
//                 <div class="modal-body">
//                     <div class="discount-type-btn" data-dismiss="modal">
//                         <div class="discount-text">
//                             <p><strong>Amount off products</strong></p>
//                             <p>Discount specific products or collections of products.</p>
//                         </div>
//                         <div class="discount-icon">&#128722;</div>
//                     </div>
//                     <div class="discount-type-btn" data-dismiss="modal">
//                         <div class="discount-text">
//                             <p><strong>Buy X get Y</strong></p>
//                             <p>Discount products based on a customer's purchase.</p>
//                         </div>
//                         <div class="discount-icon">&#128722;</div>
//                     </div>
//                     <div class="discount-type-btn" data-dismiss="modal">
//                         <div class="discount-text">
//                             <p><strong>Amount off order</strong></p>
//                             <p>Discount the total order amount.</p>
//                         </div>
//                         <div class="discount-icon">&#128179;</div>
//                     </div>
//                     <div class="discount-type-btn" data-dismiss="modal">
//                         <div class="discount-text">
//                             <p><strong>Free shipping</strong></p>
//                             <p>Offer free shipping on an order.</p>
//                         </div>
//                         <div class="discount-icon">&#128666;</div>
//                     </div>
//                 </div>

             
//                 <div class="modal-footer">
//                     <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
//                 </div>
//             </div>
//         </div>
//     </div>
//     </> )
return (
    <div className="App">
      {/* <Button variant="primary" onClick={handleShow}>
        Select Discount Type
      </Button> */}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select discount type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Link className="discount-type-btn" to={{pathname: `/create-coupon`, search:"?type=amountOffProducts"}} state={"amountOffProducts"}>
            <div className="discount-text">
              <p><strong>Amount off products</strong></p>
              <p>Discount specific products or collections of products.</p>
            </div>
            <div className="discount-icon">&#128722;</div>
          </Link>
          <Link className="discount-type-btn" to={{pathname: `/create-coupon`, search:"?type=amountOffOrder"}} state={"amountOffOrder"}>
            <div className="discount-text">
              <p><strong>Amount off order</strong></p>
              <p>Discount the total order amount.</p>
            </div>
            <div className="discount-icon">&#128179;</div>
          </Link>
          {/* <Link  className="discount-type-btn" 
          to={{pathname: `/create-coupon`, search:"?type=buyXgetY"}}
           state={"buyXgetY"}>
            <div  className="discount-text">
              <p><strong>Buy X get Y</strong></p>
              <p>Discount products based on a customer's purchase.</p>
            </div>
            <div className="discount-icon">&#128722;</div>
          </Link> */}
          {/* <Link className="discount-type-btn" 
          to={{pathname: `/create-coupon`, search:"?type=freeShipping"}}
           state={"freeShipping"}>
            <div className="discount-text">
              <p><strong>Free shipping</strong></p>
              <p>Offer free shipping on an order.</p>
            </div>
            <div className="discount-icon">&#128666;</div>
          </Link> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CouponModal;
