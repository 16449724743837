import { toastr } from "react-redux-toastr";
import {
  ADD_ADMIN_VIDEO_FAILURE,
  ADD_ADMIN_VIDEO_SUCCESS,
  ADMIN_VIDEO_API_END,
  ADMIN_VIDEO_API_REQUEST,
  DISABLE_ADMIN_VIDEOS_FAILURE,
  DISABLE_ADMIN_VIDEOS_SUCCESS,
  GET_ADMIN_SINGLE_VIDEO_FAILURE,
  GET_ADMIN_SINGLE_VIDEO_SUCCESS,
  GET_ADMIN_VIDEOS_FAILURE,
  GET_ADMIN_VIDEOS_SUCCESS,
  START_LOADER,
  STOP_LOADER,
  UPDATE_ADMIN_VIDEOS_FAILURE,
  UPDATE_ADMIN_VIDEOS_SUCCESS,
} from "../actionTypes";
import constant from "../../utils/constants";
import history from "../../utils/history";
import { hideLoader } from "./loader";
import { videoConstant } from "../../utils/videoConstants";
import { addVideo, disableAdminVideos, getAdminSingleVideos, getAdminVideos, updateAdminVideos } from "../../services/adminVideoServices";

const { VIDEO_API_TYPES } = videoConstant;
const { ERROR_MESSAGES } = constant;


//Add/Create Admin Videos
export const addVideoAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_VIDEO_API_REQUEST,
      payload: VIDEO_API_TYPES.ADD_VIDEO,
    });
    const response = await addVideo(param,token);
    toastr.success(response.message);
    dispatch({ type: ADD_ADMIN_VIDEO_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
    history.push("/videos-list");
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: ADD_ADMIN_VIDEO_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: ADMIN_VIDEO_API_END,
    });
  }
};

//Get Admin Videos List
export const getAdminVideosAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_VIDEO_API_REQUEST,
        payload: VIDEO_API_TYPES.GET_ADMIN_VIDEOS,
      });
      const response = await getAdminVideos(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_ADMIN_VIDEOS_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_VIDEOS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_VIDEO_API_END,
      });
    }
  };
};



//Disable Admin Videos
export const disableAdminVideosAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_VIDEO_API_REQUEST,
      payload: VIDEO_API_TYPES.CHANGE_VIDEO_STATUS,
    });
    const response = await disableAdminVideos(params,token);
    toastr.success(response.message);
    dispatch({
      type: DISABLE_ADMIN_VIDEOS_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: DISABLE_ADMIN_VIDEOS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_VIDEO_API_END,
    });
  }
};

//Update Admin Videos
export const updateAdminVideoAction = (param, formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_VIDEO_API_REQUEST,
      payload: VIDEO_API_TYPES.UPDATE_ADMIN_VIDEOS,
    });
    const response = await updateAdminVideos(param, formData,token);
    if (response) {
      toastr.success(response.message);
      dispatch({
        type: UPDATE_ADMIN_VIDEOS_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: STOP_LOADER });
      history.push("/videos-list");
    }
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: UPDATE_ADMIN_VIDEOS_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: ADMIN_VIDEO_API_END,
    });
  }
};

//Get Admin Single Video
export const getAdminVideoDetailAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    // dispatch( { type: START_LOADER } );
    dispatch({
      type: ADMIN_VIDEO_API_REQUEST,
      payload: VIDEO_API_TYPES.GET_ADMIN_VIDEOS_DETAIL,
    });
    const response = await getAdminSingleVideos(param,token);
    const newResponse = response.data;
    dispatch({
      type: GET_ADMIN_SINGLE_VIDEO_SUCCESS,
      payload: newResponse,
    });
    dispatch({ type: STOP_LOADER });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: GET_ADMIN_SINGLE_VIDEO_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_VIDEO_API_END,
    });
  }
};
