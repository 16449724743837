import { toastr } from "react-redux-toastr";
import { createHeroSection, getHeroProductsList } from "../../../services/CMS Services/heroSectionServices";
import { InfluencerConstants, heroConstant } from "../../../utils/CMSConstants";
import constant from "../../../utils/constants";
import { CHANGE_FETAURED_INFLUENCER_STATUS_FAILURE, CHANGE_FETAURED_INFLUENCER_STATUS_SUCCESS, CREATE_HERO_FAILURE, CREATE_HERO_SUCCESS, CREATE_INFLUENCER_FAILURE, CREATE_INFLUENCER_SUCCESS, GET_HERO_PRODUCT_LIST_FAILURE, GET_HERO_PRODUCT_LIST_SUCCESS, GET_INFLUENCERS_LIST_FAILURE, GET_INFLUENCERS_LIST_SUCCESS, GET_INFLUENCER_DETAIL_FAILURE, GET_INFLUENCER_DETAIL_SUCCESS, HERO_API_END, HERO_SECTION_API_REQUEST, INFLUENCER_API_END, INFLUENCER_API_REQUEST, START_LOADER, STOP_LOADER, UPDATE_INFLUENCERS_FAILURE, UPDATE_INFLUENCERS_SUCCESS } from "../../actionTypes";
import { hideLoader } from "../loader";
import history from "../../../utils/history";
import { addInfluencers, featuredInfluencersStatusChange, getInfluencers, getSingleInfluencers, updateInfluencers } from "../../../services/CMS Services/InfluencersServices";


const { INFLUENCER_API_TYPES } = InfluencerConstants;
const { ERROR_MESSAGES } = constant;

//Add/Create Influencers
export const createInfluencersAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: INFLUENCER_API_REQUEST,
      payload: INFLUENCER_API_TYPES.CREATE_INFLUENCER,
    });
    const response = await addInfluencers(param,token);
    toastr.success(response.message);
    dispatch({ type: CREATE_INFLUENCER_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
    // history.push("/influencer-list");
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: CREATE_INFLUENCER_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: INFLUENCER_API_END,
    });
  }
};

//Get Influencers List
export const getInfluencersListAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: INFLUENCER_API_REQUEST,
        payload: INFLUENCER_API_TYPES.GET_INFLUENCER_LIST,
      });
      const response = await getInfluencers(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_INFLUENCERS_LIST_SUCCESS,
        payload: { dataList, meta: dataList },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_INFLUENCERS_LIST_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: INFLUENCER_API_END,
      });
    }
  };
};


//Change Influencers Feature Status
export const changeFeatureInfluencerStatus = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: INFLUENCER_API_REQUEST,
      payload: INFLUENCER_API_TYPES.CHANGE_INFLUENCER_STATUS,
    });
    const response = await featuredInfluencersStatusChange(params,token);
    toastr.success(response.message);
    dispatch({
      type: CHANGE_FETAURED_INFLUENCER_STATUS_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: CHANGE_FETAURED_INFLUENCER_STATUS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: INFLUENCER_API_END,
    });
  }
};

//Update Influencers
export const updateInfluencersDetail = (param, formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: INFLUENCER_API_REQUEST,
      payload: INFLUENCER_API_TYPES.UPDATE_INFLUENCER,
    });
    const response = await updateInfluencers(param, formData,token);
    if (response) {
      toastr.success(response.message);
      dispatch({
        type: UPDATE_INFLUENCERS_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: STOP_LOADER });
      history.push("/influencers-list");
    }
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: UPDATE_INFLUENCERS_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: INFLUENCER_API_END,
    });
  }
};

//Get Influencers Detail
export const getInfluencerDetailAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    // dispatch( { type: START_LOADER } );
    dispatch({
      type: INFLUENCER_API_REQUEST,
      payload: INFLUENCER_API_TYPES.GET_SINGLE_INFLUENCER,
    });
    const response = await getSingleInfluencers(param,token);
    const newResponse = response.data;
    dispatch({
      type: GET_INFLUENCER_DETAIL_SUCCESS,
      payload: newResponse,
    });
    dispatch({ type: STOP_LOADER });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: GET_INFLUENCER_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: INFLUENCER_API_END,
    });
  }
};


// //Get Admin Blog Categories List
// export const getBlogCategories = (params) => {
//   return async (dispatch) => {
//     try {
//       // dispatch( showLoader() );
//       // dispatch({ type: START_LOADER });
//       dispatch({
//         type: ADMIN_BLOG_API_REQUEST,
//         payload: BLOG_API_TYPES.GET_ADMIN_BLOGS,
//       });
//       const response = await getBlogCategoriesList(params);
//       const dataList = response.data;
//       dispatch({
//         type: GET_ADMIN_BLOG_CATEGORIES_SUCCESS,
//         payload: { dataList, meta: dataList },
//       });
//       dispatch(hideLoader());
//     } catch (error) {
//       dispatch(hideLoader());
//       dispatch({
//         type: GET_ADMIN_BLOG_CATEGORIES_FAILURE,
//         payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
//       });
//       toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
//     } finally {
//       dispatch({
//         type: ADMIN_BLOG_API_END,
//       });
//     }
//   };
// };



