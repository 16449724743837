
import React, { useEffect, useState } from "react";
import { Row, Col, Anchor } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useQuery } from "../../hooks/useQuery";
import PageLayout from "../../layouts/PageLayout";
import { CardHeader, CardLayout } from "../../components/cards";
import { LabelField, LabelTextarea } from "../../components/fields";
import { Box } from "@mui/material";
import { Button } from "../../components/elements";
import { Breadcrumb } from "../../components";
import spinningLoaders from "./../../../src/loaderBlack.svg";

import { createOfferZone } from "../../store/actions/CMS actions/offerZone";
import StoreMapContainer from "../../components/MapContainer/StoreMapContainer";
import { addStoreLocatorAction, getSingleStoresLocatorListAction, getStoresByZipcodeAction, updateStoresAction } from "../../store/actions/adminStoreLocator";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { hideLoader, showLoader } from "../../store/actions/loader";
import PlaceSearch from "./placeSearch";


const AddStore = () => {
      const productId = useQuery();
      const [isEditEvent, setEditEventStatus] = useState(false);
      const dispatch = useDispatch();
      const location = useLocation();
      const [googlemap, setGoogleMap] = useState(null);
      const [lat, setLat] = useState(null);
      const [long, setLong] = useState(null);
      const [zip, setZip] = useState(null);
      const [suggestions, setSuggestions] = useState([]);
      const [selectedLocation, setSelectedLocation] = useState(null);

  const [search, setSearch] = useState("");
  const [showList, setShowList] = useState(true);
  const [searching, setSearching] = useState(false);

      const { storesZC } = useSelector((state) => ({
        storesZC: state.storeLocator.storeByZipCode.storesZC,
      }));

      const center = {
        lat: 37.7749, // Set your initial latitude
        lng: -122.4194, // Set your initial longitude
      };
      const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
      } = usePlacesAutocomplete();

      const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
        try {
          const results = await getGeocode({ address });
          const { lat, lng } = getLatLng(results[0]);
          setSelectedLocation({ lat, lng });
          // Update the map center or add a marker as needed
          // You can use state and props to manage the map state
        } catch (error) {
          console.error('Error selecting location:', error);
        }
      }
    
      const [storesData, setStoresData] = useState({
        Name: "",
        Contact: "",
        MoreDetails: "",
        location: {
            coordinates: [],
            formattedAddress: ""
        },
        zipcode: ""
    });




      useEffect(() => {
        if (location?.state?.isStoreEdit) {
          dispatch(showLoader());
          dispatch(getSingleStoresLocatorListAction(productId.get("_id"))).then((res) => {
            setLong(res?.data?.location?.coordinates[1])
            setLat(res?.data?.location?.coordinates[0])
            setEditEventStatus(true);
            setStoresData({...storesData , 
    Name: res?.data?.Name,
    Contact: res?.data?.Contact,
    MoreDetails: res?.data?.MoreDetails,
    location: {
        coordinates: [res?.data?.location?.coordinates[0],res?.data?.location?.coordinates[1]],
        formattedAddress: res?.data?.location?.formattedAddress
    },
    zipcode: res?.data?.zipcode
            })
          });
          dispatch(hideLoader());
        }
      }, [location?.state?.isStoreEdit]);
    

      const handleSubmit = (e) => {
        e.preventDefault()
        // dispatch(addStoreLocatorAction(storesData))
        setStoresData({...storesData ,
          Name: "",
    Contact: "",
    MoreDetails: "",
    location: {
        coordinates: [],
        formattedAddress: ""
    },
    zipcode: ""
  })
        isEditEvent
          ? dispatch(updateStoresAction(productId.get("_id"),storesData))
          : dispatch(addStoreLocatorAction(storesData))
      };
    
    
      // const handleSubmit = () => {
      // dispatch(createHeroAction(heroData))
      //   // isEditEvent
      //   //   ? dispatch(updateAdminBlogAction(productId.get("_id"), formData))
      //   //   : dispatch(addBlogAction(formData));
      // };

      const handleRecievingDataFromMap = (lat,long,formattedAddress,zipcode) => {
          setStoresData({...storesData , 
            zipcode : zipcode,
            location : {
              coordinates : [lat,long],
              formattedAddress : formattedAddress
            }
          })

      }
      const handleMapLoad = (map) => {
        setGoogleMap(map);
      };

      // useEffect(()=>{
      //   dispatch(getStoresByZipcodeAction({zipcode: zip}))
      // },[zip])
//       const handleZipCodeChange =  (value) => {
//         setSearchZipCode({...searchZipCode , zipcode : value})
      
// .then((res) => {
//   if(res?.statusCode=== 200) {
//   }
// })
         
     
// useEffect(() => {
//   const requestParams = Object.assign(
//       {
//         zipcode: search,
//       },
//     );
//   setSearching(true);
//   let id;
//   id = setTimeout(() => {
//       dispatch(getStoresByZipcodeAction(requestParams));
//   //   setFilteredData(filterBySearch(search, allProducts));
//     setSearching(false);
//   }, 500);

//   return () => {
//     clearTimeout(id);
//   };
// }, [search]);
  
      // const handleLocationSelect = async (placeId) => {
      //   dispatch(getStoresByZipcodeAction(placeId))
      //   try {
      //     const response = await fetch(
      //       `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=AIzaSyB0oGk1Jz3nzDw6L9DV9Y4kn0yCJw1J1i4`
      //     );
    
      //     if (!response.ok) {
      //       throw new Error('Failed to fetch location details');
      //     }
    
      //     const data = await response.json();
    
      //     if (data.result) {
      //       const location = data.result.geometry.location;
      //       setSelectedLocation(location);
      //     }
      //   } catch (error) {
      //     console.error('Error fetching location details:', error);
      //   }
      // };

      const changeHandler = (e) => {
        setSearch(e.target.value);
        if (!showList) setShowList(true);
      };
      const isSearch = true


      const handlePhoneInput = (e) => {
        const input = e.target.value;
    
        const cleanedValue = input.replace(/\D/g, '');
        
        // Limit to 10 digits
        const limitedValue = cleanedValue.slice(0, 10);
    
        setStoresData({...storesData,Contact: limitedValue});
      }
      return (
        <>
          <PageLayout>
            
              <Row>
                <Col xl={12}>
                  <CardLayout>
                    <Breadcrumb title={"Store(s) Locator"}>
                      
                    </Breadcrumb>
                  </CardLayout>
                </Col>

                <Col xl={12}>
                  <CardLayout>
                    <CardHeader title="Select New Store" />
                    {/* <Col xs={12} sm={6} md={4} lg={12} >
                  <LabelField
                    type={"text"}
                    label={"Search Location"}
                    name="search"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Search for a location"
                    labelDir="label-col"
                    fieldSize="w-100 h-md"
                  />

{storesZC?.length > 0 && (
        <ul>
          {storesZC.map((suggestion) => (
            <li key={suggestion._id} >
              {suggestion.MoreDetails}
            </li>
          ))}
        </ul>
      )}

{status === 'OK' && (
        <ul className="suggestions-container">
          {value ? (
            <li className="suggestion-item">
              <img src={spinningLoaders} alt="Searching..." />
            </li>
          ) : data.length ? (
            data.map((suggestion) => (
              <li key={suggestion._id} className="" onClick={() => handleSelect(suggestion.description)}>
                 <div
      className={`suggestion-div ${
        isSearch ? "div-list-hover" : ""
      }`}
    >
      <div className="div-suggestion-container-1">
        <div className=" div-suggestion-container-2">
          <div
            className={` bg-black/[0.075] ${
              isSearch ? "search-div-1 " : "search-div-2"
            } search-div-3`}
          >
            <img src={""} alt="" className="object-fit w-full" />
          </div>
          <div className="search-div-4">
          {suggestion.description}
          </div>
        </div>
        <div className="search-div-6">
          <span>₹0</span>
          <span className="search-div-7">
            ₹ 0
          </span>
        </div>
      </div>
    </div>
              </li>
            ))
          ) : (
            <li className="no-result">
              No result found
            </li>
          )}
        </ul>
      )}

{status === 'OK' && ready && (
            <ul>
              {data.map((suggestion) => (
                <li key={suggestion.id} onClick={() => handleSelect(suggestion.description)}>
                  {suggestion.description}
                </li>
              ))}
            </ul>
          )}
       {storesZC?.length > 0 && (
        <ul className="suggestions-container">
          {storesZC.map((suggestion) => (
            <li key={suggestion._id} className="suggestion-item" >
               {suggestion.MoreDetails}
            </li>
          ))}
        </ul>
      )}
                </Col> */}
                <PlaceSearch location={location?.state?.isStoreEdit} lat={lat} lng={long} storeData={storesData} recievingDataFromMap={handleRecievingDataFromMap} handleMapLoad={handleMapLoad} selectedLocation={selectedLocation}/>
                    <Box className="mc-product-upload-media1">
                      
                      <Box className="mc-product-upload-file">
                        
                        {/* <StoreMapContainer location={location?.state?.isStoreEdit} lat={lat} long={long} recievingDataFromMap={handleRecievingDataFromMap} handleMapLoad={handleMapLoad} selectedLocation={selectedLocation}/> */}
                      </Box>
                    </Box>
                 
                  </CardLayout>
                </Col>
                <form
            onSubmit= {handleSubmit}
              encType="multipart/form-data"
              novalidate
            >
                <Col xl={12}>
                  <CardLayout>
                  <Row>
                
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Location Name"
                      placeholder={"type Location name here..."}
                      name="Name"
                      fieldSize="w-100 h-md"
                      onChange={(e) => setStoresData({...storesData,Name: e.target.value})}
                      value={storesData?.Name}
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      name="Contact"
                      label="Contact No."
                      placeholder={"type contact number here..."}
                      fieldSize="w-100 h-md"
                      onChange={(e) => handlePhoneInput(e)}
                      value={storesData?.Contact}
                    />
                  </Col>
                
                  {/* <Col xl={6}>
                    <LabelField
                      type="number"
                      label="Location Latitude"
                      disabled
                      fieldSize="w-100 h-md"
                      value={storesData.location.coordinates[0]}
                    />
                  </Col>
                  
                  <Col xl={6}>
                    <LabelField
                      type="number"
                      label="Location Longitude"
                      disabled
                      fieldSize="w-100 h-md"
                      value={storesData.location.coordinates[1]}
                    />
                  </Col> */}
                  
                  {/* <Col xl={12}>
                    <LabelField
                      type="text"
                      placeholder={'Select from map'}
                      label="Formatted Address"
                      fieldSize="w-100 h-md"
                      value={storesData.location.formattedAddress}
                    />
                  </Col> */}
                 
                  <Col xl={12}>
                    <LabelTextarea
                      label="Location Description"
                      placeholder={"type Location description here..."}
                      name="ShortDescription"
                      fieldSize="w-100 h-text-md"
                      onChange={(e) => setStoresData({...storesData, MoreDetails: e.target.value})}
                      value={storesData?.MoreDetails}
                    />
                  </Col>
                  {/* <Col xl={12}>
                    <LabelField
                      placeholder={'Select from map'}
                      type="number"
                      name="MRP"
                      label="Zip Code."
                      fieldSize="w-100 h-md"
                      value={storesData.zipcode}
                    />
                  </Col>        */}
                </Row>
                    {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Add Store"
                        icon="chat"
                      />
                    )}
                  </CardLayout>
                </Col>
                <Col xl={5}></Col>
                </form>
              </Row>
           
          </PageLayout>
        </>
      );
    };
    

export default AddStore;