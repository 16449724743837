import { toastr } from "react-redux-toastr";
import {
  ADMIN_COUPON_API_END,
  ADMIN_COUPON_API_REQUEST,
  CREATE_ADMIN_COUPON_FAILURE,
  CREATE_ADMIN_COUPON_SUCCESS,
  DISABLE_ADMIN_COUPON_FAILURE,
  DISABLE_ADMIN_COUPON_SUCCESS,
  GET_ADMIN_COUPONS_FAILURE,
  GET_ADMIN_COUPONS_SUCCESS,
  GET_ADMIN_COUPON_DETAIL_FAILURE,
  GET_ADMIN_COUPON_DETAIL_SUCCESS,
  START_LOADER,
  STOP_LOADER,
  UPDATE_ADMIN_COUPON_FAILURE,
  UPDATE_ADMIN_COUPON_SUCCESS,
} from "../actionTypes";
import constant from "../../utils/constants";
import { couponConstant } from "../../utils/couponConstants";
import { createCoupon, disableAdminCoupons, getAdminCouponDetail, getAdminCoupons, updateAdminCoupons } from "../../services/adminCouponServices";
import history from "../../utils/history";

const { COUPON_API_TYPES } = couponConstant;
const { ERROR_MESSAGES } = constant;

//Add/Create Admin Coupon
export const createCouponAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_COUPON_API_REQUEST,
      payload: COUPON_API_TYPES.CREATE_COUPON,
    });
    const response = await createCoupon(param,token);
    toastr.success(response.message);
    dispatch({ type: CREATE_ADMIN_COUPON_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
    history.push("/coupon-list");
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: CREATE_ADMIN_COUPON_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: ADMIN_COUPON_API_END,
    });
  }
};

//Get Admin Coupon List
export const getAdminCouponsAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_COUPON_API_REQUEST,
        payload: COUPON_API_TYPES.GET_ADMIN_COUPONS,
      });
      const response = await getAdminCoupons(params,token);
      // const dataList = response.products.map(
      //   (item) => new ProductDetails(item)
      // );
      const dataList = response?.data;
      dispatch({
        type: GET_ADMIN_COUPONS_SUCCESS,
        payload: { dataList , meta: response },
      });
     dispatch({ type: STOP_LOADER });
    return response;
    } catch (error) {
      dispatch({ type: STOP_LOADER });
      dispatch({
        type: GET_ADMIN_COUPONS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_COUPON_API_END,
      });
    }
  };
};

//Disable Admin Coupons
export const disableAdminCouponAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_COUPON_API_REQUEST,
      payload: COUPON_API_TYPES.DISABLE_ADMIN_COUPONS,
    });
    const response = await disableAdminCoupons(params,token);
     toastr.success(response.message);
    dispatch({
      type: DISABLE_ADMIN_COUPON_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: DISABLE_ADMIN_COUPON_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_COUPON_API_END,
    });
  }
};

//Get Admin Single Coupon 
export const getAdminCouponDetailAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    // dispatch( { type: START_LOADER } );
    dispatch({
      type: ADMIN_COUPON_API_REQUEST,
      payload: COUPON_API_TYPES.GET_ADMIN_COUPON_DETAIL,
    });
    const response = await getAdminCouponDetail(param,token);
    const newResponse = response.data;
    dispatch({
      type: GET_ADMIN_COUPON_DETAIL_SUCCESS,
      payload: newResponse,
    });
    dispatch({ type: STOP_LOADER });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: GET_ADMIN_COUPON_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_COUPON_API_END,
    });
  }
};

//Update Admin Products
export const updateAdminCouponAction =
  (param, formData) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_COUPON_API_REQUEST,
        payload: COUPON_API_TYPES.UPDATE_ADMIN_COUPONS,
      });
      const response = await updateAdminCoupons(param, formData,token);
      if (response) {
        toastr.success(response.message);
        dispatch({
          type: UPDATE_ADMIN_COUPON_SUCCESS,
          payload: response.data,
        });
        dispatch({ type: STOP_LOADER });
        history.push("/coupon-list");
      }
    } catch (error) {
      const err = Object.values(error?.data?.error || {}) || "";
      dispatch({ type: STOP_LOADER });
      dispatch({ type: UPDATE_ADMIN_COUPON_FAILURE, payload: error });
      if (err[0]) {
        toastr.error(err[0]);
      } else {
        toastr.error(error.data.message);
      }
    } finally {
      dispatch({
        type: ADMIN_COUPON_API_END,
      });
    }
  };
