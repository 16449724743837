import { useLocation } from 'react-router-dom';
import history from '../utils/history';

const useAddQuery = (
) => {
  const location = useLocation();
  const addQuery = ( params, state = '', isExtraParam = true ) => {
    let { pathname } = location;
    if( isExtraParam ) {
      [ pathname ] = pathname.split( '?' );
    }
    history.push( {
      pathname,
      search: params,
      state,
    } );
  };

  return {
    addQuery
  };
};

export default useAddQuery;
