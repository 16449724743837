import { toastr } from "react-redux-toastr";
import {
  ADMIN_PRODUCT_API_END,
  ADMIN_USERS_API_REQUEST,
  ADMIN_USER_API_END,
  BLOCK_UNBLOCK_ADMIN_USERS_FAILURE,
  BLOCK_UNBLOCK_ADMIN_USERS_SUCCESS,
  GET_ADMIN_USERS_DETAIL_FAILURE,
  GET_ADMIN_USERS_DETAIL_SUCCESS,
  GET_ADMIN_USERS_FAILURE,
  GET_ADMIN_USERS_SUCCESS,
  START_LOADER,
  STOP_LOADER,
} from "../actionTypes";
import constant from "../../utils/constants";
import { hideLoader } from "./loader";
import { userConstant } from "../../utils/usersConstants";
import { blockAdminUsers, getAdminUserDetail, getAdminUsers } from "../../services/adminUsersServices";

const { USERS_API_TYPES } = userConstant;
const { ERROR_MESSAGES } = constant;
const token = localStorage.getItem("token");


//Get Admin Product List
export const getAdminUsersAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_USERS_API_REQUEST,
        payload: USERS_API_TYPES.GET_ADMIN_USERS,
      });
      const response = await getAdminUsers(params,token);
      const dataList = response.data
      dispatch({
        type: GET_ADMIN_USERS_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_USERS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_USER_API_END,
      });
    }
  };
};

//Get Admin Product Detail View
export const getAdminUsersDetailAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    // dispatch( { type: START_LOADER } );
    dispatch({
      type:  ADMIN_USERS_API_REQUEST,
      payload: USERS_API_TYPES.GET_USERS_DETAIL,
    });
    const response = await getAdminUserDetail(param,token);
    const newResponse = response.data;
    dispatch({
      type: GET_ADMIN_USERS_DETAIL_SUCCESS,
      payload: newResponse,
    });
    dispatch({ type: STOP_LOADER });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: GET_ADMIN_USERS_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_USER_API_END,
    });
  }
};


//Delete Admin Products
export const blockUnblockAdminUsersAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_USERS_API_REQUEST,
      payload: USERS_API_TYPES.GET_ADMIN_USERS_BLOCKED,
    });
    const response = await blockAdminUsers(params,token);
    dispatch({
      type: BLOCK_UNBLOCK_ADMIN_USERS_SUCCESS,
      payload: { ...response.data},
    });
    dispatch({ type: STOP_LOADER });
    toastr.success(response?.message)
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: BLOCK_UNBLOCK_ADMIN_USERS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_USER_API_END,
    });
  }
};

