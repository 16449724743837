import React from 'react';
import JoditEditor from "jodit-react";

const FormikJoditEditor = ({ field, form, ...props }) => {
    return (
      <JoditEditor
        value={field.value}
        onChange={value => form.setFieldValue(field.name, value)}
        {...props}
      />
    );
  };

export default FormikJoditEditor
