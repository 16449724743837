import { toastr } from "react-redux-toastr";
import { otherContentsConstants } from "../../../utils/CMSConstants";
import constant from "../../../utils/constants";
import { CONTENTS_API_END, CONTENTS_API_REQUEST, CREATE_UPDATE_CONTENT_FAILURE, CREATE_UPDATE_CONTENT_SUCCESS,  GET_CONTENTS_FAILURE, GET_CONTENTS_SUCCESS, START_LOADER, STOP_LOADER} from "../../actionTypes";
import history from "../../../utils/history";
import { hideLoader } from "../loader";
import { getContactsDetail } from "../../../services/CMS Services/contactUsServices";
import { createUpdateOtherContents, getOtherContentDetails } from "../../../services/CMS Services/otherContentServices";

const { CONTENT_API_TYPES } = otherContentsConstants;
const { ERROR_MESSAGES } = constant;


//Add/Create/Update Other Contents
export const createUpdateOtherContentsAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: CONTENTS_API_REQUEST,
        payload: CONTENT_API_TYPES.CREATE_UPDATE_OTHER_CONTENTS,
      });
      const response = await createUpdateOtherContents(param,token);
      toastr.success(response.message);
      dispatch({ type: CREATE_UPDATE_CONTENT_SUCCESS, payload: response.data });
      dispatch({ type: STOP_LOADER });
      history.push("/contents");
    } catch (error) {
      const err = Object.values(error?.data?.error || {}) || "    ";
      dispatch({ type: STOP_LOADER });
      dispatch({ type: CREATE_UPDATE_CONTENT_FAILURE, payload: error });
      if (err[0]) {
        toastr.error(err[0]);
      } else {
        toastr.error(error.data.message);
      }
    } finally {
      dispatch({
        type: CONTENTS_API_END,
      });
    }
  };
  
  //Get Other Contents
  export const getOtherContentsAction = (params) => {
    const token = localStorage.getItem("token");
    return async (dispatch) => {
      try {
        // dispatch( showLoader() );
        // dispatch({ type: START_LOADER });
        dispatch({
          type: CONTENTS_API_REQUEST,
          payload: CONTENT_API_TYPES.GET_OTHER_CONTENTS,
        });
        const response = await getOtherContentDetails(params,token);
        const dataList = response.data;
        dispatch({
          type: GET_CONTENTS_SUCCESS,
          payload: { dataList, meta: dataList },
        });
        dispatch(hideLoader());
        return response;
      } catch (error) {
        dispatch(hideLoader());
        dispatch({
          type: GET_CONTENTS_FAILURE,
          payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
        });
        toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
      } finally {
        dispatch({
          type: CONTENTS_API_END,
        });
      }
    };
  };

 
