
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "../../../hooks/useQuery";
import PageLayout from "../../../layouts/PageLayout";
import { CardLayout } from "../../../components/cards";
import { LabelTextarea } from "../../../components/fields";
import { Button } from "../../../components/elements";
import { Breadcrumb } from "../../../components";
import {createFAQsAction, getFAQsDetailAction, updateFAQsDetail } from "../../../store/actions/CMS actions/FAQs";
import { hideLoader, showLoader } from "../../../store/actions/loader";
import { createUpdateContactAction, getContactsListAction } from "../../../store/actions/CMS actions/contactUs";
import { createUpdateOtherContentsAction, getOtherContentsAction } from "../../../store/actions/CMS actions/otherContent";


const CreateOtherContents = () => {
      const productId = useQuery();
      const [isEditEvent, setEditEventStatus] = useState(false);
      const dispatch = useDispatch();
      const location = useLocation();
    
      const [faqData, setFAQData] = useState({
        privacyPolicy: "",
        returnPolicy: "",
        aboutUs: "",
        termsAndConditions: "",
      });
    
      useEffect(() => {
        if (location?.state?.isContentEdit) {
          dispatch(showLoader());
          dispatch(getOtherContentsAction()).then((res) => {
            setEditEventStatus(true);
            setFAQData({
                privacyPolicy: res?.data?.privacyPolicy,
                returnPolicy: res?.data?.returnPolicy,
                aboutUs: res?.data?.aboutUs,
                termsAndConditions: res?.data?.termsAndConditions,
            })
          });
          dispatch(hideLoader());
        }
      }, [location?.state?.isContentEdit]);
    
    
      const handleSubmit = () => {
       dispatch(createUpdateOtherContentsAction(faqData))
      };
    
      const formik = useFormik({
        initialValues: faqData,
        onSubmit: handleSubmit,
      });

    
      return (
        <>
          <PageLayout>
            <form
              onSubmit={formik.handleSubmit}
              encType="multipart/form-data"
              novalidate
            >
              <Row>
                <Col xl={12}>
                  <CardLayout>
                    <Breadcrumb title={"Privacy & Policy(s)"}>
                    </Breadcrumb>
                  </CardLayout>
                </Col>
                <Col xl={7}>
                  <CardLayout>
                    <Row>
                      <Col xl={12}>
                      <LabelTextarea
                          label="Privacy Policy"
                          name="privacyPolicy"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            privacyPolicy: e.target.value})}
                          value={faqData.privacyPolicy}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Return Policy"
                          name="returnPolicy"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            returnPolicy: e.target.value})}
                          value={faqData.returnPolicy}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="About Us"
                          name="aboutUs"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            aboutUs: e.target.value})}
                          value={faqData.aboutUs}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Terms & Conditions"
                          name="termsAndConditions"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            termsAndConditions: e.target.value})}
                          value={faqData.termsAndConditions}
                        />
                      </Col>
                    </Row>
                  </CardLayout>
                </Col>
                <Col xl={5}></Col>
                <Col xl={12}>
                  {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Submit"
                        icon="chat"
                      />
                    )}
                </Col>
              </Row>
            </form>
          </PageLayout>
        </>
      );
    };
    

export default CreateOtherContents;