import { toastr } from "react-redux-toastr";
import { createHeroSection, getHeroProductsList } from "../../../services/CMS Services/heroSectionServices";
import { featuredConstants } from "../../../utils/CMSConstants";
import constant from "../../../utils/constants";
import { CHANGE_FETAURED_BLOG_STATUS_FAILURE, CHANGE_FETAURED_BLOG_STATUS_SUCCESS, CHANGE_FETAURED_STATUS_FAILURE, CHANGE_FETAURED_STATUS_SUCCESS, CREATE_HERO_FAILURE, CREATE_HERO_SUCCESS, FEATURED_API_END, FEATURED_BLOGS_API_REQUEST, FEATURED_PRODUCTS_API_REQUEST, GET_FEATURED_BLOGS_LIST_FAILURE, GET_FEATURED_BLOGS_LIST_SUCCESS, GET_FEATURED_PRODUCT_LIST_FAILURE, GET_FEATURED_PRODUCT_LIST_SUCCESS, GET_HERO_PRODUCT_LIST_FAILURE, GET_HERO_PRODUCT_LIST_SUCCESS, HERO_API_END, HERO_SECTION_API_REQUEST, START_LOADER, STOP_LOADER } from "../../actionTypes";
import { hideLoader } from "../loader";
import history from "../../../utils/history";
import { getFeaturedProducts, statusFeaturedProducts } from "../../../services/CMS Services/featuredProductsServices";
import { getFeaturedBlogs, statusFeaturedBlogs } from "../../../services/CMS Services/featuredBlogsServices";


const { FEATURED_API_TYPES } = featuredConstants;
const { ERROR_MESSAGES } = constant;

//Get Featured Blogs
export const getFeaturedBlogListAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: FEATURED_BLOGS_API_REQUEST,
      payload: FEATURED_API_TYPES.GET_FEATURED_BLOGS_LIST,
    });
    const response = await getFeaturedBlogs(param,token);
    toastr.success(response.message);
    dispatch({ type: GET_FEATURED_BLOGS_LIST_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
    // history.push("/hero-product-list");s
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: GET_FEATURED_BLOGS_LIST_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: FEATURED_API_END,
    });
  }
};

//Change Featured Blogs Status
export const changeBlogsStatusAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: FEATURED_BLOGS_API_REQUEST,
        payload: FEATURED_API_TYPES.CHANGE_FEATURED_BLOGS_STATUS,
      });
      const response = await statusFeaturedBlogs(params,token);
      toastr.success(response.message);
      dispatch({
        type: CHANGE_FETAURED_BLOG_STATUS_SUCCESS,
        payload: { ...response.data},
      });
      dispatch({ type: STOP_LOADER });
    } catch (error) {
      dispatch({ type: STOP_LOADER });
      dispatch({
        type: CHANGE_FETAURED_BLOG_STATUS_FAILURE,
        payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: FEATURED_API_END,
      });
    }
  };