import { toastr } from "react-redux-toastr";

// Function to fetch and convert images
async function fetchAndConvertImages(urls) {
    const fileList = [];
    for (const url of urls) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], url.split('/').pop(), { type: blob.type });
        fileList.push(file);
      } catch (error) {
        toastr.error(`Error fetching or converting image from ${url}:`, error);
      }
    }
  
    // Create a FileList object from the array of File objects
    const fileListObject = new DataTransfer();
    fileList.forEach(file => {
      fileListObject.items.add(file);
    });
    return fileListObject.files;
  }

  /**
 * concatenating all the attributes with delimiter
 */
export const concatStrings = ( object, delimiter ) => object?.map( value => value.value ).join( delimiter );


export default fetchAndConvertImages;