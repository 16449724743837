import React, { useState } from "react";
import { Box, Form, Heading, Button, Anchor, Image, Text } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import showPwdImg from "../../show-password.svg";
import hidePwdImg from "../../hide-password.svg";
import { useDispatch } from "react-redux";
import { adminLoginAuth } from "../../store/actions/auth";

export default function Login() {
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [login, setLogin] = useState({
        email: "",
        password: "",
      });
    const dispatch = useDispatch();

 const handleSubmit = (e) => {
  e.preventDefault();
dispatch(adminLoginAuth(login))
      }

    return (
        <Box className="mc-auth">
            <Image
                src={ data?.pattern.src } 
                alt={ data?.pattern.alt }
                className="mc-auth-pattern"  
            />
            <Box className="mc-auth-group">
                <Logo 
                    src = { data?.logo.src }
                    alt = { data?.logo.alt }
                    href = { data?.logo.path }
                    className = "mc-auth-logo"
                />
                <Heading as="h4" className="mc-auth-title">{ data?.title }</Heading>
                <Form className="mc-auth-form" onSubmit={handleSubmit}>
              <div>
                <div>
                  <IconField
                    required
                    autoComplete="off"
                    type="text"
                    icon="email"
                    placeholder="Enter email/phone number"
                    value={login.email}
                    onChange={(e) =>
                      setLogin({ ...login, email: e.target.value })
                    }
                  />
                  <div style={{ position: " relative" }}>
                    <IconField
                      required
                      autoComplete="off"
                      icon="lock"
                      placeholder="Enter password"
                      type={isRevealPwd ? "text" : "password"}
                      value={login.password}
                      onChange={(e) =>
                        setLogin({ ...login, password: e.target.value })
                      }
                    />
                    <img
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        position: "absolute",
                        right: "-4px",
                        top: "12px",
                      }}
                      alt="img"
                      className="hideAndShow"
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                  </div>
                </div>
                <Button
                  className={`mc-auth-btn ${data?.button.fieldSize}`}
                  type="submit"
                >
                  {data?.button.text}
                </Button>

                {/* <Anchor className="mc-auth-forgot" href={data?.forgot.path}>
                  {data?.forgot.text}
                </Anchor> */}
              </div>
            </Form>
                {/* <Box className="mc-auth-navigate">
                    <Text as="span">{ data?.navigate.title }</Text>
                    <Anchor href={ data?.navigate.path }>{ data?.navigate.text }</Anchor>
                </Box> */}
            </Box>
        </Box>
    );
}