import { toastr } from "react-redux-toastr";
import { changeOfferStatus, createOfferBanner, getOffersBannerList } from "../../../services/CMS Services/offerZoneServices";
import { heroConstant, offerZoneConstants } from "../../../utils/CMSConstants";
import constant from "../../../utils/constants";
import { CHANGE_OFFER_STATUS_FAILURE, CHANGE_OFFER_STATUS_SUCCESS, CREATE_OFFER_ZONE_FAILUTRE, CREATE_OFFER_ZONE_SUCCESS, GET_OFFER_BANNER_LIST_FAILURE, GET_OFFER_BANNER_LIST_SUCCESS, HERO_SECTION_API_REQUEST, OFFER_API_END, OFFER_ZONE_API_REQUEST, START_LOADER, STOP_LOADER } from "../../actionTypes";
import history from "../../../utils/history";
import { hideLoader } from "../loader";

const { OFFER_API_TYPES } = offerZoneConstants;
const { ERROR_MESSAGES } = constant;

//Add/Create Offer Banners
export const createOfferZone = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");

    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: OFFER_ZONE_API_REQUEST,
        payload: OFFER_API_TYPES.CREATE_OFFER_BANNER,
      });
      const response = await createOfferBanner(param,token);
      toastr.success(response.message);
      dispatch({ type: CREATE_OFFER_ZONE_SUCCESS, payload: response.data });
      dispatch({ type: STOP_LOADER });
      history.push("/offer-banner-list");
    } catch (error) {
      const err = Object.values(error?.data?.error || {}) || "    ";
      dispatch({ type: STOP_LOADER });
      dispatch({ type: CREATE_OFFER_ZONE_FAILUTRE, payload: error });
      if (err[0]) {
        toastr.error(err[0]);
      } else {
        toastr.error(error.data.message);
      }
    } finally {
      dispatch({
        type: OFFER_API_END,
      });
    }
  };
  
  //Get Hero Product List
  export const getOfferZoneBannersList = (params) => {
    const token = localStorage.getItem("token");

    return async (dispatch) => {
      try {
        // dispatch( showLoader() );
        // dispatch({ type: START_LOADER });
        dispatch({
          type: OFFER_ZONE_API_REQUEST,
          payload: OFFER_API_TYPES.GET_OFFER_BANNER_LIST,
        });
        const response = await getOffersBannerList(params,token);
        const dataList = response.data;
        dispatch({
          type: GET_OFFER_BANNER_LIST_SUCCESS,
          payload: { dataList, meta: dataList },
        });
        dispatch(hideLoader());
      } catch (error) {
        dispatch(hideLoader());
        dispatch({
          type: GET_OFFER_BANNER_LIST_FAILURE,
          payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
        });
        toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
      } finally {
        dispatch({
          type: OFFER_API_END,
        });
      }
    };
  };

  //Disable Offer Banners
export const disableOffersBannerAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: OFFER_ZONE_API_REQUEST,
      payload: OFFER_API_TYPES.CHANGE_OFFER_STATUS,
    });
    const response = await changeOfferStatus(params,token);
    toastr.success(response.message);
    dispatch({
      type: CHANGE_OFFER_STATUS_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: CHANGE_OFFER_STATUS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: OFFER_API_END,
    });
  }
};