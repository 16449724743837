import { API_URL } from "../utils/apiPaths";
import { request } from "./api";



export function createCoupon(params,token) {
  return request('POST', API_URL.CREATE_COUPON, params,token);
}

export function getAdminCoupons(params,token) {
  return request('GET', API_URL.GET_ADMIN_COUPONS, params,token);
}

export function updateAdminCoupons(params, formData,token) {
  return request('PUT', API_URL.UPDATE_COUPON.replace(':id', params), formData,token);
}

export function getAdminCouponDetail(params,token) {
  return request('GET', `${API_URL.GET_COUPON}/${params}`,"",token);
}

export function disableAdminCoupons (params,token) {
  return request('PUT', `${API_URL.COUPON_STATUS}/${params}`, params,token);
}

export const adminCouponServices = {
  createCoupon,
  getAdminCoupons,
  updateAdminCoupons,
  getAdminCouponDetail,
  disableAdminCoupons
};
