import { toastr } from "react-redux-toastr";
import {
  ADD_ADMIN_BLOG_FAILURE,
  ADD_ADMIN_BLOG_SUCCESS,
  ADMIN_BLOG_API_END,
  ADMIN_BLOG_API_REQUEST,
  DISABLE_ADMIN_BLOG_SUCCESS,
  DISABLE_ADMIN_COUPON_FAILURE,
  GET_ADMIN_BLOGS_FAILURE,
  GET_ADMIN_BLOGS_SUCCESS,
  GET_ADMIN_BLOG_CATEGORIES_FAILURE,
  GET_ADMIN_BLOG_CATEGORIES_SUCCESS,
  GET_ADMIN_BLOG_DETAIL_FAILURE,
  GET_ADMIN_BLOG_DETAIL_SUCCESS,
  START_LOADER,
  STOP_LOADER,
  UPDATE_ADMIN_BLOG_FAILURE,
  UPDATE_ADMIN_BLOG_SUCCESS,
} from "../actionTypes";
import constant from "../../utils/constants";
import history from "../../utils/history";
import { hideLoader } from "./loader";
import { blogConstant } from "../../utils/blogConstants";
import {
  addBlog,
  disableAdminBlogs,
  getAdminBlogDetail,
  getAdminBlogs,
  getBlogCategoriesList,
  updateAdminBlogs,
} from "../../services/adminBlogServices";

const { BLOG_API_TYPES } = blogConstant;
const { ERROR_MESSAGES } = constant;



//Add/Create Admin Blogs
export const addBlogAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_BLOG_API_REQUEST,
      payload: BLOG_API_TYPES.CREATE_BLOG,
    });
    const response = await addBlog(param,token);
    toastr.success(response.message);
    dispatch({ type: ADD_ADMIN_BLOG_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
    // history.push("/blog-list");
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: ADD_ADMIN_BLOG_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: ADMIN_BLOG_API_END,
    });
  }
};

//Get Admin Blogs List
export const getAdminBlogsAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_BLOG_API_REQUEST,
        payload: BLOG_API_TYPES.GET_ADMIN_BLOGS,
      });
      const response = await getAdminBlogs(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_ADMIN_BLOGS_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_BLOGS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_BLOG_API_END,
      });
    }
  };
};

//Get Admin Blog Categories List
export const getBlogCategories = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_BLOG_API_REQUEST,
        payload: BLOG_API_TYPES.GET_ADMIN_BLOGS,
      });
      const response = await getBlogCategoriesList(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_ADMIN_BLOG_CATEGORIES_SUCCESS,
        payload: { dataList, meta: dataList },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_BLOG_CATEGORIES_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_BLOG_API_END,
      });
    }
  };
};

//Disable Admin Blogs
export const disableAdminBlogsAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_BLOG_API_REQUEST,
      payload: BLOG_API_TYPES.CHANGE_BLOG_STATUS,
    });
    const response = await disableAdminBlogs(params,token);
    toastr.success(response.message);
    dispatch({
      type: DISABLE_ADMIN_BLOG_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: DISABLE_ADMIN_COUPON_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_BLOG_API_END,
    });
  }
};

//Update Admin Blogs
export const updateAdminBlogAction = (param, formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_BLOG_API_REQUEST,
      payload: BLOG_API_TYPES.UPDATE_ADMIN_BLOGS,
    });
    const response = await updateAdminBlogs(param, formData,token);
    if (response) {
      toastr.success(response.message);
      dispatch({
        type: UPDATE_ADMIN_BLOG_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: STOP_LOADER });
      history.push("/blog-list");
    }
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: UPDATE_ADMIN_BLOG_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: ADMIN_BLOG_API_END,
    });
  }
};

//Get Admin Blog Detail
export const getAdminBlogDetailAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    // dispatch( { type: START_LOADER } );
    dispatch({
      type: ADMIN_BLOG_API_REQUEST,
      payload: BLOG_API_TYPES.GET_ADMIN_BLOGS_DETAIL,
    });
    const response = await getAdminBlogDetail(param,token);
    const newResponse = response.data;
    dispatch({
      type: GET_ADMIN_BLOG_DETAIL_SUCCESS,
      payload: newResponse,
    });
    dispatch({ type: STOP_LOADER });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: GET_ADMIN_BLOG_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_BLOG_API_END,
    });
  }
};
