import { API_URL } from "../utils/apiPaths";
import { request } from "./api";


export function addVideo(params,token) {
  return request('POST', API_URL.ADD_VIDEO, params,token);
}

export function getAdminVideos(params,token) {
  return request('GET', API_URL.GET_ALL_VIDEOS, params,token);
}

export function disableAdminVideos(params,token) {
  return request('PUT', `${API_URL.CHANGE_VIDEOS_STATUS}/${params}`, params,token);
}

export function updateAdminVideos(params, formData,token) {
  return request('PUT', API_URL.UPDATE_VIDEO.replace(':id', params), formData,token);
}

export function getAdminSingleVideos(params,token) {
  return request('GET', `${API_URL.GET_SINGLE_VIDEOS}/${params}`, "",token);
}



export const adminVideoServices = {
    addVideo,
    getAdminVideos,
    disableAdminVideos,
    updateAdminVideos,
    getAdminSingleVideos
};
