import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  Anchor,
  Button,
  Image,
  Input,
  Label,
  Icon,
  Text,
} from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/productUpload.json";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { hideLoader, showLoader } from "../../store/actions/loader";
import { addVideoAction, getAdminVideoDetailAction, updateAdminVideoAction } from "../../store/actions/adminVideos";

const AddVideos = () => {
  const productId = useQuery();
  const [isEditEvent, setEditEventStatus] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [videoData, setVideoData] = useState({
    title: "",
    description: "",
    embeddedLink: "",
  });

  useEffect(() => {
    if (location?.state?.isVideoEdit) {
      dispatch(showLoader());
      dispatch(getAdminVideoDetailAction(productId.get("_id"))).then((res) => {
        setEditEventStatus(true);
        setVideoData({
          title: res?.data?.title,
          description: res?.data?.description,
          embeddedLink: res?.data?.embeddedLink })
      })
     
     
      dispatch(hideLoader());
    }
  }, [location?.state?.isVideoEdit]);

  const handleSubmit = () => {
    isEditEvent
      ? dispatch(updateAdminVideoAction(productId.get("_id"), videoData))
      : dispatch(addVideoAction(videoData));
  };

  const formik = useFormik({
    initialValues: videoData,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <PageLayout>
        <form
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
          novalidate
        >
          <Row>
            <Col xl={12}>
              <CardLayout>
                <Breadcrumb title={"Add Video"}>
                  {/* {data?.breadcrumb.map((item, index) => (
                    <li key={index} className="mc-breadcrumb-item">
                      {item.path ? (
                        <Anchor className="mc-breadcrumb-link" href={item.path}>
                          {item.text}
                        </Anchor>
                      ) : (
                        item.text
                      )}
                    </li>
                  ))} */}
                </Breadcrumb>
              </CardLayout>
            </Col>
            <Col xl={14}>
              <CardLayout>
                <Row>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Video Title"
                      name="title"
                      fieldSize="w-100 h-md"
                      onChange={(e) => setVideoData({...videoData,title: e.target.value})}
                      value={videoData.title}
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelTextarea
                      label="Video Description"
                      name="description"
                      fieldSize="w-100 h-text-md"
                      onChange={(e) => setVideoData({...videoData,description: e.target.value})}
                      value={videoData.description}
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Video Link"
                      name="embeddedLink"
                      fieldSize="w-100 h-md"
                      onChange={(e) => setVideoData({...videoData,embeddedLink: e.target.value})}
                      value={videoData.embeddedLink}
                    />
                  </Col>
                </Row>
                {isEditEvent ? (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Update"
                    icon="discount"
                  />
                ) : (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Add"
                    icon="discount"
                  />
                )}
              </CardLayout>
            </Col>
            <Col xl={5}></Col>
          </Row>
        </form>
      </PageLayout>
    </>
  );
};

export default AddVideos;
