import { API_URL } from "../utils/apiPaths";
import { request } from "./api";


export function addProduct(params,token) {
  return request('POST', API_URL.ADD_PRODUCT, params,token);
}

export function getAdminProducts(params,token) {
  return request('GET', API_URL.GET_ADMIN_PRODUCTS, params,token);
}

export function getAdminAllProducts(params,token) {
  return request('GET', API_URL.GET_ADMIN_ALL_PRODUCTS, params,token);
}

export function updateAdminProduct(params, formData,token) {
  return request('PATCH', API_URL.UPDATE_PRODUCT.replace(':id', params), formData,token);
}

export function getAdminProductDetail(params,token) {
  return request('GET', `${API_URL.GET_PRODUCT}/${params}`,"",token);
}

export function disableAdminProducts(params,token) {
  return request('PUT', `${API_URL.DELETE_PRODUCT}/${params}`, params,token);
}

export function getAdminParentProducts(params,token) {
  return request('GET', API_URL.GET_PARENT_PRODUCT, params,token);
}

export function getAdminVariantProducts(params,token) {
  return request('GET', `${API_URL.GET_VARIANTS_PRODUCT}/${params}`,"",token);
}

export function getDropdownCategoryList(params,token) {
  return request('GET', API_URL.GET_DROPDOWN_CATEGORIES_LIST, params,token);
}

export function getDropdownProductList(params,token) {
  return request('GET', `${API_URL.GET_DROPDOWN_PRODUCTS_LIST}/${params}`,"",token);
}



export const adminEventServices = {
  addProduct,
  getAdminProducts,
  updateAdminProduct,
  getAdminProductDetail
};
