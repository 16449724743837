import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Anchor, Heading, Box, Text, Image, Icon, Button, Input } from "../elements";
import { useDispatch } from "react-redux";
import { disableAdminProductAction } from "../../store/actions/adminProduct";
import { ToggleOff, ToggleOn } from "@mui/icons-material";
import TableHeaderRow from "../shared/TableHeaderRow";
import { changeFeaturedStatusAction } from "../../store/actions/CMS actions/featuredProducts";

export default function ProductsTable({ thead, tbody, products, memoizedHandleClick,memoizedTableConfig }) {

    const [alertModal, setAlertModal] = useState(false);
    const [data, setData] = useState([]);
    const [productId, setProductId] = useState();
    const [productsData, setProductsData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => { setData(tbody); setProductsData(products) }, [tbody, products]);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;

        if (name === "allCheck") {
            const checkData = data?.map((item) => {
                return { ...item, isChecked: checked };
            });
            setData(checkData);
        }
        else {
            const checkData = data?.map((item) =>
                item.name === name ? { ...item, isChecked: checked } : item
            );
            setData(checkData);
        }
    }

    const handleDisableProduct = (productIds) => {
        dispatch(disableAdminProductAction(productId || productIds))
    }

    const handleFeaturedProduct = (productIds) => {
        dispatch(changeFeaturedStatusAction(productId || productIds))
    }

    const getIdinModal = (key) => {
        setAlertModal(true)
        setProductId(key)
    }

    return (
        <Box className="mc-table-responsive">
            <Table className="mc-table product">
                <Thead className="mc-table-head primary">
                    <Tr>
                        {/* <Th>
                            <Box className="mc-table-check">
                                <Input
                                    type="checkbox" 
                                    name="allCheck"
                                    checked={ data?.filter((item)=> item.isChecked !== true).length < 1 } 
                                    onChange={ handleCheckbox } 
                                />
                                <Text>Image</Text>
                            </Box>
                        </Th> */}
                        <TableHeaderRow 
                        configData={memoizedTableConfig}
                        onDesOrder={memoizedHandleClick}
                        onAscOrder={memoizedHandleClick}
                        />
                        {/* {thead.map((item, index) => (
                            <Th key={index}>{item}</Th>
                        ))} */}
                    </Tr>
                </Thead>
                {/* <Tbody className="mc-table-body even">
                    {(data || productsData)?.map((item, index) => (
                        <Tr key={ index }> 
                            <Td title={ index + 1 }>
                                <Box className="mc-table-check">
                                    <Input 
                                        type="checkbox" 
                                        name={item.name} 
                                        checked={ item?.isChecked || false }
                                        onChange={ handleCheckbox } 
                                    />
                                    <Text>#{ item.productId }</Text>
                                </Box>
                            </Td>
                            <Td>
                                <Box className="mc-table-product md">
                                    <Image src={ item.src } alt={ item.alt } />
                                    <Box className="mc-table-group">
                                        <Heading as="h6">{ item.heading }</Heading>
                                        <Text>{ item.descrip }</Text>
                                    </Box>
                                </Box>
                            </Td>
                            <Td>{ item.category }</Td>
                            <Td>{ item.brand }</Td>
                            <Td>
                                <Box className="mc-table-price">
                                    <del>{ item.price.previous }</del>
                                    <Text>{ item.price.present }</Text>
                                </Box>
                            </Td>
                            <Td>{ item.stock }</Td>
                            <Td>
                                <Box className="mc-table-rating">
                                    <Icon>{ item.rating.icon }</Icon>
                                    <Heading>{ item.rating.percent }</Heading>
                                    <Text>({ item.rating.number })</Text>
                                </Box>
                            </Td>
                            <Td>{ item.order }</Td>
                            <Td>{ item.sales }</Td>
                            <Td>
                                <Box className="mc-table-action">
                                    <Anchor href="/product-view" title="View" className="material-icons view">{ item.action.view }</Anchor>
                                    <Anchor href="/product-upload" title="Edit" className="material-icons edit">{ item.action.edit }</Anchor>
                                    <Button title="Delete" className="material-icons delete" onClick={()=> setAlertModal(true)}>{ item.action.delete }</Button>
                                </Box>
                            </Td>
                        </Tr>
                    ))}
                </Tbody> */}
                <Tbody className="mc-table-body even">
                    {(productsData)?.map((item, index) => (
                        <Tr key={index}>
                            <Td title={index + 1}>
                                <Box className="mc-table-check">
                                    {/* <Input 
                                        type="checkbox" 
                                        // name={item.name} 
                                        // checked={ item?.isChecked || false }
                                        onChange={ handleCheckbox } 
                                    /> */}
                                    <Box className="mc-table-product md">
                                        <Image src={item?.productIcon} />
                                    </Box>
                                </Box>
                            </Td>
                            <Td>
                                <Box className="mc-table-product md">
                                    <Box className="mc-table-group">
                                    <Anchor href={{ pathname: `/product-view`, search:`?_id=${item?.productId}` }}>
                                        <Heading as="h6">{item?.productName}</Heading>
                                        <Text>{""}</Text></Anchor>
                                    </Box>
                                </Box>
                            </Td>
                            <Td>{item?.sKUs}</Td>
                            <Td>{item?.stock}</Td>
                            <Td>
                                <Box className="mc-table-price">
                                    <del>{item?.MRP}</del>
                                    <Text>{item?.discountedPrice}</Text>
                                </Box>
                            </Td>
                            <Td title={ item.isFeaturedProduct }>
                               <Text className={item.isFeaturedProduct ? "mc-table-badge green" : "mc-table-badge red"}>{ item.isFeaturedProduct ? "Featured" : "Non Featured" }</Text> 
                                {/* { item.role === "pending" && <Text className="mc-table-badge purple">{ item.status }</Text> } */}
                                {/* { item.Blocked === "blocked" && <Text className="mc-table-badge red">{ item.role }</Text> } */}
                            </Td>
                            {/* <Td>
                                <Box className="mc-table-rating">
                                    <Icon>{ item.rating.icon }</Icon>
                                    <Heading>{ item.rating.percent }</Heading>
                                    <Text>({ item.rating.number })</Text>
                                </Box>
                            </Td>
                            <Td>{ 'item.order' }</Td>
                            <Td>{ 'item.sales' }</Td> */}
                            <Td>
                                <Box className="mc-table-action">
                                    <Anchor href={{ pathname: `/product-view`, search:`?_id=${item?.productId}` }} title="View" className="material-icons view">visibility</Anchor>
                                    {/* <Anchor href={"/product-view/:id".replace(":id", item.productId)} title="View" className="material-icons view">visibility</Anchor> */}
                                    <Anchor href={{ pathname: `/product-upload`, search:`?_id=${item?.productId}` }} state={{ isEdit: true }} title="Edit" className="material-icons edit">edit</Anchor>
                                    <Anchor
                                    href={""}
                                        title="Enable/Disable"
                                        className="material-icons view"
                                        onClick={() => {
                                            item?.Active ? getIdinModal(item?.productId) : handleDisableProduct(item?.productId);
                                        }}
                                    >
                                        {item?.Active ? (
                                            <ToggleOn style={{ color: "#000" }} />
                                        ) : (
                                            <ToggleOff style={{ color: "#85818b" }} />
                                        )}
                                    </Anchor>
                                    <Anchor
                                    href={""}
                                        title="Featured/Non-Featured"
                                        className="material-icons view"
                                        onClick={() => {
                                            handleFeaturedProduct(item?.productId);
                                        }}
                                    >
                                        {item?.isFeaturedProduct ? (
                                            <ToggleOn style={{ color: "#000" }} />
                                        ) : (
                                            <ToggleOff style={{ color: "#85818b" }} />
                                        )}
                                    </Anchor>
                                    {/* //  onClick={()=> getIdinModal(item.productId)} */}
                                </Box>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            <Modal show={alertModal} onHide={() => setAlertModal(false)}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">are your sure!</Heading>
                    <Text as="p">Want to disable this product?</Text>
                    <Modal.Footer>
                        <Button type="button" className="btn btn-secondary" onClick={() => setAlertModal(false)}>nop, close</Button>
                        <Button type="button" className="btn btn-danger" onClick={() => handleDisableProduct()}>yes, disable</Button>
                    </Modal.Footer>
                </Box>
            </Modal>
        </Box>
    );
}
