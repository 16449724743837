import { API_URL } from "../../utils/apiPaths";
import { request } from "../api";

export function createOfferBanner(params,token) {
    return request('POST', API_URL.CREATE_OFFER_BANNER, params,token);
  }
  
export function getOffersBannerList(params,token) {
    return request('GET', API_URL.GET_OFFER_ZONE, params,token);
  }
  
export function changeOfferStatus(params,token) {
    return request('PUT', `${API_URL.BANNER_STATUS}/${params}`, params,token);
  }