import { ADMIN_CPASSWORD_FAILURE, ADMIN_CPASSWORD_SUCCESS, ADMIN_LOGIN_FAILURE, ADMIN_LOGIN_SUCCESS } from "../actionTypes";
  
  const user = localStorage.getItem("token");
  
  const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
  
  const adminAuth = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
    
      case ADMIN_LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: action.payload,
        };
      case ADMIN_LOGIN_FAILURE:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
        case ADMIN_CPASSWORD_SUCCESS:
          return {
            ...state,
            isLoggedIn: true,
            user: payload,
          };
        case ADMIN_CPASSWORD_FAILURE:
          return {
            ...state,
            isLoggedIn: true,
            user: null,
          };
    //   case LOGOUT:
    //     return {
    //       ...state,
    //       isLoggedIn: false,
    //       user: null,
    //     };
      default:
        return state;
    }
  }

  export { adminAuth };
  