import { connect, useSelector } from "react-redux";
import { Box, Image, Heading } from "../components/elements";
import PulseLoader from "react-spinners/PulseLoader";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

// export const LoaderContext = createContext();

export  const LoaderProvider = ({children,props}) => {
    const [loadingData, setLoadingData] = useState(false);
    const loading = useSelector (state => state.loader.loading);

    useEffect(() => {
        setLoadingData(true);
        const id = setTimeout(() => {
          setLoadingData(false);
        }, 1000);
    
        return () => {
          clearTimeout(id);
        };
      }, []);
    // const { loading } = props;
    return (
        <>
            {  loading ? 
                <Box className="mc-spinner">
                    <Image src="//herbalcart.in/cdn/shop/files/Favicon_32x32.png?v=1656918574" alt="logo" />
                    <Box className="mc-spinner-group">
                        <Heading>Loading</Heading>
                        <PulseLoader 
                            color="#0857f5"
                            loading={ loading} 
                            size={8} 
                        /> 
                    </Box>
                </Box>
                : 
                (
                   children
                  )
            }
        </>
    )

    
}
// function mapStateToProps( state ) {
//     return {
//       loader: state.loader,
//     };
//   }
// LoaderProvider.propTypes = {
//     loader: PropTypes.object,
//   };
// export default withRouter( connect( mapStateToProps )( LoaderProvider ) );