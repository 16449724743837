import React, { useCallback, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout, FloatCard } from "../../components/cards";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/productList.json";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LoaderProvider } from "../../context/Preloader";
import ListPagination from "../../components/ListPagination";
import useForm from "../../hooks/useForm";
import { debounce } from "lodash";
import { useSetUrlParams } from "../../hooks/useSetUrlParams";
import useAddQuery from "../../hooks/useAddQuery";
import {
   adminNewsletterTableConfigs,
} from "../../utils/tableConstants";
import { getNewsletterSubscriberAction } from "../../store/actions/adminNewsletter";
import NewsletterTable from "../../components/tables/NewsletterTable";

export default function NewsletterSubscriberList() {
  let [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const paramsQuery = new URLSearchParams(window.location.search);
  const { subscriber, loading, meta } = useSelector((state) => ({
    subscriber: state.adminNewsletter.adminNewsletter?.subscriber,
    loading: state.loader.loading,
    meta: state.adminNewsletter.adminNewsletter?.meta,
  }));

  function getUrlParameter(key, col) {
    let paramsQuerys;
    if (key === "search" || key === "sortColumn" || key === "sortOrder") {
      paramsQuerys = paramsQuery.get(key) === "" ? col : paramsQuery.get(key);
    }
    return paramsQuerys;
  }

  const { values, handleChange } = useForm(
    {
      searchDummyText: getUrlParameter("search", ""),
      search: getUrlParameter("search", ""),
      sortBy: getUrlParameter("sortColumn", ""),
      sortOrder: getUrlParameter("sortOrder", "ASC"),
      page: 1,
      publishedOn:
        paramsQuery.get("publishedOn") === null ||
        paramsQuery.get("publishedOn") === ""
          ? false
          : paramsQuery.get("publishedOn"),
    },
    {}
  );

  useEffect(() => {
    const requestParams = Object.assign(
      {
        name: values.name,
        perPage: 10,
        page: values.page,
      },
      values.search && { search: values.search },
      values.sortBy && { sortBy: values.sortBy }
    );
    dispatch(getNewsletterSubscriberAction(requestParams));
  }, [
    values.sortOrder,
    values.name,
    values.page,
    values.sortBy,
    values.search,
  ]);


  const count = Math.ceil(meta?.totalCount / 10);

  
  const handlePageChange = (e, p) => {
    setPage(p);
    const requestParams = Object.assign({
      page: p,
    });
    dispatch(getNewsletterSubscriberAction(requestParams));
  };
  const debouncedSave = useCallback(
    debounce((nextValue) => handleChange("search", nextValue), 1000),
    []
  );

  const searchHandle = (e) => {
    const { value: nextValue } = e.target;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    addQuery(useSetUrlParams("search", nextValue));
    handleChange("searchDummyText", nextValue);
    debouncedSave(nextValue);
    handleChange("page", 1);
  };

  const handleSorting = (sortBy, sortingType) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    addQuery(useSetUrlParams("sortColumn", sortBy));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    addQuery(useSetUrlParams("sortOrder", sortingType));
    handleChange("name", sortingType);
    handleChange("sortOrder", sortingType);
    handleChange("page", 1);
  };
  const memoizedTableConfig = useMemo(() => adminNewsletterTableConfigs, []);
  const memoizedHandleClick = useCallback(
    (sortBy, orderType) => handleSorting(sortBy, orderType),
    []
  );

  const handleClear = () => {
    handleChange("searchDummyText", "");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    addQuery(useSetUrlParams("search", ""));
    debouncedSave("");
  };
  return (
    <PageLayout>
      {loading ? (
        <LoaderProvider />
      ) : (
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Newsletter(s) Subscriber"}>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={3}>
                        <FloatCard
                            variant = { "lg yellow" }
                            digit = {meta?.totalCount ? meta?.totalCount : "0"}
                            title = { "total subscriber" }
                            icon = { "bookmark" }
                        />
                    </Col>
          <Col xl={12}>
            <CardLayout>
              <Row>
                <Col xl={12}>
                  {subscriber?.length ? (
                    <>
                      {" "}
                      <NewsletterTable
                        memoizedTableConfig={memoizedTableConfig}
                        memoizedHandleClick={memoizedHandleClick}
                        thead={data?.product.thead}
                        tbody={data?.product.tbody}
                        products={subscriber}
                      />
                      <ListPagination
                        products={subscriber}
                        totalCount={meta?.totalCount}
                        pageCount={count}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </>
                  ) : (
                    <div
                      className="no-record"
                      style={{
                        fontSize: "26px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "200px",
                        margin: "0 auto",
                      }}
                    >
                      No Result Found
                    </div>
                  )}
                  {/* <Pagination products={meta?.totalCount}/> */}
                </Col>
              </Row>
            </CardLayout>
          </Col>
        </Row>
      )}
    </PageLayout>
  );
}
