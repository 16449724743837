import constant from "../../utils/constants";
import {
  ADD_ADMIN_PRODUCT_FAILURE,
  ADD_ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_API_END,
  ADMIN_PRODUCT_API_REQUEST,
  DELETE_ADMIN_PRODUCT_SUCCESS,
  GET_ADMIN_DROPDOWN_CATEGORIES_SUCCESS,
  GET_ADMIN_DROPDOWN_PRODUCTS_SUCCESS,
  GET_ADMIN_PARENT_PRODUCTS_SUCCESS,
  GET_ADMIN_PRODUCTS_DETAIL_FAILURE,
  GET_ADMIN_PRODUCTS_DETAIL_SUCCESS,
  GET_ADMIN_PRODUCTS_DROPDOWN_FAILURE,
  GET_ADMIN_PRODUCTS_DROPDOWN_SUCCESS,
  GET_ADMIN_PRODUCTS_FAILURE,
  GET_ADMIN_PRODUCTS_SUCCESS,
  GET_ADMIN_VARIANT_PRODUCTS_SUCCESS,
  UPDATE_ADMIN_PRODUCT_FAILURE,
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminProducts: { products: [], meta: "" },
  adminDropdownProducts: { products: [], meta: "" },
};

const adminProduct = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case ADMIN_PRODUCT_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case ADMIN_PRODUCT_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADD_ADMIN_PRODUCT_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case ADD_ADMIN_PRODUCT_FAILURE:
    case GET_ADMIN_PRODUCTS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminProducts: { products: [], meta: "" },
      };
    case GET_ADMIN_PRODUCTS_SUCCESS:
      return {
        ...state,
        adminProducts: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          products: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
      case GET_ADMIN_PRODUCTS_DROPDOWN_FAILURE:
        return {
          ...state,
          status: API_STATUS.FAILURE,
          adminDropdownProducts: { products: [], meta: "" },
        };
      case GET_ADMIN_PRODUCTS_DROPDOWN_SUCCESS:
        return {
          ...state,
          adminDropdownProducts: {
            // products:
            //   action.payload.page === 1
            //     ? action.payload.dataList
            //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
            products: action.payload.dataList,
            meta: action.payload.meta,
            loading: false,
          },
          status: API_STATUS.SUCCESS,
        };
      case GET_ADMIN_PARENT_PRODUCTS_SUCCESS:
        return {
          ...state,
          adminParentProducts: {
            // products:
            //   action.payload.page === 1
            //     ? action.payload.dataList
            //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
            parentProducts: action.payload.dataList,
            meta: action.payload.meta,
            loading: false,
          },
          status: API_STATUS.SUCCESS,
        };
        case GET_ADMIN_VARIANT_PRODUCTS_SUCCESS:
          return {
            ...state,
            adminVariantProducts: {
              // products:
              //   action.payload.page === 1
              //     ? action.payload.dataList
              //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
              variantProducts: action.payload.dataList,
              meta: action.payload.meta,
              loading: false,
            },
            status: API_STATUS.SUCCESS,
          };
          case GET_ADMIN_DROPDOWN_CATEGORIES_SUCCESS:
        return {
          ...state,
          adminCategories: {
            // products:
            //   action.payload.page === 1
            //     ? action.payload.dataList
            //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
            dropdownCategories: action.payload.dataList,
            meta: action.payload.meta,
            loading: false,
          },
          status: API_STATUS.SUCCESS,
        };
        case GET_ADMIN_DROPDOWN_PRODUCTS_SUCCESS:
          return {
            ...state,
            adminProducts: {
              // products:
              //   action.payload.page === 1
              //     ? action.payload.dataList
              //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
              dropdownProducts: action.payload.dataList,
              meta: action.payload.meta,
              loading: false,
            },
            status: API_STATUS.SUCCESS,
          };
    case GET_ADMIN_PRODUCTS_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminProductDetail: action.payload,
        loading: false,
      };
    case GET_ADMIN_PRODUCTS_DETAIL_FAILURE:
    case UPDATE_ADMIN_PRODUCT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case DELETE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminProducts: {
          ...state.adminProducts,
          products: state.adminProducts.products.filter(
            (product) => product.productId !== action.payload.productId
          ),
        },
      };

    default:
      return state;
  }
};

export { adminProduct };
