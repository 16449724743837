import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Image, Input, Text, Box, Icon, Button, Heading, Anchor, Select, Option } from "../elements";
import { useDispatch } from "react-redux";
import TableHeaderRow from "../shared/TableHeaderRow";
import { disableAdminCouponAction } from "../../store/actions/adminCoupon";
import { changeOrderStatusAction, confirmOrderAction } from "../../store/actions/adminOrders";
import { LabelField } from "../fields";
import { changeOrderStatus } from "../../services/adminOrderServices";
import moment from "moment";

export default function UserOrdersListTable({ thead, tbody,products, memoizedHandleClick,memoizedTableConfig  }) {
    const [alertModal, setAlertModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [data, setData] = useState([]);
    const [orderId, setOrderId] = useState();
    const [productsData, setProductsData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => { setData(tbody); setProductsData(products) }, [tbody, products]);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;

        if (name === "allCheck") {
            const checkData = data?.map((item) => {
                return { ...item, isChecked: checked };
            });
            setData(checkData);
        }
        else {
            const checkData = data?.map((item) =>
                item.name === name ? { ...item, isChecked: checked } : item
            );
            setData(checkData);
        }
    }

    const handleDisableOrder = (orderIds) => {
        dispatch(confirmOrderAction(orderId || orderIds))
    }

    const handleChangeOrderStatus = (orderIds) => {
        dispatch(changeOrderStatusAction(orderId || orderIds,{status: selectedStatus.value}))
    }

    const getIdinModal = (key) => {
        setAlertModal(true)
        setOrderId(key)
    }

    const getIdinStatusModal = (key) => {
        setStatusModal(true)
        setOrderId(key)
    }

    const handleSelectStatus = (data, setFieldValue) => {
        // const productSize = data.map((el) => {
        //   return el.value;
        // });
        setSelectedStatus(data);
      };

    return (
        <Box className="mc-table-responsive">
            <Table className="mc-table">
                <Thead className="mc-table-head primary">
                    <Tr>
                        {/* <Th>
                            <Box className="mc-table-check">
                                <Input 
                                    type="checkbox" 
                                    name="allCheck"
                                    checked={ data?.filter((item)=> item.isChecked !== true).length < 1 } 
                                    onChange={ handleCheckbox } 
                                />
                                <Text>Order Id#</Text>
                            </Box>
                        </Th>
                        {thead.map((item, index) => (
                            <Th key={ index }>{ item }</Th>
                        ))} */}

<TableHeaderRow
                        configData={memoizedTableConfig}
                        onDesOrder={memoizedHandleClick}
                        onAscOrder={memoizedHandleClick}
                        />
                    </Tr>
                </Thead>
                <Tbody className="mc-table-body even">
                    {productsData?.map((item, index) => (
                        <Tr key={ index }> 

                       
                            <Td>
                                <Box className="mc-table-check">
                                    {/* <Input 
                                        type="checkbox" 
                                        name={item.name} 
                                        checked={ item?.isChecked || false }
                                        onChange={ handleCheckbox } 
                                    /> */}
                                    <Text>{ item.OrderId }</Text>
                                </Box>
                            </Td>
                            {/* <Td>
                                <Box className="mc-table-profile">
                                    <Image src={ item.src } alt={ item.alt } />
                                    <Text>{ item.name }</Text>
                                </Box>
                            </Td> */}
                           <Td>{item.user?.name ? item.user?.name : 'Guest' }</Td>
                            <Td> ({item.items?.length} item)</Td>
                            <Td>₹{ item?.payableAmount.toFixed(2) }</Td>
                            <Td>{ item?.transaction.gateway }</Td>
                            <Td> <Box className="mc-table-action"><Text className={`mc-table-badge ${item.status === "processing" ?  "yellow" : item.status === "delivered" ? "green" : item.status === "cancelled" ? "red" : "blue" }`}>{ item.status }</Text> 
                            {/* {item.status === "processing" ? <Anchor href={""} title="Confirm Order" className="material-icons view" onClick={() => {
                                             getIdinModal(item?._id)
                                        }}>{ "done" }</Anchor> : <Anchor href={""} title="Change Status" className="material-icons view" onClick={() => {
                                             getIdinStatusModal(item?._id)
                                        }}>{ "cached" }</Anchor>} */}
                                        </Box></Td>
                            <Td>{moment(item?.updatedAt).format('lll')}</Td>
                            <Td>
                                <Box className="mc-table-action">
                                    <Anchor href={{ pathname: `/invoice-details`, search:`?_id=${item?._id}` }} title="View" className="material-icons view">{ "visibility" }</Anchor>
                                    {/* <Anchor title="Download" href="#" className="material-icons download" download>{ "download" }</Anchor> */}
                                    {/* {item.status === "processing" && <Button title="Delete" className="material-icons view" onClick={() => {
                                             getIdinModal(item?._id)
                                        }}>{ "done" }</Button>  }  */}
                                </Box>
                            </Td>
                            
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Modal show={ alertModal } onHide={()=> setAlertModal(false)}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">are your sure!</Heading>
                    <Text as="p">Want to Accept this order?</Text>
                    <Modal.Footer>
                        <Button type="button" className="btn btn-secondary" onClick={()=> setAlertModal(false)}>nop, close</Button>
                        <Button type="button" className="btn btn-danger" onClick={()=> handleDisableOrder()}>yes, Confirm</Button>
                    </Modal.Footer>
                </Box>
            </Modal>

            <Modal show={ statusModal } onHide={()=> setStatusModal(false)}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">Change Status Of this Order</Heading>
                    {/* <Text as="p">Want to Change the Status this order?</Text> */}
                    <LabelField
                                        label = { "status by" }
                                        onChange={(e) => handleSelectStatus(e)}
                      value={selectedStatus}
                                        options = {[
                                            { value: "pending", label: "pending" },
                                            { value: "shipped", label: "shipped" },
                                            { value: "delivered", label: "delivered" },
                                            { value: "cancelled", label: "cancelled" },
                                            { value: "returned", label: "returned" }]}
                                        
                                        type="select"
                                        labelDir = "label-col"
                                        fieldSize = "w-100 h-md"
                                    /> 
                    <Modal.Footer>
                        <Button type="button" className="btn btn-secondary" onClick={()=> setStatusModal(false)}>nop, close</Button>
                        <Button type="button" className="btn btn-danger" onClick={()=> handleChangeOrderStatus()}>yes, Change</Button>
                    </Modal.Footer>
                </Box>
            </Modal>
        </Box>
    )
}