export const blogConstant = {
    BLOG_API_TYPES: {
      CREATE_BLOG: 'CREATE_BLOG',
      GET_ADMIN_BLOGS: 'GET_ADMIN_BLOGS',
      CHANGE_BLOG_STATUS : 'CHANGE_BLOG_STATUS',
      DELETE_ADMIN_BLOGS: 'DELETE_ADMIN_BLOGS',
      UPDATE_ADMIN_BLOGS: 'UPDATE_ADMIN_BLOGS',
      GET_ADMIN_BLOGS_DETAIL: 'GET_ADMIN_BLOGS_DETAIL',
    }
  }
