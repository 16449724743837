import constant from "../../utils/constants";
import { ADD_STORE_FAILURE, ADD_STORE_SUCCESS, GET_SINGLE_STORES_LIST_FAILURE, GET_SINGLE_STORES_LIST_SUCCESS, GET_STORES_LIST_BY_ZIPCODE_FAILURE, GET_STORES_LIST_BY_ZIPCODE_SUCCESS, GET_STORES_LIST_FAILURE, GET_STORES_LIST_SUCCESS, STORE_LOCATOR_API_END, STORE_LOCATOR_API_REQUEST, UPDATE_STORE_FAILURE, UPDATE_STORE_SUCCESS } from "../actionTypes";


const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  storeLocator: { stores: [], meta: "" },
  storeByZipCode: { storesZC: [], meta: "" },
};

const storeLocator = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case STORE_LOCATOR_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case STORE_LOCATOR_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADD_STORE_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case ADD_STORE_FAILURE:
    case GET_STORES_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        storeLocator: { stores: [], meta: "" },
      };
    case GET_STORES_LIST_SUCCESS:
      return {
        ...state,
        storeLocator: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          stores: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
      case GET_SINGLE_STORES_LIST_SUCCESS:
      return {
        ...state,
        storeLocator: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          singleStores: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
      case GET_SINGLE_STORES_LIST_FAILURE:
      case UPDATE_STORE_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
        };
     case UPDATE_STORE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case GET_STORES_LIST_BY_ZIPCODE_FAILURE:
        return {
          ...state,
          status: API_STATUS.FAILURE,
          storeByZipCode: { storesZC: [], meta: "" },
        };
    case GET_STORES_LIST_BY_ZIPCODE_SUCCESS:
        return {
          ...state,
          storeByZipCode: {
            storesZC: action.payload,
            meta: action.payload.meta,
            loading: false,
          },
          status: API_STATUS.SUCCESS,
        };

    default:
      return state;
  }
};

export { storeLocator };
