
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "../../../hooks/useQuery";
import PageLayout from "../../../layouts/PageLayout";
import { CardLayout } from "../../../components/cards";
import { LabelTextarea } from "../../../components/fields";
import { Button } from "../../../components/elements";
import { Breadcrumb } from "../../../components";
import {createFAQsAction, getFAQsDetailAction, updateFAQsDetail } from "../../../store/actions/CMS actions/FAQs";
import { hideLoader, showLoader } from "../../../store/actions/loader";
import { faqValidationsSchema } from "../../../utils/formValidations";


const CreateFAQs = () => {
      const productId = useQuery();
      const [isEditEvent, setEditEventStatus] = useState(false);
      const dispatch = useDispatch();
      const location = useLocation();
    
      const [faqData, setFAQData] = useState({
        question: "",
        answer: "",
      });
    
      useEffect(() => {
        if (location?.state?.isFAQEdit) {
          dispatch(showLoader());
          dispatch(getFAQsDetailAction(productId.get("_id"))).then((res) => {
          
            setEditEventStatus(true);
            setFAQData({
              question: res?.data?.question,
              answer: res?.data?.answer
            })
          });
          dispatch(hideLoader());
        }
      }, [location?.state?.isFAQEdit]);
    
    
      const handleSubmit = () => {
        isEditEvent
          ? dispatch(updateFAQsDetail(productId.get("_id"), faqData))
          : dispatch(createFAQsAction(faqData));
      };
    
      const formik = useFormik({
        initialValues: faqData,
        onSubmit: handleSubmit,
        // validationSchema: faqValidationsSchema
      });

    
      return (
        <>
          <PageLayout>
            <form
              onSubmit={formik.handleSubmit}
            >
              <Row>
                <Col xl={12}>
                  <CardLayout>
                    <Breadcrumb title={"Add FAQ(s)"}>
                    </Breadcrumb>
                  </CardLayout>
                </Col>
                <Col xl={14}>
                  <CardLayout>
                    <Row>
                      <Col xl={12}>
                      <LabelTextarea
                          label="Question"
                          name="question"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            question: e.target.value})}
                          value={faqData.question}
                          required={true}
                        />
                      </Col>
                      <Col xl={12}>
                        <LabelTextarea
                          label="Answer"
                          name="answer"
                          fieldSize="w-100 h-text-md"
                          onChange={(e) => setFAQData({...faqData,
                            answer: e.target.value})}
                          value={faqData.answer}
                          required={true}
                        />
                      </Col>
                    </Row>
                  </CardLayout>
                </Col>
                <Col xl={5}></Col>
                <Col xl={12}>
                  {isEditEvent ? (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Update"
                        icon="cloud_upload"
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="mc-btn w-100 primary mt-5"
                        text="Create FAQs"
                        icon="chat"
                      />
                    )}
                </Col>
              </Row>
            </form>
          </PageLayout>
        </>
      );
    };
    

export default CreateFAQs;