import { API_URL } from "../../utils/apiPaths";
import { request } from "../api";

export function createFAQ(params,token) {
    return request('POST', API_URL.CREATE_FAQ, params,token);
  }
  
  export function getFAQList(params,token) {
    return request('GET', API_URL.GET_FAQ, params,token);
  }
  
  export function updateFAQs(params, formData,token) {
    return request('PUT', API_URL.UPDATE_FAQ.replace(':id', params), formData,token);
  }
  
  export function getFAQDetail(params,token) {
    return request('GET', `${API_URL.GET_FAQ_DETAIL}/${params}`,"",token);
  }