import { useLoadScript } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';

const libraries = ['places'];
const mapContainerStyle = {
    height: '630px',
    width: '100%',
};
const center = {
    lat: 28.60699,
    lng:77.36322,
};

const PlaceSearch = ({lat,lng ,stores,recievingDataFromMap,selectedLocation,handleMapLoad,storeData,location }) => {
    const [map, setMap] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB0oGk1Jz3nzDw6L9DV9Y4kn0yCJw1J1i4',
    libraries,
  });

  // useEffect(() => {
  //   if (!isLoaded) return;

  //   const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
  //     center,
  //     zoom: 13,
  //     mapTypeId: 'roadmap',
  //   });

  //   const input = document.getElementById('pac-input');
  //   const searchBox = new window.google.maps.places.SearchBox(input);
  //   mapInstance.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

  //   mapInstance.addListener('bounds_changed', () => {
  //     searchBox.setBounds(mapInstance.getBounds());
  //   });
  //   const markers = [];
  //   searchBox.addListener('places_changed', () => {
  //     const places = searchBox.getPlaces();

  //     if (places?.length === 0) {
  //       return;
  //     }

  //     markers.forEach(marker => marker.setMap(null));
  //     markers.length = 0;

  //     const bounds = new window.google.maps.LatLngBounds();

  //     places.forEach(place => {
  //       if (!place.geometry || !place.geometry.location) {
  //         console.log('Returned place contains no geometry');
  //         return;
  //       }

  //       mapInstance.setCenter(place.geometry.location);

  //       const icon = {
  //         url: place.icon,
  //         size: new window.google.maps.Size(71, 71),
  //         origin: new window.google.maps.Point(0, 0),
  //         anchor: new window.google.maps.Point(17, 34),
  //         scaledSize: new window.google.maps.Size(25, 25),
  //       };

  //       markers.push(
  //         new window.google.maps.Marker({
  //           map,
  //           icon,
  //           title: place.name,
  //           position: place.geometry.location,
  //         })
  //       );

  //       if (place.geometry.viewport) {
  //         bounds.union(place.geometry.viewport);
  //       } else {
  //         bounds.extend(place.geometry.location);
  //       }
  //     });
  //     map.fitBounds(bounds);

      
  //   });


  //   mapInstance.addListener('click', (event) => {
  //     const latLng = {
  //       lat: event.latLng.lat(),
  //       lng: event.latLng.lng(),
  //     };
  //     const geocoder = new window.google.maps.Geocoder();

  //     geocoder.geocode({ location: latLng }, (results, status) => {
  //       if (status === 'OK' && results.length > 0) {
  //         const formattedAddress = results[0].formatted_address;
  //         const postalCode = results[0].address_components.find(component => component.types.includes('postal_code'))?.long_name || '';
          
  //       //   // Set marker position
  //       //   if (marker) {
  //       //     marker.setPosition(latLng);
  //       //   } else {
  //       //     const newMarker = new window.google.maps.Marker({
  //       //       position: latLng,
  //       //       map: mapInstance,
  //       //       title: formattedAddress,
  //       //     });
  //       //     setMarker(newMarker);
  //       //   }

  //         // Now you have the latitude, longitude, formatted address, and postal code
  //         recievingDataFromMap(latLng.lat,latLng.lng,formattedAddress,postalCode)
  //       //   console.log('Latitude:', latLng.lat);
  //       //   console.log('Longitude:', latLng.lng);
  //       //   console.log('Formatted Address:', formattedAddress);
  //       //   console.log('Postal Code:', postalCode);
  //       } else {
  //         toastr.error('Geocoder failed due to: Some Error Occured', status);
  //       }
  //     });
  //   });

  //   setMap(mapInstance);
    
  // }, [isLoaded]);
  

  useEffect(() => {
    if (!isLoaded) return;

    const map = new window.google.maps.Map(document.getElementById('map'), {
      center,
      zoom: 13,
      mapTypeId: 'roadmap',
    });

 

    const input = document.getElementById('pac-input');
    const searchBox = new window.google.maps.places.SearchBox(input);
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds());
    });

    const markers = [];

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      markers.forEach(marker => marker.setMap(null));
      markers.length = 0;

      const bounds = new window.google.maps.LatLngBounds();

      places.forEach(place => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry');
          return;
        }
        const beachFlagImg = document.createElement("img");
        beachFlagImg.src =
  "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

        const icon = {
          url: beachFlagImg,
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(25, 25),
        };
        const postalCode = place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '';
        recievingDataFromMap(place.geometry.location.lat(),place.geometry.location.lng(),place.formatted_address,postalCode);
   
        markers.push(
          new window.google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          })
        )
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      map.fitBounds(bounds);
    });
    map.addListener('click', (event) => {
          const latLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          const geocoder = new window.google.maps.Geocoder();
    
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results.length > 0) {
              const formattedAddress = results[0].formatted_address;
              const postalCode = results[0].address_components.find(component => component.types.includes('postal_code'))?.long_name || '';
              
            //   // Set marker position
            //   if (marker) {
            //     marker.setPosition(latLng);
            //   } else {
            //     const newMarker = new window.google.maps.Marker({
            //       position: latLng,
            //       map: mapInstance,
            //       title: formattedAddress,
            //     });
            //     setMarker(newMarker);
            //   }
    
              // Now you have the latitude, longitude, formatted address, and postal code
              recievingDataFromMap(latLng.lat,latLng.lng,formattedAddress,postalCode)
            //   console.log('Latitude:', latLng.lat);
            //   console.log('Longitude:', latLng.lng);
            //   console.log('Formatted Address:', formattedAddress);
            //   console.log('Postal Code:', postalCode);
            } else {
              toastr.error('Geocoder failed due to: Some Error Occured', status);
            }
          });
        });

        new window.google.maps.Marker({
          position: {
            lat: lat,
            lng:lng,
        },
          map,
        });
  }, [isLoaded]);
  return ( <>
    {isLoaded && 
         <div>
   <input
        id="pac-input"
        type="text"
        placeholder="Select a location"
        style={{ width: '300px', padding: '10px' ,border: "solid" , background: "white", marginTop:"10px"}}
      />
      <div id="map"  style={mapContainerStyle}></div>
    </div>}
    </> );
};

export default PlaceSearch;