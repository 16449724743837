import { API_URL } from "../utils/apiPaths";
import { request } from "./api";


  
  export function getReviewsList(params,token) {
    return request('GET', API_URL.GET_ALL_REVIEW_RATINGS_LIST, params,token);
  }

  export function deleteReviews(params,token) {
    return request('DELETE', `${API_URL.DELETE_REVIEW_RATINGS}/${params}`, "",token);
  }

  export function changeReviewsFeaturedStatus(params,token) {
    return request('PUT', `${API_URL.CHANGE_REVIEW_RATINGS_FEATURED_STATUS}/${params}`," ",token);
  }
  