export const productConstant = {
  ADMIN_LOGIN_API_TYPES : {
    ADMIN_LOGIN: 'ADMIN_LOGIN',
  },
  PRODUCT_API_TYPES: {
    ADD_PRODUCT: 'ADD_PRODUCT',
    GET_ADMIN_CATEGORIES: 'GET_ADMIN_CATEGORIES',
    GET_ADMIN_PRODUCTS: 'GET_ADMIN_PRODUCTS',
    UPDATE_ADMIN_PRODUCT: 'UPDATE_ADMIN_PRODUCT',
    DELETE_ADMIN_PRODUCT: 'DELETE_ADMIN_PRODUCT',
    GET_ADMIN_PRODUCT_DETAIL: 'GET_ADMIN_PRODUCT_DETAIL',
  }
}

export const sizeOptionList = [
  { value: "340g", label: "340g" },
  { value: "1Kg", label: "1Kg" },
  { value: "2Kg", label: "2Kg" },
  { value: "3Kg", label: "3Kg" },
  { value: "4Kg", label: "4Kg" }, 
];

export const detailKeyOptionList = [
  { value: "Peanut Butter(Natural)", label: "Peanut Butter(Natural)" },
  { value: "Peanut Butter(Dark Chocolate)", label: "Peanut Butter(Dark Chocolate)" },
  { value: "Peanut Butter(Classic)", label: "Peanut Butter(Classic)" },
  { value: "Whey Protein (Banana)", label: "Whey Protein (Banana)" },
  { value: "Whey Protein (Cafe Mocha)", label: "Whey Protein (Cafe Mocha)" },
  { value: "Whey Protein (Mango)", label: "Whey Protein (Mango)" },
  { value: "Plant Protein", label: "Plant Protein" },
  { value: "L-Glutamine", label: "L-Glutamine" },
  { value: "L-Carnitine (Orange)", label: "L-Carnitine (Orange)" },
  { value: "L-Carnitine (Raspberry)", label: "L-Carnitine (Raspberry)" },
  { value: "Essential Greens", label: "Essential Greens" },
  { value: "Immunozest", label: "Immunozest" },
  { value: "Meal Replacement", label: "Meal Replacement" },
  { value: "Muscle Mass (Chocolate)", label: "Muscle Mass (Chocolate)" },
  { value: "Multivitamin Capsules", label: "Multivitamin Capsules" },
  { value: "Pre Workout", label: "Pre Workout" },
// new products added
  { value: "Tulsi Ark", label: "Tulsi Ark" },
  { value: "Tulsi Soap", label: "Tulsi Soap" },
  { value: "Protein Bar (Dark Chocolate)", label: "Protein Bar (Dark Chocolate)" },
  { value: "Protein Bar (Choco Almond)", label: "Protein Bar (Choco Almond)" },
  { value: "Jack Of All Snacks", label: "Jack Of All Snacks" },
];

export const flavourOptionList = [
  { value: "Classic", label: "Classic" },
  { value: "Chocolate", label: "Chocolate" },
  { value: "Crunchy", label: "Crunchy" },
];
