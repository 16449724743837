import React from "react";
import { Box, Text, Icon, Heading } from "../elements";

export default function FloatCard({ variant, productCount,categoryCount,brandsCount,digit, title, icon }) {
    return ( <>
        <Box className={`mc-float-card ${ variant }`}>
            <Heading>{ title === 'total products' ? (productCount ? productCount : 0) : title === 'total categories' ? (categoryCount ? categoryCount : 0) : 0 }</Heading>
            <Text>{ title }</Text>
            <Icon>{ icon }</Icon>
        </Box>
        {/* <Box className={`mc-float-card ${ variant }`}>
        <Heading>{ digit }</Heading>
        <Text>{ title }</Text>
        <Icon>{ icon }</Icon>
    </Box> */}
    </>)
}