import { API_URL } from "../utils/apiPaths";
import { request } from "./api";

export function getAdminUsers(params,token) {
  return request('GET', API_URL.GET_USER, params,token);
}

export function getAdminUserDetail(params,token) {
  return request('GET', `${API_URL.GET_USERS_DETAIL}/${params}`,"",token);
}

export function blockAdminUsers (params,token) {
  return request('PUT', `${API_URL.BLOCK_STATUS}/${params}`, params,token);
}

export const adminCouponServices = {
    getAdminUsers,
    getAdminUserDetail,
    blockAdminUsers
//   getAdminProducts,
//   updateAdminProduct,
//   getAdminProductDetail
};
