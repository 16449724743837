import { API_URL } from "../utils/apiPaths";
import { request } from "./api";


export function addStore(params,token) {
  return request('POST', API_URL.ADD_STORE, params,token);
}

export function getAdminStoresLocatorList(params,token) {
  return request('GET', API_URL.GET_ALL_STORES, params,token);
}

export function updateAdminStoresLocator(params, formData,token) {
  return request('PUT', `${API_URL.UPDATE_STORE}/${params}`, formData,token);
}

export function getAdminSingleStoresLocator(params,token) {
  return request('GET', API_URL.GET_SINGLE_STORES.replace(':id', params), "",token);
}


export function changeAdminStoresLocatorStatus(params,token) {
  return request('PUT', `${API_URL.STORE_ACTIVE_STATUS}/${params}`,"",token);
}

export function getAdminStoresLocatorByZipcode(params,token) {
  return request('GET', API_URL.GET_STORE_BY_ZIPCODE, params,token);
}



export const adminStoreServices = {
  addStore,
  getAdminStoresLocatorList,
  updateAdminStoresLocator,
  getAdminStoresLocatorByZipcode,


};
