import { API_URL } from "../../utils/apiPaths";
import { request } from "../api";



export function createHeroSection(params,token) {
  return request('POST', API_URL.CREATE_HERO_SECTION, params,token);
}

export function getHeroProductsList(params,token) {
  return request('GET', API_URL.GET_HERO_SECTION, params,token);
}

export function updateHeroSection(params, formData,token) {
  return request('PUT', API_URL.UPDATE_HERO_SECTION.replace(':id', params), formData,token);
}

export function getHeroSectionDetail(params,token) {
  return request('GET', `${API_URL.GET_HERO_SECTION_DETAIL}/${params}`,"",token);
}

export function getHeroSectionDelete(params,token) {
  return request('DELETE', `${API_URL.GET_HERO_SECTION_DELETE}/${params}`,"",token);
}

export function disableAdminBlogs(params,token) {
  return request('PUT', `${API_URL.BLOG_STATUS}/${params}`, params,token);
}



export const adminEventServices = {
  createHeroSection,
  getHeroProductsList,
  disableAdminBlogs,
  updateHeroSection,
  getHeroSectionDetail,
};
