import { API_URL } from "../utils/apiPaths";
import { request } from "./api";


  
  export function getOrderList(params,token) {
    return request('GET', API_URL.GET_ALL_ORDERS_LIST, params,token);
  }

  export function getUsersOrderList(params,token) {
    return request('GET', `${API_URL.GET_USER_ORDERS_LIST}/${params}`,"",token);
  }

  export function getOrdersDetail(params,token) {
    return request('GET', `${API_URL.GET_ORDER_DETAILS}/${params}`,"",token);
  }

  export function confirmOrder(params,token) {
    return request('PUT', `${API_URL.CONFIRM_ORDER}/${params}`, "",token);
  }

  export function changeOrderStatus(params,status,token) {
    return request('PUT', `${API_URL.CHANGE_ORDER_STATUS}/${params}`,status,token);
  }

  export function getReturnOrderList(params,token) {
    return request('GET', API_URL.GET_RETURN_ORDERS_LIST, params,token);
  }

  export function getReturnOrderDetailed(params,token) {
    return request('GET', `${API_URL.GET_RETURN_ORDER_DETAILS}/${params}`,"",token);
  }

  export function   changeReturnOrderStatus(params,status,token) {
    return request('PUT', `${API_URL.CHANGE_RETURN_ORDER_STATUS}/${params}`,status,token);
  }
  export function confirmReturnOrder(params,token,confirm) {
    return request('PUT', `${API_URL.CHANGE_RETURN_ORDER_STATUS}/${params}`,confirm,token);
  }
  