import { toastr } from "react-redux-toastr";
import { createHeroSection, getHeroProductsList, getHeroSectionDelete, getHeroSectionDetail, updateHeroSection } from "../../../services/CMS Services/heroSectionServices";
import { heroConstant } from "../../../utils/CMSConstants";
import constant from "../../../utils/constants";
import { CREATE_HERO_FAILURE, CREATE_HERO_SUCCESS, DELETE_HERO_FAILURE, DELETE_HERO_SUCCESS, GET_HERO_DETAIL_FAILURE, GET_HERO_DETAIL_SUCCESS, GET_HERO_PRODUCT_LIST_FAILURE, GET_HERO_PRODUCT_LIST_SUCCESS, HERO_API_END, HERO_SECTION_API_REQUEST, START_LOADER, STOP_LOADER, UPDATE_HERO_FAILURE, UPDATE_HERO_SUCCESS } from "../../actionTypes";
import { hideLoader } from "../loader";
import history from "../../../utils/history";


const { HERO_API_TYPES } = heroConstant;
const { ERROR_MESSAGES } = constant;

//Add/Create Admin Blogs
export const createHeroAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: HERO_SECTION_API_REQUEST,
      payload: HERO_API_TYPES.CREATE_HERO,
    });
    const response = await createHeroSection(param,token);
    toastr.success(response.message);
    dispatch({ type: CREATE_HERO_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
    history.push("/hero-product-list");
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: CREATE_HERO_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: HERO_API_END,
    });
  }
};

//Get Hero Product List
export const getHeroProductListAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: HERO_SECTION_API_REQUEST,
        payload: HERO_API_TYPES.DELETE_ADMIN_HERO_SECTION,
      });
      const response = await getHeroProductsList(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_HERO_PRODUCT_LIST_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_HERO_PRODUCT_LIST_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: HERO_API_END,
      });
    }
  };
};


//Update Hero
export const updateHeroAction = (param, formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: HERO_SECTION_API_REQUEST,
      payload: HERO_API_TYPES.UPDATE_HERO,
    });
    const response = await updateHeroSection(param, formData,token);
    if (response) {
      toastr.success(response.message);
      dispatch({
        type: UPDATE_HERO_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: STOP_LOADER });
      history.push("/hero-product-list");
    }
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: UPDATE_HERO_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: HERO_API_END,
    });
  }
};

//Get Hero Detail
export const getHeroDetailAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    // dispatch( { type: START_LOADER } );
    dispatch({
      type: HERO_SECTION_API_REQUEST,
      payload: HERO_API_TYPES.GET_HERO_DETAIL,
    });
    const response = await getHeroSectionDetail(param,token);
    const newResponse = response.data;
    dispatch({
      type: GET_HERO_DETAIL_SUCCESS,
      payload: newResponse,
    });
    dispatch({ type: STOP_LOADER });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: GET_HERO_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: HERO_API_END,
    });
  }
};


//Delete Admin Hero Banner
export const deleteAdminHeroAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: HERO_SECTION_API_REQUEST,
      payload: HERO_API_TYPES.DELETE_ADMIN_HERO_SECTION,
    });
    const response = await getHeroSectionDelete(params,token);
    response?.statusCode === 200 ? toastr.success(response?.message) :  toastr.error(response?.error || "Some Error Occured")
    dispatch({
      type: DELETE_HERO_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: DELETE_HERO_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: HERO_API_END,
    });
  }
};

// //Get Admin Blog Categories List
// export const getBlogCategories = (params) => {
//   return async (dispatch) => {
//     try {
//       // dispatch( showLoader() );
//       // dispatch({ type: START_LOADER });
//       dispatch({
//         type: ADMIN_BLOG_API_REQUEST,
//         payload: BLOG_API_TYPES.GET_ADMIN_BLOGS,
//       });
//       const response = await getBlogCategoriesList(params);
//       const dataList = response.data;
//       dispatch({
//         type: GET_ADMIN_BLOG_CATEGORIES_SUCCESS,
//         payload: { dataList, meta: dataList },
//       });
//       dispatch(hideLoader());
//     } catch (error) {
//       dispatch(hideLoader());
//       dispatch({
//         type: GET_ADMIN_BLOG_CATEGORIES_FAILURE,
//         payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
//       });
//       toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
//     } finally {
//       dispatch({
//         type: ADMIN_BLOG_API_END,
//       });
//     }
//   };
// };

// //Disable Admin Blogs
// export const disableAdminBlogsAction = (params) => async (dispatch) => {
//   try {
//     dispatch({ type: START_LOADER });
//     dispatch({
//       type: ADMIN_BLOG_API_REQUEST,
//       payload: BLOG_API_TYPES.CHANGE_BLOG_STATUS,
//     });
//     const response = await disableAdminBlogs(params);
//     toastr.success(response.message);
//     dispatch({
//       type: DISABLE_ADMIN_BLOG_SUCCESS,
//       payload: { ...response.data, offerId: params.offerId },
//     });
//     dispatch({ type: STOP_LOADER });
//   } catch (error) {
//     dispatch({ type: STOP_LOADER });
//     dispatch({
//       type: DISABLE_ADMIN_COUPON_FAILURE,
//       payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
//     });
//     toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
//   } finally {
//     dispatch({
//       type: ADMIN_BLOG_API_END,
//     });
//   }
// };

