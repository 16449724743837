import axios from "axios";
import { HTTP_METHODS } from "../utils/httpMethods";


export function request(method, url, data,token) {
  return new Promise(async (resolve, reject) => {
    const req = {
      method: method,
      url: url,
      data: data,
      headers: { 'Authorization': `Bearer ${token}`  }
    };
    if (method === HTTP_METHODS.GET) {
      req.params = data;
    }
    try {
      const res = await axios(req);
      return resolve(res.data);
    } catch (err) {
      return reject(err.response);
    }
  });
}
