import { combineReducers } from "redux";
import { loader } from "./loader";
import { adminBlogs } from "./adminBlog";
import { adminReviews } from "./adminReviews";
import { adminProduct } from "./adminProduct";
import { adminAuth } from "./adminAuth";
import { adminUsers } from "./adminUsers";
import { adminCoupon } from "./adminCoupon";
import { heroSection } from "./CMS/heroSection";
import { offerZone } from "./CMS/offerZone";
import { FAQs } from "./CMS/FAQs";
import { Influencers } from "./CMS/Influencers";
import { Contacts } from "./CMS/contactUs";
import { adminVideos } from "./adminVideos";
import { adminOrders } from "./adminOrders";
import { adminNewsletter } from "./adminNewsletter";
import { storeLocator } from "./adminStoreLocator";
import { OtherContents } from "./CMS/otherContents";
import { reducer as toastrReducer } from "react-redux-toastr";
import { CLEAR_STORE } from "../actionTypes";

const reducer = combineReducers({
  loader,
  toastr: toastrReducer,
  adminProduct,
  adminCoupon,
  adminBlogs,
  adminAuth,
  adminUsers,
  heroSection,
  offerZone,
  FAQs,
  Influencers,
  Contacts,
  OtherContents,
  adminVideos,
  adminReviews,
  adminOrders,
  storeLocator,
  adminNewsletter
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    state = undefined;
  }
  return reducer(state, action);
};

export default rootReducer;
