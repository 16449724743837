import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { configureStore } from './store';
import { Toaster } from './utils/toaster';
import { Provider } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './pages/supports/ErrorFallback';

const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
  <ErrorBoundary fallback={<ErrorFallback/>}>
  <Provider store={store}>
    <Toaster></Toaster>
    <App />
  </Provider>
</ErrorBoundary>
</React.StrictMode>);
