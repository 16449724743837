
const size = ( data ) => data.map((sizes) => sizes);
export class ProductDetails {
  constructor( obj ) {
    this.isFeaturedProduct = obj.isFeaturedProduct || '';
    this.Active = obj.Active || '';
    this.ReviewsAndRatings = obj.ReviewsAndRatings || '';
    this.LongDescription = obj.LongDescription || '';
    this.Category = obj.Category || '';
    this.NoOfReviews = obj.NoOfReviews || '';
    this.productName = obj.Name || '';
    this.ShortDescription = obj.ShortDescription || '';
    this.disclaimer = obj.Disclamer || '';
    // this.manufacturedBy = obj.Description.MoreInfo.ManufacturedBy || '';
    // this.marketedBY = obj.Description.MoreInfo.MarketedBy || '';
    // this.countryOfOrigin = obj.Description.MoreInfo.CountryOfOrigin || '';
    this.productId = obj._id || '';
    this.MRP = obj.MRP || "";
    this.discountedPrice = obj.DiscountedPrice || "";
    this.ratings = obj.Ratings || "";
    this.stock = obj.Stock;
    this.sKUs = obj.SKUs || "";
    this.productIcon = obj.ImagesUrls[0] || '';
    this.allImages = obj.ImagesUrls || '';
    this.SizeVariant = obj.SizeVariant || '';
    this.FlavourVariant = obj.FlavourVariant || '';
    this.createdAt = obj.createdAt || '';
  }
}
