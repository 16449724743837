import { ThemeProvider } from "./context/Themes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Overview, Documentation, ChangeLog, Error } from "./pages/supports";
import { Avatars, Alerts, Buttons, Charts, Tables, Fields, Headings, Colors } from "./pages/blocks";
import {
    Ecommerce, Analytics, CRM, ForgotPassword, Register, Login, UserList, UserProfile, MyAccount,
    ProductList, ProductView, ProductUpload, InvoiceList, InvoiceDetails, OrderList, Message,
    Notification, BlankPage, Settings
} from "./pages/master";
import { useSelector } from "react-redux";
import { LoaderProvider } from "./context/Preloader";
import VariantsUpload from "./pages/master/VariantsUpload";
import Protected from "./ProtectedRoute";
import CreateCoupon from "./pages/Coupons/CreateCoupon";
import CouponList from "./pages/Coupons/CouponList";
import CreateBlogs from "./pages/Blogs/CreateBlogs";
import BlogsList from "./pages/Blogs/BlogsList";
import CreateHeroSection from "./pages/CMS/Hero Section/CreateHeroSection";
import HeroProductList from "./pages/CMS/Hero Section/HeroProductList";
import CreateOfferBanner from "./pages/CMS/Offer Banner/CreateOfferBanner";
import OfferBannerList from "./pages/CMS/Offer Banner/OfferBannerList";
import CreateFAQs from "./pages/CMS/FAQs/createFAQs";
import FAQsList from "./pages/CMS/FAQs/FAQsList";
import AddInfluencers from "./pages/CMS/Influencers/AddInfluencers";
import InfluencersList from "./pages/CMS/Influencers/InfluencersList";
import CreateContactUs from "./pages/CMS/ContactUs/createContactUs";
import ContactUs from "./pages/CMS/ContactUs/contactUsDetail";
import CreateOtherContents from "./pages/CMS/Other Contents/createOtherContents";
import ContentDetails from "./pages/CMS/Other Contents/otherContentDetails";
// import FuzzySearch from "./pages/FuzzySearch";
import AddVideos from "./pages/Videos/AddVideos";
import VideosList from "./pages/Videos/VideosList";
import AddStore from "./pages/Store Locator/AddStore";
import StoresList from "./pages/Store Locator/StoresList";
import ReviewListing from "./pages/Review&Ratings/ReviewListing";
import NewsletterSubscriberList from "./pages/Newsletter/NewsletterSubscriberList";
import ReturnList from "./pages/Returns/ReturnList";
import ReturnOrderDetails from "./pages/master/returnOrderDetails";
// import CouponForm from "./CouponDiscount/couponForm";
import CouponForm1 from "./CouponDiscount/CouponForm1";

export default function App() {
    const loading = useSelector( state => ( { loading: state.adminProduct.loading }
      ) );
    return (
        <ThemeProvider>
            {}
            <LoaderProvider>
                <BrowserRouter>
                    <Routes>
                        {/* master Pages */}
                        <Route path="/" element={ <Protected><ProductList /></Protected>} />

                        <Route path="/analytics" element={<Analytics />} />
                        <Route path="/crm" element={<CRM />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/user-list" element={<Protected><UserList /></Protected>} />
                        <Route path="/create-coupon" element={<Protected><CouponForm1/></Protected>} />
                        <Route path="/user-profile" element={<Protected><UserProfile /></Protected>} />
                        <Route path="/my-account" element={<Protected><MyAccount /></Protected>} />
                        <Route path="/product-list" element={<Protected><ProductList /></Protected>} />
                        <Route path="/coupon-list" element={<Protected><CouponList /></Protected>} />
                        <Route path="/blog-list" element={<Protected><BlogsList /></Protected>} />
                        <Route path="/product-view" element={<Protected><ProductView /></Protected>} />
                        <Route path="/product-upload" element={<Protected><ProductUpload /></Protected>} />
                        <Route path="/coupon-form" element={<Protected><CreateCoupon /></Protected>} />
                        <Route path="/create-blogs" element={<Protected><CreateBlogs /></Protected>} />
                        <Route path="/variants-upload" element={<Protected><VariantsUpload /></Protected>} />
                        <Route path="/invoice-list" element={<Protected><InvoiceList /></Protected>} />
                        <Route path="/invoice-details" element={<Protected><InvoiceDetails /></Protected>} />
                        <Route path="/return-details" element={<Protected><ReturnOrderDetails /></Protected>} />
                        <Route path="/order-list" element={<Protected><OrderList /></Protected>} />
                        <Route path="/return-list" element={<Protected><ReturnList /></Protected>} />
                        <Route path="/message" element={<Message />} />
                        <Route path="/notification" element={<Notification />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/blank-page" element={<BlankPage />} />

                        {/* CMS Routes */}

                        <Route path="/create-hero-section" element={<Protected><CreateHeroSection /></Protected>} />
                        <Route path="/hero-product-list" element={<Protected><HeroProductList /></Protected>} />

                        <Route path="/create-offer-banner" element={<Protected><CreateOfferBanner /></Protected>} />
                        <Route path="/offer-banner-list" element={<Protected><OfferBannerList /></Protected>} />

                        <Route path="/create-FAQs" element={<Protected><CreateFAQs /></Protected>} />
                        <Route path="/FAQs-list" element={<Protected><FAQsList /></Protected>} />

                        <Route path="/add-influencers" element={<Protected><AddInfluencers /></Protected>} />
                        <Route path="/influencers-list" element={<InfluencersList />} />

                        <Route path="/add-contactUs" element={<Protected><CreateContactUs /></Protected>} />
                        <Route path="/contactUs" element={<Protected><ContactUs /></Protected>} />

                        <Route path="/add-content" element={<Protected><CreateOtherContents /></Protected>} />
                        <Route path="/contents" element={<Protected><ContentDetails /></Protected>} />

                        <Route path="/add-video" element={<Protected><AddVideos /></Protected>} />
                        <Route path="/videos-list" element={<Protected><VideosList /></Protected>} />

                        <Route path="/newsletter-subscriber" element={<Protected><NewsletterSubscriberList /></Protected>} />

                        <Route path="/add-store" element={<Protected><AddStore /></Protected>} />
                        <Route path="/stores-list" element={<Protected><StoresList /></Protected>} />
                        <Route path="/reviews-list" element={<Protected><ReviewListing /></Protected>} />
                        {/* Blocks Pages */}
                        <Route path="/headings" element={<Headings />} />
                        <Route path="/buttons" element={<Buttons />} />
                        <Route path="/avatars" element={<Avatars />} />
                        <Route path="/colors" element={<Colors />} />
                        <Route path="/charts" element={<Charts />} />
                        <Route path="/tables" element={<Tables />} />
                        <Route path="/fields" element={<Fields />} />
                        <Route path="/alerts" element={<Alerts />} />

                        {/* Supports Pages */}
                        <Route path="*" element={<Error />} />
                        <Route path="/ecomm" element={<Overview />} />
                        <Route path="/documentation" element={<Documentation />} />
                        <Route path="/changelog" element={<ChangeLog />} />
                    </Routes>
                </BrowserRouter>
            </LoaderProvider>
        </ThemeProvider>
    );
}

