import constant from "../../utils/constants";
import {
    ADD_ADMIN_BLOG_FAILURE,
    ADD_ADMIN_BLOG_SUCCESS,
  ADMIN_BLOG_API_END,
  ADMIN_BLOG_API_REQUEST,
  GET_ADMIN_BLOGS_FAILURE,
  GET_ADMIN_BLOGS_SUCCESS,
  GET_ADMIN_BLOG_CATEGORIES_SUCCESS,
  GET_ADMIN_BLOG_DETAIL_FAILURE,
  GET_ADMIN_BLOG_DETAIL_SUCCESS,
  UPDATE_ADMIN_BLOG_FAILURE,
  UPDATE_ADMIN_BLOG_SUCCESS,
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminBlogs: { blogs: [], meta: "" },
};

const adminBlogs = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case ADMIN_BLOG_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case ADMIN_BLOG_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADD_ADMIN_BLOG_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case ADD_ADMIN_BLOG_FAILURE:
    case GET_ADMIN_BLOGS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminBlogs: { blogs: [], meta: "" },
      };
    case GET_ADMIN_BLOGS_SUCCESS:
      return {
        ...state,
        adminBlogs: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          blogs: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
          case GET_ADMIN_BLOG_CATEGORIES_SUCCESS:
        return {
          ...state,
          adminBlogCategories: {
            // products:
            //   action.payload.page === 1
            //     ? action.payload.dataList
            //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
            dropdownCategories: action.payload.dataList,
            meta: action.payload.meta,
            loading: false,
          },
          status: API_STATUS.SUCCESS,
        };
    case GET_ADMIN_BLOG_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminBlogDetail: action.payload,
        loading: false,
      };
    case GET_ADMIN_BLOG_DETAIL_FAILURE:
      case UPDATE_ADMIN_BLOG_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
        };
    case UPDATE_ADMIN_BLOG_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };

    default:
      return state;
  }
};

export { adminBlogs };
