import constant from "../../utils/constants";
import {
  ADD_ADMIN_VIDEO_FAILURE,
  ADD_ADMIN_VIDEO_SUCCESS,
  ADMIN_VIDEO_API_END,
  ADMIN_VIDEO_API_REQUEST,
  GET_ADMIN_SINGLE_VIDEO_FAILURE,
  GET_ADMIN_SINGLE_VIDEO_SUCCESS,
  GET_ADMIN_VIDEOS_FAILURE,
  GET_ADMIN_VIDEOS_SUCCESS,
  UPDATE_ADMIN_VIDEOS_FAILURE,
  UPDATE_ADMIN_VIDEOS_SUCCESS,
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminVideos: { videos: [], meta: "" },
};

const adminVideos = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case ADMIN_VIDEO_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case ADMIN_VIDEO_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADD_ADMIN_VIDEO_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case ADD_ADMIN_VIDEO_FAILURE:
    case GET_ADMIN_VIDEOS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminVideos: { videos: [], meta: "" },
      };
    case GET_ADMIN_VIDEOS_SUCCESS:
      return {
        ...state,
        adminVideos: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          videos: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_SINGLE_VIDEO_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminBlogDetail: action.payload,
        loading: false,
      };
    case GET_ADMIN_SINGLE_VIDEO_FAILURE:
      case UPDATE_ADMIN_VIDEOS_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
        };
    case UPDATE_ADMIN_VIDEOS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };

    default:
      return state;
  }
};

export { adminVideos };
