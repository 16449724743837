import React from "react";
import { Dropdown } from "react-bootstrap";
import { DuelText, RoundAvatar } from "..";
import { Anchor } from "../elements";

export default function ProfileDropdown({ name, username, image,email, dropdown }) {

    const handleLogout = () => {
        localStorage.clear();
      }
      
    return (
        <Dropdown className="mc-header-user">
            <Dropdown.Toggle className="mc-dropdown-toggle">
                <RoundAvatar src={ image } alt="avatar" size="xs" />
                <DuelText title={ name } role={ username } email={email} size="xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="mc-dropdown-paper">
            {/* <Anchor
                        href={"/my-account"}
                        icon={"person"}
                        text={"my account"}
                        className="mc-dropdown-menu"
                    /> */}
                    {/* <Anchor
                        href={"/forgot-password"}
                        icon={"privacy_tip"}
                        text={"reset password"}
                        className="mc-dropdown-menu"
                    /> */}
                    <Anchor
                        href={"/login"}
                        icon={"lock"}
                        text={"logout"}
                        onClick={handleLogout}
                        className="mc-dropdown-menu"
                    />
                {/* {dropdown.map((item, index) => (
                    <Anchor
                        key={index}
                        href={item.path}
                        icon={item.icon}
                        text={item.text}
                        className="mc-dropdown-menu"
                    />
                ))} */}
            </Dropdown.Menu>
        </Dropdown>
    )
}