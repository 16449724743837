import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { List, Item, Icon, Text, Box, Anchor, Button } from "../../../components/elements";
import { Breadcrumb, RoundAvatar, DivideTitle, DuelText } from "../../../components";
import { CardLayout, CardHeader, FloatCard, ActivityCard } from "../../../components/cards";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/userProfile.json";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../hooks/useQuery";
import { getAdminUsersDetailAction } from "../../../store/actions/adminUsers";
import { getContactsListAction } from "../../../store/actions/CMS actions/contactUs";
import { Link } from "react-router-dom";

export default function ContactUs() {
    const dispatch = useDispatch();

  const query = useQuery();
  const { contact } = useSelector((state) => ({
    contact: state.Contacts?.Contacts?.contact,
  }));
 

  useEffect(() => {
      dispatch(getContactsListAction())
  }, [])

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title="Contact(s)">
                            {/* {data?.breadcrumb.map((item, index) => (
                                <Item key={ index } className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={ item.path }>{ item.text }</Anchor> : item.text }
                                </Item>
                            ))} */}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        {/* <CardHeader title="user information" dotsMenu={ data?.dotsMenu } /> */}
                        <Box className="mc-user-group">
                        <Col xs={12} sm={6} md={4} lg={4}>
                  <div
                   className="d-flex justify-content-start align-items-center mb-3" 
                  >
                    <Anchor href={"/add-contactUs"} state={{ isContactEdit: true }}>
                      <Button
                        className="mc-btn green"
                        text="Edit Contact"
                        icon="discount"
                      />
                    </Anchor>
                  </div>
                </Col>
                            {/* <Box className="mc-user-profile">
                                <RoundAvatar 
                                    // image={ 'images/avatar/09.png' }
                                    src={ 'images/avatar/09.png' } 
                                    alt={ data?.profile.alt } 
                                    size={ data?.profile.size } 
                                />
                                <DuelText 
                                    title={ adminUsersDetail?.name }
                                    // descrip={ data?.profile.username } 
                                    // size={ data?.profile.size }
                                />
                            </Box> */}
                            <Box className="mb-4">
                                <DivideTitle title="communication" className="mb-4" />
                                <List className="mc-user-metalist">
                                     <Item>
                                            <Icon>{ "phone_in_talk" }</Icon>
                                            <Text as="span">{ contact['CompanyNumber'] !== "" ? contact['CompanyNumber'] : "-" }</Text>
                                        </Item>
                                        <Item>
                                            <Icon>{ "map" }</Icon>
                                            <Text as="span">{ contact['CompanyAddress'] !== "" ? contact['CompanyAddress'] : "-" }</Text>
                                        </Item>
                                        <Item>
                                            <Icon>{ "public" }</Icon>
                                            <Text as="span">{ contact['CompanyEmail'] !== "" ? contact['CompanyEmail'] : "-" }</Text>
                                        </Item>
                                        <Item>
                                            <Icon>{ "feed" }</Icon>
                                            <Text as="span">{contact['pageContent'] !== "" ? contact['pageContent'] : "-" }</Text>
                                        </Item>
                                        
                                        {/* <Item>
                                            <Icon>{ "public" }</Icon>
                                            <Text as="span">{ '-' }</Text>
                                        </Item>
                                        <Item>
                                            <Icon>{ "map" }</Icon>
                                            <Text as="span">{ '-' }</Text>
                                        </Item> */}
                                </List>
                            </Box>
                            {/* <Box className="mb-4">
                                <DivideTitle title={ data?.bio.title } className="mb-3" />
                                <Text className="mc-user-bio mb-4">{ data?.bio.descrip }</Text>
                            </Box> */}
                            {/* <Box>
                                <DivideTitle title="elsewhere" className="mb-4" />
                                <Box className="mc-user-social">
                                    {data?.social.map((item, index)=> (
                                        <Anchor 
                                            key = { index } 
                                            href = { item.path }
                                            text = { item.type }
                                            iconClass = { item.icon }
                                            className = { item.type }
                                        />
                                    ))}
                                </Box>
                            </Box> */}
                        </Box>
                    </CardLayout>
                </Col>
                <Col xl={7}>
                    {/* <Row>
                        {data?.float.map((item, index) => (
                            <Col md={4} lg={4} key={ index }>
                                <FloatCard 
                                    variant={ item.variant }
                                    digit={ item.digit }
                                    title={ item.title }
                                    icon={ item.icon }
                                />
                            </Col>
                        ))}
                        <Col xl={12}>
                            <ActivityCard 
                                style={{ height: "540px" }}
                                title={ data?.activity.title }
                                dotsMenu={ data?.activity.dotsMenu }
                                items={ data?.activity.items }
                            />
                        </Col>
                    </Row> */}
                </Col>
            </Row>
        </PageLayout>
    )
}