import { toastr } from "react-redux-toastr";
import {
  addProduct,
  disableAdminProducts,
  getAdminAllProducts,
  getAdminParentProducts,
  getAdminProductDetail,
  getAdminProducts,
  getAdminVariantProducts,
  getDropdownCategoryList,
  getDropdownProductList,
  updateAdminProduct,
} from "../../services/adminProductServices";
import { productConstant } from "../../utils/productConstants";
import {
  ADD_ADMIN_PRODUCT_FAILURE,
  ADD_ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_API_END,
  ADMIN_PRODUCT_API_REQUEST,
  DELETE_ADMIN_PRODUCT_FAILURE,
  DELETE_ADMIN_PRODUCT_SUCCESS,
  GET_ADMIN_DROPDOWN_CATEGORIES_FAILURE,
  GET_ADMIN_DROPDOWN_CATEGORIES_SUCCESS,
  GET_ADMIN_DROPDOWN_PRODUCTS_FAILURE,
  GET_ADMIN_DROPDOWN_PRODUCTS_SUCCESS,
  GET_ADMIN_PARENT_PRODUCTS_FAILURE,
  GET_ADMIN_PARENT_PRODUCTS_SUCCESS,
  GET_ADMIN_PRODUCTS_DETAIL_FAILURE,
  GET_ADMIN_PRODUCTS_DETAIL_SUCCESS,
  GET_ADMIN_PRODUCTS_DROPDOWN_FAILURE,
  GET_ADMIN_PRODUCTS_DROPDOWN_SUCCESS,
  GET_ADMIN_PRODUCTS_FAILURE,
  GET_ADMIN_PRODUCTS_SUCCESS,
  GET_ADMIN_VARIANT_PRODUCTS_FAILURE,
  GET_ADMIN_VARIANT_PRODUCTS_SUCCESS,
  START_LOADER,
  STOP_LOADER,
  UPDATE_ADMIN_PRODUCT_FAILURE,
  UPDATE_ADMIN_PRODUCT_SUCCESS,
} from "../actionTypes";
import constant from "../../utils/constants";
import { ProductDetails } from "../../entities/productDetails";
import history from "../../utils/history";
import { hideLoader } from "./loader";

const { PRODUCT_API_TYPES } = productConstant;
const { ERROR_MESSAGES } = constant;

//Add/Create Admin Product
export const addProductAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_PRODUCT_API_REQUEST,
      payload: PRODUCT_API_TYPES.ADD_PRODUCT,
    });
    const response = await addProduct(param,token);
    toastr.success(response.message);
    dispatch({ type: ADD_ADMIN_PRODUCT_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
    history.push("/product-list");
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: ADD_ADMIN_PRODUCT_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: ADMIN_PRODUCT_API_END,
    });
  }
};

//Add/Create Admin Product Variants
export const addVariantAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_PRODUCT_API_REQUEST,
      payload: PRODUCT_API_TYPES.ADD_PRODUCT,
    });
    const response = await addProduct(param,token);
    toastr.success('Product Variants Created Successfully');
    dispatch({ type: ADD_ADMIN_PRODUCT_SUCCESS, payload: response.data });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    const err = Object.values(error?.data?.error || {}) || "    ";
    dispatch({ type: STOP_LOADER });
    dispatch({ type: ADD_ADMIN_PRODUCT_FAILURE, payload: error });
    if (err[0]) {
      toastr.error(err[0]);
    } else {
      toastr.error(error.data.message);
    }
  } finally {
    dispatch({
      type: ADMIN_PRODUCT_API_END,
    });
  }
};

//Get Admin Product List
export const getAdminProductsAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_PRODUCT_API_REQUEST,
        payload: PRODUCT_API_TYPES.GET_ADMIN_PRODUCTS,
      });
      const response = await getAdminProducts(params,token);

      // const dataList = response.data
      const dataList = response.data.map(
        (item) => new ProductDetails(item)
      );
      dispatch({
        type: GET_ADMIN_PRODUCTS_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_PRODUCTS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_PRODUCT_API_END,
      });
    }
  };
};

//Get Admin Product List
export const getAdminDropdownProductsAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_PRODUCT_API_REQUEST,
        payload: PRODUCT_API_TYPES.GET_ADMIN_PRODUCTS,
      });
      const response = await getAdminAllProducts(params,token);
      const dataList = response.data
      // const dataList = response.data.map(
      //   (item) => new ProductDetails(item)
      // );
      dispatch({
        type: GET_ADMIN_PRODUCTS_DROPDOWN_SUCCESS,
        payload: { dataList, meta: response },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_PRODUCTS_DROPDOWN_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_PRODUCT_API_END,
      });
    }
  };
};

//Get Admin Product Detail View
export const getAdminProductDetailAction = (param) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    // dispatch( { type: START_LOADER } );
    dispatch({
      type: ADMIN_PRODUCT_API_REQUEST,
      payload: PRODUCT_API_TYPES.GET_ADMIN_PRODUCT_DETAIL,
    });
    const response = await getAdminProductDetail(param,token);
    const newResponse = new ProductDetails(response.data);
    dispatch({
      type: GET_ADMIN_PRODUCTS_DETAIL_SUCCESS,
      payload: newResponse,
    });
    dispatch({ type: STOP_LOADER });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: GET_ADMIN_PRODUCTS_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_PRODUCT_API_END,
    });
  }
};

//Update Admin Products
export const updateAdminProductAction =
  (param, formData) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_PRODUCT_API_REQUEST,
        payload: PRODUCT_API_TYPES.UPDATE_ADMIN_PRODUCT,
      });
      const response = await updateAdminProduct(param, formData,token);
      if (response) {
        toastr.success(response.message);
        dispatch({
          type: UPDATE_ADMIN_PRODUCT_SUCCESS,
          payload: response.data,
        });
        dispatch({ type: STOP_LOADER });
        history.push("/product-list");
      }
    } catch (error) {
      const err = Object.values(error?.data?.error || {}) || "";
      dispatch({ type: STOP_LOADER });
      dispatch({ type: UPDATE_ADMIN_PRODUCT_FAILURE, payload: error });
      if (err[0]) {
        toastr.error(err[0]);
      } else {
        toastr.error(error.data.message);
      }
    } finally {
      dispatch({
        type: ADMIN_PRODUCT_API_END,
      });
    }
  };

//Delete Admin Products
export const disableAdminProductAction = (params) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: START_LOADER });
    dispatch({
      type: ADMIN_PRODUCT_API_REQUEST,
      payload: PRODUCT_API_TYPES.DELETE_ADMIN_PRODUCT,
    });
    const response = await disableAdminProducts(params,token);
    response.message === "true"
      ? toastr.success("Product Enabled Successfully")
      : response.message === "false"
      ? toastr.error("Product has been Disabled")
      : toastr.error("Something Went Wrong");
    dispatch({
      type: DELETE_ADMIN_PRODUCT_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    });
    dispatch({ type: STOP_LOADER });
  } catch (error) {
    dispatch({ type: STOP_LOADER });
    dispatch({
      type: DELETE_ADMIN_PRODUCT_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    });
    toastr.error(error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR);
  } finally {
    dispatch({
      type: ADMIN_PRODUCT_API_END,
    });
  }
};

//Get Admin Product List
export const getAdminParentProductsAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_PRODUCT_API_REQUEST,
        payload: PRODUCT_API_TYPES.GET_ADMIN_PRODUCTS,
      });
      const response = await getAdminParentProducts(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_ADMIN_PARENT_PRODUCTS_SUCCESS,
        payload: { dataList, meta: dataList },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_PARENT_PRODUCTS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_PRODUCT_API_END,
      });
    }
  };
};

//Get Admin Product List
export const getAdminVariantProductsAction = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_PRODUCT_API_REQUEST,
        payload: PRODUCT_API_TYPES.GET_ADMIN_PRODUCTS,
      });
      const response = await getAdminVariantProducts(params,token);
      const dataList = response;
      dispatch({
        type: GET_ADMIN_VARIANT_PRODUCTS_SUCCESS,
        payload: { dataList, meta: dataList },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_VARIANT_PRODUCTS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_PRODUCT_API_END,
      });
    }
  };
};

//Get Admin Product List
export const getDropdownCategories = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_PRODUCT_API_REQUEST,
        payload: PRODUCT_API_TYPES.GET_ADMIN_CATEGORIES,
      });
      const response = await getDropdownCategoryList(params,token);
      const dataList = response.data;
      dispatch({
        type: GET_ADMIN_DROPDOWN_CATEGORIES_SUCCESS,
        payload: { dataList, meta: dataList },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_DROPDOWN_CATEGORIES_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_PRODUCT_API_END,
      });
    }
  };
};

//Get Admin Product List
export const getDropdownProducts = (params) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // dispatch( showLoader() );
      // dispatch({ type: START_LOADER });
      dispatch({
        type: ADMIN_PRODUCT_API_REQUEST,
        payload: PRODUCT_API_TYPES.GET_ADMIN_PRODUCTS,
      });
      const response = await getDropdownProductList(params,token);
      const dataList = response;
      dispatch({
        type: GET_ADMIN_DROPDOWN_PRODUCTS_SUCCESS,
        payload: { dataList, meta: dataList },
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      dispatch({
        type: GET_ADMIN_DROPDOWN_PRODUCTS_FAILURE,
        payload: error || ERROR_MESSAGES.EXCEPTION_ERROR,
      });
      toastr.error(error || ERROR_MESSAGES.EXCEPTION_ERROR);
    } finally {
      dispatch({
        type: ADMIN_PRODUCT_API_END,
      });
    }
  };
};
