import React from "react";
import { Box, Label, Textarea } from "../elements";

export default function LabelTextarea({ label,name ,onChange,value,labelDir, fieldSize, placeholder,required, ...rest }) {
    return (
        <Box className={`mc-label-field-group ${ label ? labelDir || "label-col" : "" }`}>
            {label && <Label className="mc-label-field-title">{ label }</Label>}
            <Textarea 
            name={name}
            onChange={onChange}
            value={value}
                className={`mc-label-field-textarea ${ fieldSize || "w-md h-text-md" }`} 
                placeholder={ placeholder }
                required={required}
                { ...rest } 
            ></Textarea>
        </Box>
    )
}