export const heroConstant = {
    HERO_API_TYPES: {
      CREATE_HERO: 'CREATE_HERO',
      GET_HERO_PRODUCT_LIST: 'GET_HERO_PRODUCT_LIST',
      CHANGE_BLOG_STATUS : 'CHANGE_BLOG_STATUS',
      DELETE_ADMIN_HERO_SECTION: 'DELETE_ADMIN_HERO_SECTION',
      UPDATE_HERO: 'UPDATE_HERO',
      GET_HERO_DETAIL: 'GET_HERO_DETAIL',
    }
  }

  export const featuredConstants = {
    FEATURED_API_TYPES: {
      GET_FEATURED_PRODUCT_LIST: 'GET_FEATURED_PRODUCT_LIST',
      CHANGE_FEATURED_STATUS : 'CHANGE_FEATURED_STATUS',
      GET_FEATURED_BLOGS_LIST: 'GET_FEATURED_BLOGS_LIST',
      CHANGE_FEATURED_BLOGS_STATUS : 'CHANGE_FEATURED_BLOGS_STATUS',
    }
  }


  export const featuredVideoConstants = {
    FEATURED_API_TYPES: {
      CHANGE_FEATURED_STATUS : 'CHANGE_FEATURED_STATUS',
      GET_FEATURED_VIDEOS_LIST: 'GET_FEATURED_VIDEOS_LIST',
    }
  }
  export const offerZoneConstants = {
    OFFER_API_TYPES: {
      CREATE_OFFER_BANNER: 'CREATE_OFFER_BANNER',
      GET_OFFER_BANNER_LIST: 'GET_HERO_PRODUCT_LIST',
      CHANGE_OFFER_STATUS : 'CHANGE_BLOG_STATUS',
    }
  }

  export const FAQsConstants = {
    FAQ_API_TYPES: {
      CREATE_FAQ: 'CREATE_FAQ',
      GET_FAQS_LIST: 'GET_FAQS_LIST',
      UPDATE_FAQS : 'UPDATE_FAQS',
      GET_SINGLE_FAQS : 'GET_SINGLE_FAQS',
    }
  }

  export const contactsConstants = {
    CONTACT_API_TYPES: {
      CREATE_UPDATE_CONTACTS: 'CREATE_UPDATE_CONTACTS',
      GET_CONTACTS: 'GET_CONTACTS',
    }
  }

  export const otherContentsConstants = {
    CONTENT_API_TYPES: {
      CREATE_UPDATE_OTHER_CONTENTS: 'CREATE_UPDATE_OTHER_CONTENTS',
      GET_OTHER_CONTENTS: 'GET_OTHER_CONTENTS',
    }
  }

  export const ReviewConstants = {
    REVIEW_API_TYPES: {
      CHANGE_FEATURED_STATUS : 'CHANGE_FEATURED_STATUS',
      GET_REVIEWS_LIST: 'GET_REVIEWS_LIST',
      DELETE_REVIEWS : 'DELETE_REVIEWS',
    }
  }

  export const OrderConstants = {
    ORDER_API_TYPES: {
      GET_RETURN_ORDER_LIST: "GET_RETURN_ORDER_LIST",
      CHANGE_ORDER_STATUS : 'CHANGE_ORDER_STATUS',
      CHANGE_RETURN_ORDER_STATUS : 'CHANGE_RETURN_ORDER_STATUS',
      GET_ORDER_LIST: 'GET_ORDER_LIST',
      GET_ORDER_DETAIL: 'GET_ORDER_DETAIL',
      GET_RETURN_ORDER_DETAIL: 'GET_RETURN_ORDER_DETAIL',
      GET_USER_ORDER_LIST: 'GET_USER_ORDER_LIST',
      ORDER_STATUS_COUNT: 'ORDER_STATUS_COUNT',
      CONFIRM_ORDER : 'CONFIRM_ORDER',
      CONFIRM_RETURN_ORDER : 'CONFIRM_RETURN_ORDER',
    }
  }

  export const InfluencerConstants = {
    INFLUENCER_API_TYPES: {
      CREATE_INFLUENCER: 'CREATE_INFLUENCER',
      GET_INFLUENCER_LIST: 'GET_INFLUENCER_LIST',
      CHANGE_INFLUENCER_STATUS : 'CHANGE_INFLUENCER_STATUS',
      DELETE_INFLUENCER: 'DELETE_INFLUENCER',
      UPDATE_INFLUENCER: 'UPDATE_INFLUENCER',
      GET_SINGLE_INFLUENCER: 'GET_SINGLE_INFLUENCER',
    }
  }

