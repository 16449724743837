import constant from "../../../utils/constants";
import { INFLUENCER_API_REQUEST, CREATE_INFLUENCER_SUCCESS, CREATE_ADMIN_COUPON_FAILURE, GET_INFLUENCERS_LIST_FAILURE, GET_INFLUENCERS_LIST_SUCCESS, INFLUENCER_API_END, GET_INFLUENCER_DETAIL_SUCCESS, GET_INFLUENCER_DETAIL_FAILURE, UPDATE_INFLUENCERS_SUCCESS, UPDATE_INFLUENCERS_FAILURE } from "../../actionTypes";


const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  Influencers: { inflncrs: [], meta: "" },
};

const Influencers = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case INFLUENCER_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case INFLUENCER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case CREATE_INFLUENCER_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case CREATE_ADMIN_COUPON_FAILURE:
    case GET_INFLUENCERS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        Influencers: { inflncrs: [], meta: "" },
      };
    case GET_INFLUENCERS_LIST_SUCCESS:
      return {
        ...state,
        Influencers: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          inflncrs: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_INFLUENCER_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        influencersDetail: action.payload,
        loading: false,
      };
    case GET_INFLUENCER_DETAIL_FAILURE:
      case UPDATE_INFLUENCERS_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
        };
    case UPDATE_INFLUENCERS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };

    default:
      return state;
  }
};

export { Influencers };
