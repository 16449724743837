import constant from "../../../utils/constants";
import { CONTENTS_API_REQUEST, CONTENTS_API_END, CREATE_UPDATE_CONTENT_SUCCESS, CREATE_UPDATE_CONTENT_FAILURE, GET_CONTENTS_FAILURE, GET_CONTENTS_SUCCESS } from "../../actionTypes";


const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  Contents: { content: [], meta: "" },
};

const OtherContents = (state = DEFAULT_STATE, action = "") => {
  switch (action.type) {
    case CONTENTS_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case CONTENTS_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case CREATE_UPDATE_CONTENT_SUCCESS:
      // case UPDATE_ADMIN_PRODUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case CREATE_UPDATE_CONTENT_FAILURE:
    case GET_CONTENTS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        Contents: { content: [], meta: "" },
      };
    case GET_CONTENTS_SUCCESS:
      return {
        ...state,
        Contents: {
          // products:
          //   action.payload.page === 1
          //     ? action.payload.dataList
          //     : [ ...state.adminProducts.products, ...action.payload.dataList ],
          content: action.payload.dataList,
          meta: action.payload.meta,
          loading: false,
        },
        status: API_STATUS.SUCCESS,
      };

    default:
      return state;
  }
};

export { OtherContents };
